import React from 'react';

import Grid from '@material-ui/core/Grid';

import { AimTypography } from '../../atoms';

import { theme } from '../../../theme';

const AbstractInfo = ({ data, i18n, variant }) => {
  const InfoRow = ({ title, value }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <AimTypography variant="textBold" boxStyle={{ margin: 0 }}>
          {`${title}:`}
        </AimTypography>
        <AimTypography variant="text" boxStyle={{ margin: '5px 0px 0px 5px' }}>
          {value}
        </AimTypography>
      </div>
    );
  };

  const getList = (data) => data.map((i) => i).join(', ');

  return (
    <Grid
      container
      style={{
        backgroundColor:
          variant && variant === 'white'
            ? theme.colors.primary.white
            : theme.colors.greyScale.backgroundGrey,
        paddingLeft: 16,
        paddingTop: 10,
        paddingBottom: 10,
      }}
      direction="column"
    >
      {data?.category?.name && (
        <Grid item xs={12} sm={12} md={12}>
          <InfoRow
            title={i18n.page.abstractInfo.category}
            value={data.category.name}
          />
        </Grid>
      )}
      {data?.subCategory?.name && (
        <Grid item xs={12} sm={12} md={12}>
          <InfoRow
            title={i18n.page.abstractInfo.subCategory}
            value={data.subCategory.name}
          />
        </Grid>
      )}
      {(data?.acceptedPresentationTypology?.name ||
        data?.proposedPresentationTypology?.name) && (
        <Grid item xs={12} sm={12} md={12}>
          <InfoRow
            title={i18n.page.abstractInfo.presentationPreferred}
            value={
              data.acceptedPresentationTypology?.name ||
              data.proposedPresentationTypology?.name
            }
          />
        </Grid>
      )}
      {data?.tags?.length > 0 && (
        <Grid item xs={12} sm={12} md={12}>
          <InfoRow
            title={i18n.page.abstractInfo.tag}
            value={getList(data.tags)}
          />
        </Grid>
      )}
      {data?.keywords?.length > 0 && (
        <Grid item xs={12} sm={12} md={12}>
          <InfoRow
            title={i18n.page.abstractInfo.keywords}
            value={getList(data.keywords)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AbstractInfo;
