import React from 'react';

import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';

import { AimTypography, AimIconButton } from '../atoms';
import { theme } from '../../theme';
import { styled } from '../styled';

const InnerContainer = styled('div')({
  width: '1280px',
  display: 'flex',
  flexDirection: 'column',
});

const DocumentMainContainer = styled('div')({
  background: theme.colors.greyScale.backgroundGrey,
  padding: '15px 10px',
});

const DocumentInnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.colors.greyScale.white,
  marginBottom: 8,
  padding: 10,
});

export const AgencyWelcomePageTemplate = ({ data, actionButtons, i18n }) => {
  const { instructions } = data;
  return (
    <InnerContainer>
      {instructions && (
        <>
          <AimTypography boxStyle={{ margin: 0, marginTop: 27 }} variant="h4">
            {i18n.instructions}
          </AimTypography>
          <div
            style={{
              margin: '8px 0px',
            }}
            dangerouslySetInnerHTML={{
              __html: instructions,
            }}
          />
        </>
      )}
      {data.policies && (
        <DocumentMainContainer>
          {data.policies.map((policy) => (
            <DocumentInnerContainer key={policy.id}>
              <AimTypography variant="h2Regular">
                {policy.originalName}
              </AimTypography>

              <AimIconButton
                variant="primary"
                onClick={() => window.open(policy.url)}
              >
                <GetAppIcon />
              </AimIconButton>
            </DocumentInnerContainer>
          ))}
        </DocumentMainContainer>
      )}
      <Grid
        container
        style={{ justifyContent: 'flex-end', marginTop: 27, marginBottom: 50 }}
      >
        <Grid item>{actionButtons}</Grid>
      </Grid>
    </InnerContainer>
  );
};
