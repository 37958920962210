import {
  constants,
  aws,
  fileHelper,
  utilities,
  massiveQueriesHelper,
} from '@aim/common';

import {
  createParticipation,
  updateParticipation,
  createLog,
  createParticipationServices,
  createFieldValue,
  updateFieldValue,
  // deleteFieldValue,
  updateGuest,
  getEventCode,
} from './gqlHelper';
import { customAlphabet } from 'nanoid';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);
const {
  callLambdaConflictsOnCreate,
  callLambdaConflictsOnUpdate,
} = aws.ConflictHelper;

const clusterConnections = {
  [constants.Clusters.Delegations.id]: {
    clusterName: 'delegation',
    connectionName: 'participationDelegationId',
  },
  [constants.Clusters.Groups.id]: {
    clusterName: 'group',
    connectionName: 'participationAgencyGroupId',
  },
  [constants.Clusters.SponsorStaff.id]: {
    clusterName: 'sponsorStaff',
    connectionName: 'participationSponsorStaffId',
  },
  [constants.Clusters.SponsorList.id]: {
    clusterName: 'sponsorList',
    connectionName: 'participationSponsorListId',
  },
  [constants.Clusters.Pax.id]: null,
};

const createN2NConnectionsTableMutation = /* GraphQL */ `
createN2NConnectionsTable(input: $input) {
    id
  }
`;

const deleteN2NConnectionsTableMutation = /* GraphQL */ `
  deleteN2NConnectionsTable(input: $input) {
    id
  }
`;

export const defaultSaveData = async ({
  participation,
  submittedData,
  dirtyFields: {
    baseDefaultValues: baseDefaultDirty = {},
    standardDefaultValues,
    customDefaultValues,
  },
  skipEmailVerification = false,
  user,
  editFrontOffice = false,
  skipAdditionalServices = true,
}) => {
  let username = participation.username;
  let newParticipationId;
  let participationParticipationServicesId =
    participation?.participationParticipationServicesId;
  const { createFile, deleteFile } = fileHelper;
  const dirtyFields = { ...standardDefaultValues, ...customDefaultValues };

  let {
    profile,
    typology,
    feeDateRange,
    participationSponsorId,
    ...baseFieldsNextValues
  } = submittedData.baseDefaultValues;

  baseFieldsNextValues = Object.fromEntries(
    Object.entries(baseFieldsNextValues).filter(([_, v]) => v != null)
  );
  let nextValues = {};
  if (profile) nextValues.participationProfileId = profile;
  if (typology) nextValues.participationTypologyId = typology;
  // if (sponsor) baseFieldsNextValues.participationSponsorId = sponsor;
  if (feeDateRange) nextValues.participationFeeDateRangeId = feeDateRange;
  if (participation?.cluster === constants.Clusters.SponsorStaff.id)
    nextValues.isParticipant = true;
  if (participation.isFirstAccessCompleted) {
    nextValues.isFirstAccessCompleted = participation.isFirstAccessCompleted;
  }

  const clusterConnection = clusterConnections[participation.cluster];
  if (participation.id) {
    newParticipationId = participation.id;
    const participationUpdateResult = await updateParticipation(
      {
        ...(baseDefaultDirty.title && {
          title: baseFieldsNextValues.title,
        }),
        ...(baseDefaultDirty.givenName && {
          givenName: baseFieldsNextValues.givenName,
        }),
        ...(baseDefaultDirty.familyName && {
          familyName: baseFieldsNextValues.familyName,
        }),
        ...(baseDefaultDirty.phone && {
          phone: baseFieldsNextValues.phone,
        }),
        ...(baseDefaultDirty.notes && {
          notes: baseFieldsNextValues.notes,
        }),
        ...(baseDefaultDirty.isReviewer && {
          isReviewer: baseFieldsNextValues.isReviewer,
        }),
        ...(baseDefaultDirty.isSpeaker && {
          isSpeaker: baseFieldsNextValues.isSpeaker,
        }),
        ...(baseDefaultDirty.isFaculty && {
          isFaculty: baseFieldsNextValues.isFaculty === true ? 'true' : 'false',
        }),
        ...(baseDefaultDirty.seg && {
          seg: baseFieldsNextValues.seg,
        }),
        ...(baseDefaultDirty.ana && {
          ana: baseFieldsNextValues.ana,
        }),
        ...(baseDefaultDirty.status && {
          status: baseFieldsNextValues.status,
        }),
        ...(baseDefaultDirty.type && {
          type: baseFieldsNextValues.type,
        }),
        ...nextValues,
        id: participation.id,
        username: participation.username,
        email: baseFieldsNextValues.email || participation.email || undefined,
        participationEventId: participation.event.id,
        participationSponsorId: participationSponsorId,
        cluster: participation.cluster,
        isClusterClosed:
          participation.cluster === constants.Clusters.Pax.id
            ? 'true'
            : undefined,
        cognitoUserId: participation.cognitoUserId,
        isInvited: participation.isInvited,
        isParsonalDetailsEditedAtLeastOnce: !!submittedData.isParsonalDetailsEditedAtLeastOnce,
      },
      false
    );
    if (!editFrontOffice) {
      if (
        Object.keys(dirtyFields).length ||
        (baseDefaultDirty &&
          !(
            baseDefaultDirty.status &&
            Object.keys(baseDefaultDirty).length === 1
          ))
      ) {
        await createLog({
          type: 'update',
          userId: user?.id,
          userEmail: user?.email,
          participationId: participationUpdateResult?.id,
          string: `Edit`,
          productId: '',
        });
      }
      if (baseDefaultDirty.status) {
        await createLog({
          type: 'update',
          userId: user?.id,
          userEmail: user?.email,
          participationId: participationUpdateResult?.id,
          string: `Status: ${baseFieldsNextValues.status}`,
          productId: '',
        });
      }
    }
    if (
      (baseDefaultDirty.givenName ||
        baseDefaultDirty.familyName ||
        baseDefaultDirty.email ||
        baseDefaultDirty.phone) &&
      participation.guests.items.length > 0
    ) {
      for (const guest of participation.guests.items) {
        await updateGuest({
          id: guest.id,
          ...(baseDefaultDirty.givenName && {
            givenName: baseFieldsNextValues.givenName,
          }),
          ...(baseDefaultDirty.familyName && {
            familyName: baseFieldsNextValues.familyName,
          }),
          ...(baseDefaultDirty.email && {
            email: baseFieldsNextValues.email,
          }),
          ...(baseDefaultDirty.phone && {
            phone: baseFieldsNextValues.phone,
          }),
        });
      }
    }
    if (
      !skipEmailVerification &&
      participation.username &&
      participation.cognitoUserId &&
      participation.email &&
      baseFieldsNextValues.email &&
      participation.email !== baseFieldsNextValues.email
    ) {
      await aws.adminUpdateCognitoUserAttributes(participation.username, [
        {
          Name: 'email',
          Value: baseFieldsNextValues.email?.trim()?.toLowerCase(),
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ]);
    }
  } else {
    if (!username) {
      const eventCode = await getEventCode(
        participation.event.id || participation.eventId
      );
      const participationSequence = await utilities.getNextSequenceValue({
        // eventId: participation.event.id,
        eventCode,
        sequenceName: 'participationSequence',
      });
      username = `${eventCode}-${participationSequence}`;
    }
    // this is for new participations
    const participationObj = {
      username,
      participationEventId: participation.event.id,
      participationSponsorId: participationSponsorId,
      cluster: participation.cluster,
      isClusterClosed:
        participation.cluster === constants.Clusters.Pax.id
          ? 'true'
          : undefined,
      cognitoUserId: participation.cognitoUserId,
      isParticipant: participation.isParticipant,
      isSpeaker: participation.isSpeaker,
      isReviewer: participation.isReviewer,
      isInvited: participation.isInvited,
      isFirstAccessCompleted: participation.isFirstAccessCompleted,
      ...(profile && { participationProfileId: profile }),
      ...(typology && { participationTypologyId: typology }),
      ...(profile &&
        feeDateRange && {
          participationFeeDateRangeId: feeDateRange,
        }),
      ...baseFieldsNextValues,
      //email MUST be after baseFieldsNextValues because if empty we cant write create participation for email indes so we set undefined
      email: baseFieldsNextValues.email || undefined,
    };

    if (clusterConnection) {
      participationObj[clusterConnection.connectionName] =
        participation[clusterConnection.clusterName].id;
    }
    const participationCreateResult = await createParticipation(
      participationObj,
      false
    );
    newParticipationId = participationCreateResult?.id;
    if (!editFrontOffice) {
      if (baseDefaultDirty || dirtyFields) {
        await createLog({
          type: 'create',
          userId: user?.id,
          userEmail: user?.email,
          participationId: participationCreateResult?.id,
          ...(baseDefaultDirty.status
            ? {
                string: `status: ${baseFieldsNextValues.status}`,
              }
            : { string: `Inserted` }),
          productId: '',
        });
      }
    }

    if (newParticipationId) {
      const { data } = await createParticipationServices({
        participationServicesParticipationId: newParticipationId,
        eventId: participation.event.id,
        publicServicesPageAccessCode: nanoid(),
      });

      participationParticipationServicesId =
        data.createParticipationServices.id;
      await updateParticipation({
        id: newParticipationId,
        participationParticipationServicesId:
          data.createParticipationServices.id,
      });
    }
  }

  const otherFields = [
    ...submittedData.standardDefaultValues,
    ...submittedData.customDefaultValues,
  ];

  const participationFields = participation.fieldValues
    ? participation.fieldValues.items.map((i) => i)
    : [];

  const nextFields = await Promise.all(
    otherFields
      .filter((f) => dirtyFields[f.fieldDefinition?.id])
      .map(async (f) => {
        if (f.controlType !== 'upload') return f;
        const originalAttachments = participationFields.find(
          (pf) => pf.fieldDefinition?.id === f.fieldDefinition?.id
        )?.value;
        if (originalAttachments) {
          const parsed = JSON.parse(originalAttachments);
          await Promise.all(
            parsed.map(
              async (file) =>
                file?.id &&
                (await deleteFile({
                  dirPath: `events/${participation.event.id}/participations/${newParticipationId}/${f.fieldDefinition?.id}`,
                  fileData: file,
                  useLoader: false,
                }))
            )
          );
        }
        f.value = await Promise.all(
          f.value.map(async (file) => {
            if (!file.id) {
              const originalName =
                file?.name || new Date().getTime().toString();

              const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
              const extension = matchExtension ? matchExtension[0] : '';

              const fileSize = file?.size;

              var inputFile = {
                originalName: originalName,
                extension: extension,
                size: Number(fileSize) || 0,
              };
              const fileId = await createFile({
                dirPath: `events/${participation.event.id}/participations/${newParticipationId}/${f.fieldDefinition?.id}`,
                fileData: inputFile,
                localFile: file,
                useLoader: false,
              });
              return { ...inputFile, id: fileId };
            }
            return file;
          })
        );
        return f;
      })
  );
  const previousFieldValues =
    participation.fieldValues?.items?.filter(
      (fv) =>
        //other because nextField contains only changed fields
        !otherFields.find((of) => of?.id === fv.fieldDefinition?.id)
    ) || [];

  //delete files in case of change profile, so old fields not included in check before
  previousFieldValues
    .filter((fv) => fv.fieldDefinition?.controlType === 'upload')
    .map(async (fv) => {
      const parsed = JSON.parse(fv.value);
      return await Promise.all(
        parsed.map(
          async (file) =>
            await deleteFile({
              dirPath: `events/${participation.event.id}/participations/${newParticipationId}/${fv.fieldDefinition?.id}`,
              fileData: file,
              useLoader: false,
            })
        )
      );
    });

  await Promise.all([
    //GET FIELD VALUES TO DELETE
    // ...previousFieldValues.map(
    //   async (fv) => await deleteFieldValue({ id: fv.id }, false)
    // ),
    ...nextFields.map(async (f) => {
      const currentField = participationFields.find(
        (pf) => pf.fieldDefinition?.id === f.fieldDefinition?.id
      );
      if (currentField) {
        await updateFieldValue(
          {
            id: currentField.id,
            value: JSON.stringify(f.value),
            eventId: participation.event.id,
          },
          false
        );
        return;
      }
      await createFieldValue(
        {
          participationFieldValuesId: participation.id || newParticipationId,
          fieldValueFieldDefinitionId: f.fieldDefinition?.id,
          value: JSON.stringify(f.value),
          eventId: participation.event.id,
        },
        false
      );
    }),
  ]);
  if (!skipAdditionalServices) {
    const toDeleteAdditionalServices =
      participation?.n2nConnection?.items?.filter(
        (n2n) =>
          !(submittedData.additionalServices || []).includes(
            n2n.n2NConnectionsTableAdditionalServicesId
          )
      ) || [];
    const toCreateAdditionalServices = (
      submittedData.additionalServices || []
    ).filter(
      (additionalService) =>
        !participation?.n2nConnection?.items?.find(
          (n2n) =>
            n2n.n2NConnectionsTableAdditionalServicesId === additionalService
        )
    );
    await massiveQueriesHelper.massiveMutation({
      query: [
        ...toDeleteAdditionalServices.map(
          () => deleteN2NConnectionsTableMutation
        ),
        ...toCreateAdditionalServices.map(
          () => createN2NConnectionsTableMutation
        ),
      ],
      queryName: 'SubmitParticipationEditAdditionalServices',
      items: [
        ...toDeleteAdditionalServices.map((item) => ({
          id: item.id,
        })),
        ...toCreateAdditionalServices.map((item) => ({
          type: constants.N2NConnectionTypes.PARTICIPATION_ADDITIONAL_SERVICE,
          n2NConnectionsTableParticipationId: newParticipationId,
          n2NConnectionsTableAdditionalServicesId: item,
        })),
      ],
      queryInputType: [
        ...toDeleteAdditionalServices.map(
          () => 'DeleteN2NConnectionsTableInput'
        ),
        ...toCreateAdditionalServices.map(
          () => 'CreateN2NConnectionsTableInput'
        ),
      ],
    });
  }

  return {
    ...participation,
    username,
    id: newParticipationId,
    participationParticipationServicesId,
  };
};
