import React, { useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import filesize from 'filesize';

import Grid from '@material-ui/core/Grid';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';

import { styled } from './../styled';
import { theme } from './../../theme';
import { appState, constants } from '@aim/common';
import { AimTypography, AimIconButton, AimIconAndTextButton } from './../atoms';
import { useRef } from 'react';

import { fileHelper } from '@aim/common';
import { AimSnackbar, AimSnackbarSeverity } from '../atoms/AimSnackbar';
import translation from './translation';

const DragAndDropContainer = styled('div')(({ style, variant }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.colors.greyScale.backgroundGrey,
  // background: `${theme.colors.greyScale.backgroundGrey} !important`,
  // height: 200,
  borderRadius: 4,
  ...variant,
  ...style,
}));

const variantTemplate = appState.template.getValue();

const DragAndDropInnerContainer = styled('div')(({ variant }) => ({
  display: 'flex',
  flex: 1,
  margin: 10,
  height: 140,
  background: 'white',
  borderRadius: 4,
  alignItems: 'center',
  justifyContent: 'center',
  ...variant,
}));

const getDefaultVariant = (eventConfiguration) =>
  variantTemplate === constants.Templates.BACKEND
    ? 'grey'
    : eventConfiguration.background === theme.colors.primary.white
    ? 'primary'
    : 'secondary';

export const AimDragDropFileUploader = ({
  intl,
  label,
  dragTitle,
  dragSubTitle,
  clickText,
  uploadBtnWithText,
  onLoad,
  onUploadFile,
  onRemove,
  onDownload,
  files = [],
  multiple,
  maxFiles = 0,
  fileLimitText = 'Limit of file reached',
  maxSize = 10000000,
  dropzoneAdditionalProps = {},
  isSmallGridEnabled = false,
  grid2ColsEnabled = false,
  style,
  containerStyle,
  helperText,
  variant,
  dirPath,
  disabledDownload = false,
  disabledRemove = false,
}) => {
  const parentRef = useRef(null);
  const i18n = intl && translation.dragAndDropFileUploader(intl);
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    maxFiles,
    maxSize,
    multiple,
    disabled: maxFiles && files?.length >= maxFiles ? true : false,
    ...dropzoneAdditionalProps,
    onDrop: (_, fileRejections) => {
      const errors = [];
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          errors.push(err.message);
        });
      });
      errors.length &&
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          children: errors.map((e) => (
            <>
              {e}
              <br />
            </>
          )),
        });
    },
  });

  const nextVariant = variant || getDefaultVariant(eventConfiguration);

  useEffect(() => {
    if (acceptedFiles.length) {
      onUploadFile && onUploadFile(acceptedFiles);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    onLoad && onLoad();
    return () => subscription.unsubscribe();
  }, []);

  const variantsBACKEND = {
    grey: {
      backgroundColor: theme.colors.greyScale.backgroundGrey,
      borderRadius: 4,
    },
    white: {
      backgroundColor: theme.colors.primary.white,
      borderRadius: 4,
    },
  };

  const variantsFRONTEND = {
    primary: {
      backgroundColor: theme.colors.greyScale.backgroundGrey,
    },
    secondary: {
      backgroundColor: theme.colors.primary.white,
    },
  };

  const variants = {
    [constants.Templates.BACKEND]: variantsBACKEND,
    [constants.Templates.FRONTEND]: variantsFRONTEND,
  };

  const variantTemplate = appState.template.getValue();

  // TODO delete when restyle is completed
  const variantMap = {
    grey: 'primary',
    white: 'secondary',
  };

  const getVariant = (variant) =>
    variants[variantTemplate][variant] ||
    variants[variantTemplate][variantMap[variant]] ||
    variantsBACKEND[variant];

  const isAIMVariant = variantTemplate === constants.Templates.BACKEND;

  return (
    <>
      <div
        style={{
          ...containerStyle,
        }}
      >
        <AimTypography
          variant="textBold"
          boxStyle={
            variantTemplate === constants.Templates.FRONTEND
              ? { margin: '0px 0px 5px 8px' }
              : { margin: '0px 8px 5px 8px' }
          }
        >
          {label}
        </AimTypography>
        <DragAndDropContainer
          style={style}
          variant={getVariant(nextVariant)}
          ref={parentRef}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              flex: 1,
            }}
            {...getRootProps({ className: 'dropzone' })}
          >
            <div
              style={{ display: 'flex', flex: 1 }}
              className="innerContainer"
            >
              <DragAndDropInnerContainer
                style={{
                  backgroundColor:
                    getVariant(nextVariant).backgroundColor ===
                    theme.colors.greyScale.backgroundGrey
                      ? 'white'
                      : theme.colors.greyScale.backgroundGrey,
                  ...style,
                }}
              >
                <div
                  className="container"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {maxFiles && files?.length >= maxFiles ? (
                      <AimTypography
                        variant={
                          variantTemplate === constants.Templates.FRONTEND
                            ? 'text'
                            : 'h5'
                        }
                        style={{ opacity: 0.5, cursor: 'not-allowed' }}
                      >
                        {fileLimitText || i18n?.fileLimitText}
                      </AimTypography>
                    ) : (
                      <AimTypography
                        variant={
                          variantTemplate === constants.Templates.FRONTEND
                            ? 'text'
                            : 'h5'
                        }
                      >
                        {dragTitle ||
                          (intl
                            ? `${i18n?.title} ${
                                dropzoneAdditionalProps?.accept
                                  ? ` (${dropzoneAdditionalProps?.accept})`
                                  : ''
                              }`
                            : 'Drag and drop your files')}
                      </AimTypography>
                    )}
                  </div>
                  {maxFiles && files?.length >= maxFiles ? null : (
                    <div style={{ display: 'flex' }}>
                      <input {...getInputProps()} />
                      <AimTypography variant="text" margin="0">
                        {dragSubTitle || i18n?.subTitle || 'or'}
                        &nbsp;
                      </AimTypography>
                      <AimTypography
                        variant={
                          variantTemplate === constants.Templates.FRONTEND
                            ? 'text'
                            : 'h6'
                        }
                        margin="0"
                        style={{
                          color:
                            variantTemplate === constants.Templates.FRONTEND
                              ? theme.palette.primary
                              : theme.colors.secondary.lightBlue,
                          cursor: 'pointer',
                        }}
                      >
                        {clickText ||
                          i18n?.selectFileText ||
                          'Select files from your PC'}
                      </AimTypography>
                    </div>
                  )}
                  {helperText && (
                    <AimTypography
                      variant={
                        variantTemplate === constants.Templates.FRONTEND
                          ? 'h7'
                          : 'h6'
                      }
                      margin="0"
                      style={{
                        color: theme.colors.greyScale.grey5,
                      }}
                    >
                      {helperText}
                    </AimTypography>
                  )}
                </div>
              </DragAndDropInnerContainer>
            </div>
            <div style={{ display: 'flex', minWidth: 0 }}>
              <div
                className="buttonsContainer"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginBottom: 10,
                  flex: 1,
                }}
              >
                {uploadBtnWithText ? (
                  <AimIconAndTextButton
                    variant="primary"
                    large
                    text={
                      typeof uploadBtnWithText === 'string'
                        ? uploadBtnWithText
                        : i18n.uploadButton
                    }
                    disabled={maxFiles && files?.length >= maxFiles}
                  ></AimIconAndTextButton>
                ) : (
                  <AimIconButton
                    variant="violetFill"
                    small="true"
                    disabled={maxFiles && files?.length >= maxFiles}
                  >
                    <VerticalAlignTopIcon />
                  </AimIconButton>
                )}
              </div>
            </div>
          </div>
        </DragAndDropContainer>
      </div>
      {isSmallGridEnabled && (
        <Grid
          container
          alignItems="center"
          style={{
            margin: '10px 0px',
            padding: 5,
          }}
        >
          {files.map((file, idx) => (
            <Grid
              key={idx}
              item
              xs={6}
              sm={
                parentRef?.current?.offsetWidth < 700 || grid2ColsEnabled
                  ? 6
                  : 4
              }
              md={
                parentRef?.current?.offsetWidth < 700 || grid2ColsEnabled
                  ? 6
                  : 2
              }
              style={{
                padding: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  // backgroundColor: theme.colors.greyScale.backgroundGrey,
                  padding: 5,
                  ...getVariant(nextVariant),
                }}
              >
                <AimTypography
                  ellipsis
                  style={{
                    flex: 1,
                  }}
                  variant={'h4regular'}
                >
                  {file?.name}
                </AimTypography>
                <AimIconButton
                  variant={isAIMVariant ? 'lightBlueFill' : 'primary'}
                  small="true"
                  onClick={() =>
                    onDownload
                      ? onDownload(file, idx)
                      : downloadFile(file, dirPath)
                  }
                  disabled={disabledDownload}
                >
                  <GetAppIcon />
                </AimIconButton>
                <AimIconButton
                  variant={isAIMVariant ? 'redFill' : 'primary'}
                  small="true"
                  onClick={() => onRemove(file, idx)}
                  disabled={disabledRemove}
                >
                  <CloseIcon />
                </AimIconButton>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
      {!isSmallGridEnabled && files.length > 0 && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            margin: '5px 0px',
          }}
        >
          {files.map((file, idx) => (
            <div
              key={idx}
              style={{
                display: 'flex',
                minWidth: 0,
                maxWidth: '100%',
                flex: 1,
                borderRadius: 3,
                margin: 5,
                padding: 5,
                // backgroundColor: theme.colors.greyScale.backgroundGrey,
                ...getVariant(nextVariant),
                width: 'auto',
              }}
            >
              <>
                <AimTypography
                  ellipsis
                  style={{
                    flex: 1,
                  }}
                  variant={'h4regular'}
                >
                  {file?.name}
                </AimTypography>
                <AimTypography variant={'h4regular'}>
                  {filesize(file?.size || 0)}
                </AimTypography>
                <AimIconButton
                  // variant="lightBlueFill"
                  variant={isAIMVariant ? 'lightBlueFill' : 'primary'}
                  small="true"
                  onClick={() => onDownload(file)}
                  disabled={disabledDownload}
                >
                  <GetAppIcon />
                </AimIconButton>
                <AimIconButton
                  variant={isAIMVariant ? 'redFill' : 'primary'}
                  small="true"
                  onClick={() => onRemove(file)}
                  disabled={disabledRemove}
                >
                  <CloseIcon />
                </AimIconButton>
              </>
            </div>
          ))}
        </div>
      )}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.children}
      </AimSnackbar>
    </>
  );
};

const downloadFile = async (item, dirPath) => {
  const { downloadFromS3 } = fileHelper;
  console.log('dirPath', dirPath);
  if (item.id) {
    return downloadFromS3({ dirPath, fileData: item });
  } else {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(item);
    link.download = item.name;
    link.click();
    link.remove();
  }
};

export const AimDragDropFileUploaderDynamic = ({
  control,
  errors,
  label,
  placeholder,
  name,
  isRequired,
  intl,
  dirPath,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {/* <AimTypography
        variant="textBold"
        boxStyle={{ margin: '0px 8px 5px 8px' }}
      >
        {label}
      </AimTypography> */}
      <Controller
        control={control}
        name={name}
        rules={{
          required: isRequired,
        }}
        render={(props) => (
          <AimDragDropFileUploader
            style={{
              width: 'calc(100% - 10px)',
              alignSelf: 'center',
              marginLeft: 8,
              marginRight: 8,
            }}
            multiple
            label={label}
            dragTitle={placeholder}
            // dragSubTitle={placeholder}
            clickText={intl.formatMessage({
              description: 'select files label',
              defaultMessage: 'select files',
            })}
            helperText={intl.formatMessage({
              description: 'max 10 files label',
              defaultMessage: '(max 10 files)',
            })}
            fileLimitText={intl.formatMessage({
              description: 'Limit of files reached',
              defaultMessage: '(Limit of files reached)',
            })}
            // onLoad={onLoad}
            onUploadFile={(files) => props.onChange(files)}
            onRemove={(file, index) =>
              props.onChange(
                props.value.filter((_, elemIdx) => elemIdx !== index)
              )
            }
            onDownload={(file) => downloadFile(file, dirPath)}
            files={props.value || []}
            maxFiles={10}
            isSmallGridEnabled
          />
        )}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <AimTypography variant="formError">
              {message || `${label} is required`}
            </AimTypography>
          )}
        />
      )}
    </div>
  );
};
