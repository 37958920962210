import React from 'react';

import {
  AimTypography,
  AimSelectForm,
  AimTextFieldForm,
  AimSelectMenuItem,
} from '../../../atoms';
import { theme } from '../../../../theme';
import Grid from '@material-ui/core/Grid';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { constants } from '@aim/common';
import translation from '../translation';

export const Travel = ({ formControlStyle, control, intl, isEditable }) => {
  const i18n = translation.TravelTransfer(intl);
  const travelTypes = Object.values(constants.TravelTypes);
  return (
    <>
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: 10,
          padding: 15,
        }}
      >
        <AimTypography variant="text">{i18n.page.travel.oneWay}</AimTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              type="date"
              name="prefsTravelOneWayDate"
              placeholder="dd/mm/yyyy"
              label={i18n.page.travel.travelDate}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AimSelectForm
              style={{
                backgroundColor: isEditable
                  ? 'white'
                  : theme.colors.greyScale.backgroundGrey,
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelOneWayTravelBy"
              label={i18n.page.travel.travelBy}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            >
              {travelTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelOneWayFlightTrainN"
              label={i18n.page.travel.flightTrainN}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelOneWayLeavingFrom"
              label={i18n.page.travel.leavingFrom}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelOneWayLeavingAt"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.travel.at}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelOneWayIntermediateAirport"
              label={i18n.page.travel.intermediateAirport}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelOneWayArrivingIn"
              label={i18n.page.travel.arrivingIn}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelOneWayArrivingAt"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.travel.at}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: 10,
          padding: 15,
        }}
      >
        <AimTypography variant="text">{i18n.page.travel.return}</AimTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              type="date"
              name="prefsTravelReturnDate"
              placeholder="dd/mm/yyyy"
              label={i18n.page.travel.travelDate}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimSelectForm
              style={{
                backgroundColor: isEditable
                  ? 'white'
                  : theme.colors.greyScale.backgroundGrey,
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelReturnTravelBy"
              label={i18n.page.travel.travelBy}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            >
              {travelTypes.map(({ id, label }) => (
                <AimSelectMenuItem key={label(intl)} value={id}>
                  {label(intl)}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelReturnFlightTrainN"
              label={i18n.page.travel.flightTrainN}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelReturnLeavingFrom"
              label={i18n.page.travel.leavingFrom}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelReturnLeavingAt"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.travel.at}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelReturnIntermediateAirport"
              label={i18n.page.travel.intermediateAirport}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelReturnArrivingIn"
              label={i18n.page.travel.arrivingIn}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AimTextFieldForm
              textfieldVariant={isEditable ? 'secondary' : 'primary'}
              style={{
                border: isEditable
                  ? 'none'
                  : `1px solid ${theme.colors.greyScale.grey5}`,
              }}
              control={control}
              name="prefsTravelReturnArrivingAt"
              type="time"
              placeholder="HH:mm"
              label={i18n.page.travel.at}
              formControlStyle={formControlStyle}
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
      </div>
      {/* <div
        style={{
          margin: 10,
        }}
      > */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AimTextFieldForm
            control={control}
            name="prefsTravelAirplaneFidelityCard"
            placeholder=""
            label={i18n.page.travel.airplaneFidelityCard}
            formControlStyle={formControlStyle}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AimTextFieldForm
            control={control}
            name="prefsTravelTrainFidelityCard"
            placeholder=""
            label={i18n.page.travel.trainFidelityCard}
            formControlStyle={formControlStyle}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AimTextFieldForm
            control={control}
            name="prefsTravelTelephoneNumber"
            placeholder=""
            label={i18n.page.travel.telephoneNumber}
            formControlStyle={formControlStyle}
            disabled={!isEditable}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AimTextFieldForm
            control={control}
            name="prefsTravelNotes"
            placeholder=""
            label={i18n.page.travel.notes}
            formControlStyle={formControlStyle}
            disabled={!isEditable}
          />
        </Grid>
      </Grid>
      {/* </div> */}
    </>
  );
};
