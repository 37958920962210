import React from 'react';
import { AimCheckbox, AimTypography } from '../../atoms';
import { Divider } from '@material-ui/core';
import { fileHelper } from '@aim/common';

export const PrivacySectionForm = ({
  privacyFiles,
  state,
  changeState,
  isPreview,
  eventId,
  requiredText,
}) => {
  const { downloadZipFromS3, downloadFromS3 } = fileHelper;

  const downloadFiles = async (field, privacyId) => {
    const files = field.file;
    if (!files?.length) return;
    const s3Folder = `events/${eventId}/privacy/${privacyId}/`;
    if (files.length > 1) {
      const newFiles = files.map((f) => {
        return { dirPath: s3Folder, ...f };
      });

      const regex = new RegExp('<<([^>]+)>>', 'g');
      const matches = [...field.text.matchAll(regex)];
      const results = matches.map((match) => match[1]);

      downloadZipFromS3(newFiles, `${results?.[0] || 'privacy-files'}.zip`);
    } else {
      await downloadFromS3({ dirPath: s3Folder, fileData: files[0] });
    }
  };

  const getFieldText = (field, privacyId) => {
    const regex = new RegExp('<<([^>]+)>>', 'g');
    let text = '';
    if (!isPreview) {
      text = field.text.split(regex).map((text, idx) =>
        !!(idx % 2) ? (
          <span
            style={{
              textDecoration: 'underline',
              fontWeight: 700,
              color: '#30CFD0',
              cursor: 'pointer',
            }}
            onClick={() => downloadFiles(field, privacyId)}
          >
            {text}
          </span>
        ) : (
          text
        )
      );
    } else {
      text = field.text.split(regex).map((text) => text);
    }

    if (field.mandatory) text.push(`*`);

    return text;
  };

  const previewFontSize = isPreview && { fontSize: '6px' };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        ...previewFontSize,
      }}
    >
      {privacyFiles.length > 0
        ? privacyFiles.map((p) => (
            <div
              key={p.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                width: '100%',
              }}
            >
              <AimTypography
                variant="textBold"
                style={{ lineHeight: 0, ...previewFontSize }}
              >
                {p.name}
              </AimTypography>
              <Divider />
              <AimTypography variant="text" style={{ ...previewFontSize }}>
                {p.description}
              </AimTypography>
              {(p.fields || []).length > 0 &&
                p.fields.map((f, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <AimCheckbox
                        variant="grey"
                        name="privacy"
                        onChange={() => changeState(p.id, idx)}
                        disabled={isPreview}
                        checked={isPreview ? f.value : state[p.id][idx]}
                      />
                      <AimTypography
                        variant="text"
                        style={{ ...previewFontSize }}
                      >
                        {getFieldText(f, p.id)}
                      </AimTypography>
                    </div>
                  );
                })}
            </div>
          ))
        : null}
    </div>
  );
};
