const translation = {
  programTemplate: (intl) => ({
    download: intl.formatMessage({
      description: 'program template download',
      defaultMessage: 'Download',
    }),
    noTitleOfIntervention: intl.formatMessage({
      description: 'no title of intervention message',
      defaultMessage: 'No intervention title',
    }),
  }),
};

export default translation;
