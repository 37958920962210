import { styled as materialUiStyled } from '@material-ui/core/styles';
import { styled as innerMuiStyled } from '@mui/material/styles';

import { nanoid } from 'nanoid';

export const styled = (component) => (style, options) => {
  return materialUiStyled(component)(style, {
    ...options,
    name: nanoid(),
  });
};

export const mui5Styled = innerMuiStyled;
