import React, { useState, useRef, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import { useForm } from 'react-hook-form';

import { translation } from './translation';
import { PaymentDialog } from './PaymentDialog';

import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import SyncIcon from '@material-ui/icons/Sync';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import LockIcon from '@material-ui/icons/Lock';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
const { PromisePool } = require('@supercharge/promise-pool');

import { constants, utilities, appState, aws } from '@aim/common';

import {
  updatePayment,
  listParticipantsByEventId,
  updateProduct,
  getBillingFlowTypesByEvent,
  getMaxBillingFlowNumber,
  createBillingFlow,
  createProductBillingFlow,
  createBillingInformation,
  listPurchases,
} from './tableGqlHelper';

import { AimSnackbar, AimSnackbarSeverity } from '../../atoms/AimSnackbar';
import { AimIconAndTextButton } from '../../atoms/AimIconAndTextButton';
import { AimTitleAndButtons } from '../../bundles/AimTitleAndButtons';
import { AimIconButton } from '../../atoms/AimIconButton';
import { AimDataGrid } from '../../bundles/AimDataGrid/AimDataGrid';
import { debounce } from 'lodash';
import { AimSelectForm, AimSelectMenuItem } from '../../atoms/AimSelect';
import { AimConfirmDialog } from '../../bundles/AimConfirmDialog/AimConfirmDialog';
import { AimDialogForm } from '../../atoms/AimDialog';
import { AimTextFieldForm } from '../../atoms/AimTextField';
const { formatNumber, decodeDbNumber, isValidPayment } = utilities;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const productTypeLabel = {
  REGISTRATION: (intl) =>
    intl.formatMessage({
      description: 'registration',
      defaultMessage: 'Registration',
    }),
  AGENCY_PREPAID_BUDGET: (intl) =>
    intl.formatMessage({
      description: 'agency prepaid budget',
      defaultMessage: 'Agency prepaid budget',
    }),
  AGENCY_PREPAID_GROUP: (intl) =>
    intl.formatMessage({
      description: 'agency prepaid group',
      defaultMessage: 'Prepaid group',
    }),
  AGENCY_GROUP: (intl) =>
    intl.formatMessage({
      description: 'agency group',
      defaultMessage: 'Group',
    }),
  SCIENTIFIC_EVENT: (intl) =>
    intl.formatMessage({
      description: 'scientific event',
      defaultMessage: 'Scientific event',
    }),
  SOCIAL_EVENT: (intl) =>
    intl.formatMessage({
      description: 'social event',
      defaultMessage: 'Social event',
    }),
  ALLOTMENT: (intl) =>
    intl.formatMessage({
      description: 'allotment',
      defaultMessage: 'Allotment',
    }),
};

const formControlStyle = { width: 'calc(100% - 20px)' };

const accountCodes = [
  {
    //IMPORTANT!!!! THE FIRST IS THE DEFAULT MUST NEVER SORT LIST!!!!
    code: '06010200008',
    description: 'ISCRIZIONI',
  },
  {
    code: '06010200005',
    description: 'CENE SOCIALI',
  },
  {
    code: '06010200006',
    description: 'SPONSORIZZAZIONI',
  },
  {
    code: '06010200016',
    description: 'GESTIONE ALBERGHIERA',
  },
  {
    code: '06010200021',
    description: 'DIRITTI DI PRENOTAZIONE',
  },
  {
    code: '06010200017',
    description: "PENALITA' ANNULLAMENTO CAMERE",
  },
  {
    code: '06010200002',
    description: 'ESPOSIZIONI',
  },
  {
    code: '06010200011',
    description: 'SERVIZI SUPPLEMENTARI',
  },
  {
    code: '06010200012',
    description: 'HOSTESS',
  },
  {
    code: 'other',
    description: 'OTHER',
  },
];

const billingStatusColorMap = {
  [constants.BillingStatuses.PROCESSED.id]: '#1FB81F',
  [constants.BillingStatuses.TO_BE_PROCESSED.id]: '#FFD400',
};

const checkMissingBillingInfoFromCustomSource = (billingSource) =>
  !billingSource?.invoiceTo ||
  (billingSource?.invoiceTo === constants.InvoiceToType.COMPANY.id &&
    !billingSource?.vatCode) ||
  (billingSource?.invoiceTo === constants.InvoiceToType.INDIVIDUAL.id &&
    !billingSource?.taxCode);

const filterAndMapPurchases = ({ products, participations, intl, i18n }) => {
  const validPurchases = products.filter(
    (product) =>
      isValidPayment(product.payment) &&
      product?.payment?.paymentType !== constants.PaymentTypes.Free &&
      !product.payment.isDeleted // why checking here if the payment is deleted and not doing it inside isValidPayment? answer: isValidPayment wouldn't be suitable anymore for the upcoming cancelled purchases table
  );

  const purchases = validPurchases?.map((item) => {
    const billing = item?.payment?.BillingInformation || item?.billing;
    const payment = item?.payment;
    const participation = participations.find((p) => p.id === item.clientId);

    const countryJson = billing?.countryIsoCode
      ? constants.countriesIsoCodesJson.find(
          (cc) => cc.alpha2 === billing?.countryIsoCode
        )
      : {};

    return {
      id: item?.id,
      participationUsername: participation?.username,
      participationName: `${participation?.familyName} ${participation?.givenName}`,
      participationEmail: participation?.email,
      participationTypeLabel: Object.values(constants.EventTypes)
        .find((x) => x.id === item.participationType)
        ?.label(intl),
      participation,
      billingStatus: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <span
            style={{
              borderRadius: 60,
              backgroundColor:
                billingStatusColorMap[item.billingStatus] || '#C1B9B9',
              width: 9,
              height: 9,
              flexShrink: 0,
            }}
          ></span>
          <span>
            {Object.values(constants.BillingStatuses)
              .find((st) => st.id === item.billingStatus)
              ?.label(intl) ||
              constants.BillingStatuses.UNPROCESSED.label(intl)}
          </span>
        </div>
      ),
      billingStatusLabel:
        Object.values(constants.BillingStatuses)
          .find((st) => st.id === item.billingStatus)
          ?.label(intl) || constants.BillingStatuses.UNPROCESSED.label(intl),
      service: productTypeLabel?.[item.serviceType]?.(intl),
      createdAt: item?.payment?.createdAt,
      updatedAt: item?.payment?.updatedAt,
      billingEmail: billing?.email,
      pec: billing?.pec,
      invoiceToLabel: Object.values(constants.InvoiceToType)
        .find((x) => x.id === billing?.invoiceTo?.toLowerCase())
        ?.label(intl),
      invoiceTo: billing?.invoiceTo,
      billingName: billing?.surname
        ? `${billing?.name} ${billing?.surname}`
        : billing?.name,
      address: billing?.address,
      postalCode: billing?.postalCode,
      city: billing?.city,
      province: billing?.province,
      region: billing?.region,
      country: billing?.country,
      // countryIsoCode: billing?.countryIsoCode,
      // countryIsoAlpha3: countryJson?.alpha3 || '',
      // isItaly:
      //   billing?.countryIsoCode === 'IT'
      //     ? i18n.aimTablePage.table.yes
      //     : i18n.aimTablePage.table.no,
      // vatCodeOr99Cee: countryJson?.isCee
      //   ? billing?.vatCode || '99999999999'
      //   : '',
      // isNoCee99: !countryJson?.isCee ? '99999999999' : '',
      vatCode: billing?.vatCode,
      taxCode: billing?.taxCode,
      reference: billing?.reference,
      phone: billing?.phone,
      invoiceToPublicAuthority: billing?.invoiceToPublicAuthority
        ? i18n.aimTablePage.table.yes
        : i18n.aimTablePage.table.no,
      uniqueCode: billing?.uniqueCode,
      ipaCode: billing?.ipaCode,
      isSplitPayment: billing?.isSplitPayment
        ? i18n.aimTablePage.table.yes
        : i18n.aimTablePage.table.no,
      isVatEvent: billing?.isVatEvent
        ? i18n.aimTablePage.table.yes
        : i18n.aimTablePage.table.no,
      venue: billing?.venue,
      cig: billing?.cig,
      cup: billing?.cup,
      tripletta: billing?.tripletta,
      amount: formatNumber(decodeDbNumber(payment?.amount)),
      // vatRateCode: '22',
      // netAmount: payment?.amount
      //   ? formatNumber((decodeDbNumber(payment?.amount) / 122) * 100)
      //   : null,
      // vat: payment?.amount
      //   ? formatNumber((decodeDbNumber(payment?.amount) / 122) * 22)
      //   : null,
      amountValue: payment?.amount,
      paymentId: payment?.id,
      paymentNanoId: payment?.paymentId,
      paymentType: Object.values(constants.PaymentTypesWithTranslations)
        .find((x) => x.key === payment?.paymentType)
        ?.label(intl),
      paymentStatusValue: payment?.paymentStatus,
      paymentStatus:
        payment?.paymentType === constants.PaymentTypes.CreditCard
          ? constants.PaymentStatuses.DONE.label(intl)
          : payment?.paymentStatus !== constants.PaymentStatuses.DONE.key
          ? constants.PaymentStatuses.PENDING.label(intl)
          : constants.PaymentStatuses.DONE.label(intl),
      paymentBillingInfoId: item?.payment?.BillingInformation?.id,
      originalProduct: item,
      billing,
    };
  });
  return purchases?.filter((p) => p.participation) || [];
};

const isBillingBtnDisabled = (
  selectedRows,
  isChangeStatus,
  products,
  targetDocType
) => {
  const billingStatuses = constants.BillingStatuses;

  const rowsData = selectedRows.map((r) =>
    products?.find((d) => d.id === r.id)
  );

  let disabledForTypeCheck = false;
  if (isChangeStatus && rowsData.some((rd) => !!rd?.billingStatus))
    disabledForTypeCheck = true;

  if (!isChangeStatus) {
    if (
      targetDocType === 'invoice' &&
      rowsData?.some(
        (rd) => rd.billingStatus !== billingStatuses.TO_BE_PROCESSED.id
      )
    )
      disabledForTypeCheck = true;

    if (
      targetDocType === 'creditNote' &&
      !rowsData?.every(
        (rd) => rd.billingStatus === billingStatuses.TO_BE_PROCESSED.id
      ) &&
      !rowsData?.every(
        (rd) => rd.billingStatus === billingStatuses.PROCESSED.id
      )
    )
      disabledForTypeCheck = true;
  }

  return !selectedRows?.length || !rowsData || disabledForTypeCheck;
};

const renderRowButtons = (
  { row },
  {
    products,
    eventId,
    history,
    billingFlowId,
    i18n,
    setPaymentDialog,
    baseUrl,
    setDialog,
    dialogOnAgreeRef,
  }
) => {
  return (
    <>
      {!billingFlowId ? (
        <AimIconButton
          small
          variant="violetFill"
          onClick={() =>
            setPaymentDialog({
              row,
              payment: {
                id: row.paymentId,
                totalPrice: parseFloat(+row.amountValue / 100)
                  .toFixed(2)
                  .replace('.', ','),
                status: row.paymentStatusValue
                  ? row.paymentStatusValue
                  : row.paymentStatus.toLowerCase(),
                paymentDate: row.lastUpdateDate,
              },
              isOpen: true,
            })
          }
        >
          <AccountBalanceWalletIcon />
        </AimIconButton>
      ) : null}
      {!billingFlowId ? (
        <AimIconButton
          small
          variant="lightBlueFill"
          disabled={isBillingBtnDisabled([row], true, products)}
          onClick={() => {
            const hasRowBillingInfo = !checkMissingBillingInfoFromCustomSource(
              row.originalProduct?.payment?.BillingInformation
            );

            const urlType = hasRowBillingInfo ? 'product' : 'pax';
            const urlId = hasRowBillingInfo ? row.id : row.participation?.id;

            const navigateTo = `/events/${eventId}/participation/billing-info/${urlType}/${urlId}`;

            history.push(navigateTo, {
              backUrl: baseUrl,
            });
          }}
        >
          <PersonIcon />
        </AimIconButton>
      ) : null}
      <AimIconButton
        small
        variant="lightBlueFill"
        onClick={() => history.push(`${baseUrl}/${row?.id}/ticket-info`)}
        disabled={row.locked}
      >
        <VisibilityIcon />
      </AimIconButton>
      {!billingFlowId ? (
        <AimIconButton
          small
          variant="redFill"
          onClick={() => {
            setDialog({
              isOpen: true,
              title: i18n.aimTablePage.dialog.delete.title,
              message: i18n.aimTablePage.dialog.delete.message,
              agreeText: i18n.aimTablePage.dialog.delete.agreeText,
              disagreeText: i18n.aimTablePage.dialog.delete.disagreeText,
              refreshRows: true,
              snackbar: {
                success: i18n.aimTablePage.snackbar.delete.success,
                error: i18n.aimTablePage.snackbar.delete.error,
              },
            });
            dialogOnAgreeRef.current = async () => {
              await updatePayment({
                id: row?.paymentId,
                isDeleted: true,
              });
            };
          }}
          disabled={row.locked}
        >
          <ClearIcon />
        </AimIconButton>
      ) : null}
    </>
  );
};

const loadColumns = ({
  products,
  history,
  eventId,
  billingFlowId,
  i18n,
  setPaymentDialog,
  baseUrl,
  setDialog,
  dialogOnAgreeRef,
}) => {
  const labels = i18n.aimTablePage.table.columns;
  return [
    {
      field: 'paymentNanoId',
      headerName: labels.paymentNanoId,
      width: 100,
    },
    {
      field: 'billingStatusLabel',
      headerName: labels.billingStatusLabel,
      width: 100,
    },
    {
      field: 'service',
      headerName: labels.service,
      width: 100,
    },
    {
      field: 'participationUsername',
      headerName: labels.participationUsername,
      width: 150,
    },
    {
      field: 'participationName',
      headerName: labels.participationName,
      width: 150,
    },
    {
      field: 'participationEmail',
      headerName: labels.participationEmail,
      width: 150,
    },
    {
      field: 'participationTypeLabel',
      headerName: labels.participationTypeLabel,
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: labels.creationDate,
      valueGetter: ({ row }) => new Date(row?.createdAt),
      valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy HH:mm:ss'),
      width: 100,
    },

    {
      field: 'updatedAt',
      headerName: labels.lastUpdateDate,
      valueGetter: ({ row }) => new Date(row?.updatedAt),
      valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy HH:mm:ss'),
      width: 100,
    },

    {
      field: 'invoiceToLabel',
      headerName: labels.invoiceToLabel,
      width: 100,
    },
    {
      field: 'billingName',
      headerName: labels.billingName,
      width: 120,
    },
    {
      field: 'address',
      headerName: labels.address,
      width: 100,
    },
    {
      field: 'postalCode',
      headerName: labels.postalCode,
      width: 100,
    },
    {
      field: 'city',
      headerName: labels.city,
      width: 100,
    },
    { field: 'province', headerName: labels.province, width: 100 },
    { field: 'region', headerName: labels.region, width: 100 },
    {
      field: 'country',
      headerName: labels.country,
      width: 100,
    },
    // {
    //   field: 'countryIsoCode',
    //   headerName: labels.countryIsoCode,
    //   width: 120,
    // },
    // {
    //   field: 'countryIsoAlpha3',
    //   headerName: labels.countryIsoAlpha3,
    //   width: 120,
    //   hide: true,
    // },
    // {
    //   field: 'isItaly',
    //   headerName: labels.isItaly,
    //   width: 100,
    //   hide: true,
    // },
    // {
    //   field: 'vatCodeOr99Cee',
    //   headerName: labels.vatCodeOr99Cee,
    //   width: 100,
    //   hide: true,
    // },
    // {
    //   field: 'isNoCee99',
    //   headerName: labels.isNoCee99,
    //   width: 100,
    //   hide: true,
    // },
    {
      field: 'billingEmail',
      headerName: labels.billingEmail,
      width: 150,
    },
    { field: 'pec', headerName: labels.pec, width: 150 },
    {
      field: 'vatCode',
      headerName: labels.vatCode,
      width: 100,
    },
    {
      field: 'taxCode',
      headerName: labels.taxCode,
      width: 100,
    },
    {
      field: 'reference',
      headerName: labels.reference,
      width: 100,
    },
    {
      field: 'phone',
      headerName: labels.phone,
      width: 100,
    },
    {
      field: 'invoiceToPublicAuthority',
      headerName: labels.invoiceToPublicAuthority,
      width: 100,
    },
    {
      field: 'uniqueCode',
      headerName: labels.uniqueCode,
      width: 100,
    },
    {
      field: 'ipaCode',
      headerName: labels.ipaCode,
      width: 100,
    },
    {
      field: 'isSplitPayment',
      headerName: labels.isSplitPayment,
      width: 100,
    },
    {
      field: 'isVatEvent',
      headerName: labels.isVatEvent,
      width: 100,
    },
    {
      field: 'venue',
      headerName: labels.venue,
      width: 100,
    },
    {
      field: 'cig',
      headerName: labels.cig,
      width: 100,
    },
    {
      field: 'cup',
      headerName: labels.cup,
      width: 100,
    },
    {
      field: 'tripletta',
      headerName: labels.tripletta,
      width: 100,
    },
    // {
    //   field: 'vatRateCode',
    //   headerName: labels.vatRateCode,
    //   width: 100,
    //   hide: true,
    // },
    // {
    //   field: 'netAmount',
    //   headerName: labels.netAmount,
    //   width: 100,
    //   hide: true,
    // },
    // { field: 'vat', headerName: labels.vat, width: 100, hide: true },
    {
      field: 'amount',
      headerName: labels.amount,
      width: 100,
      hide: true,
    },
    {
      field: 'paymentType',
      headerName: labels.paymentType,
      width: 100,
    },
    {
      field: 'paymentStatus',
      headerName: labels.paymentStatus,
      width: 100,
    },
    {
      field: 'actions',
      headerName: 'Actions', // non tradotto xk andrebbe nascosto, devo capire come si fa
      type: 'button',
      width: 200,
      hideSortIcons: true,
      renderCell: (props) =>
        renderRowButtons(props, {
          products,
          eventId,
          history,
          billingFlowId,
          i18n,
          setPaymentDialog,
          baseUrl,
          setDialog,
          dialogOnAgreeRef,
        }),
    },
  ];
};

export const PurchasesTable = ({
  eventId,
  productId,
  billingFlowId,
  serviceType,
  intl,
  history,
  backUrl,
  baseUrl,
  tableTitle = false,
  tableName,
}) => {
  const i18n = translation.purchasesPage(intl);
  const dialogOnAgreeRef = useRef(() => {});
  const participationsRef = useRef();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [dialog, setDialog] = useState({ isOpen: false });
  const [processDialog, setProcessDialog] = useState({ isOpen: false });
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [products, setProducts] = useState([]);
  const [datagridRows, setDatagridRows] = useState([]);
  const [tableRows, setTableRows] = useState({
    checkedRows: [],
    filteredRows: [],
    rawData: {},
  });
  const [billingFlowTypes, setBillingFlowTypes] = useState([]);
  const [paymentDialog, setPaymentDialog] = useState({
    isOpen: false,
  });

  const columns = useMemo(
    () =>
      loadColumns({
        i18n,
        eventId,
        history,
        products,
        billingFlowId,
        setPaymentDialog,
        baseUrl,
        setDialog,
        dialogOnAgreeRef,
      }),
    []
  );

  const buttons = useMemo(() => [
    {
      icon: <SyncIcon />,
      variant: 'green',
      tooltip: i18n.aimTablePage.header.action.sync.tooltip,
      onClick: async () =>
        await aws.standardAPI.post('aimlambdaproxy', '/async/', {
          body: {
            lambdaName: 'aimBilling',
            eventType: 'sync',
            body: {
              eventId,
            },
          },
        }),
    },
    ...(!billingFlowId
      ? [
          {
            variant: 'yellow',
            tooltip: i18n.aimTablePage.header.action.toProcess.tooltip,
            text: i18n.aimTablePage.header.action.toProcess.label,
            onClick: () => {
              setDialog({
                isOpen: true,
                title: i18n.aimTablePage.dialog.changeStatus.title,
                message: i18n.aimTablePage.dialog.changeStatus.message,
                agreeText: i18n.aimTablePage.dialog.changeStatus.agreeText,
                disagreeText:
                  i18n.aimTablePage.dialog.changeStatus.disagreeText,
                refreshRows: true,
                snackbar: {
                  success: i18n.aimTablePage.snackbar.changeStatus.success,
                  error: i18n.aimTablePage.snackbar.changeStatus.error,
                },
              });
              dialogOnAgreeRef.current = async () => {
                checkMissingBillingInfo();
                await PromisePool.for(tableRows.checkedRows)
                  .withConcurrency(10)
                  .handleError(async (error) => {
                    console.error('💥 ERROR: ', error);
                    throw error;
                  })
                  .process(async ({ id }) => {
                    await updateProduct({
                      id,
                      billingStatus:
                        constants.BillingStatuses.TO_BE_PROCESSED.id,
                    });
                  });
              };
            },
            disabled: isBillingBtnDisabled(
              tableRows.checkedRows,
              true,
              products
            ),
          },
          {
            variant: 'yellow',
            tooltip: i18n.aimTablePage.header.action.processInvoice.tooltip,
            text: i18n.aimTablePage.header.action.processInvoice.label,
            onClick: () => {
              setValue('docType', 'invoice');
              setProcessDialog({
                isOpen: true,
                title: i18n.aimTablePage.dialog.process.title,
                message: i18n.aimTablePage.dialog.process.message,
                agreeText: i18n.aimTablePage.dialog.process.agreeText,
                disagreeText: i18n.aimTablePage.dialog.process.disagreeText,
                refreshRows: true,
                snackbar: {
                  success: i18n.aimTablePage.snackbar.process.success,
                  error: i18n.aimTablePage.snackbar.process.error,
                },
              });
            },
            disabled: isBillingBtnDisabled(
              tableRows.checkedRows,
              false,
              products,
              'invoice'
            ),
          },
          {
            variant: 'yellow',
            tooltip: i18n.aimTablePage.header.action.processCreditNote.tooltip,
            text: i18n.aimTablePage.header.action.processCreditNote.label,
            onClick: () => {
              setProcessDialog({
                isOpen: true,
                title: i18n.aimTablePage.dialog.process.title,
                message: i18n.aimTablePage.dialog.process.message,
                agreeText: i18n.aimTablePage.dialog.process.agreeText,
                disagreeText: i18n.aimTablePage.dialog.process.disagreeText,
                refreshRows: true,
                snackbar: {
                  success: i18n.aimTablePage.snackbar.process.success,
                  error: i18n.aimTablePage.snackbar.process.error,
                },
              });
              setValue('docType', 'creditNote');
            },
            disabled: isBillingBtnDisabled(
              tableRows.checkedRows,
              false,
              products,
              'creditNote'
            ),
          },
          {
            icon: <SettingsIcon />,
            variant: 'yellow',
            tooltip: i18n.aimTablePage.header.action.settings.tooltip,
            onClick: () => history.push(`${baseUrl}/settings`),
          },
        ]
      : []),
  ]);

  useEffect(() => {
    getBillingFlowTypesByEvent(eventId).then((flowTypes) => {
      setBillingFlowTypes(flowTypes);
      if (flowTypes?.length) setValue('flowType', flowTypes[0].id);
    });
  }, []);

  useEffect(() => {
    const loadPurchases = async () => {
      const [nextProducts, participations] = await Promise.all([
        listPurchases({
          eventId,
          serviceType,
          billingFlowId,
          productId,
        }),
        listParticipantsByEventId(eventId),
      ]);
      const rows = filterAndMapPurchases({
        products: nextProducts,
        participations,
        intl,
        i18n,
      });
      setProducts(nextProducts);
      participationsRef.current = participations;
      setDatagridRows(rows);
    };
    loadPurchases();
  }, [lastUpdate]);

  //to prevent too many rerender
  const debouncedSelectedVisibleRows = useRef(
    debounce((data) => {
      updateVisibleAndCheckedRows(data);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSelectedVisibleRows.cancel();
    };
  }, [debouncedSelectedVisibleRows]);

  const updateVisibleAndCheckedRows = (data) => {
    const filteredRows =
      Object.entries(data.filter?.visibleRowsLookup || {})
        // eslint-disable-next-line unused-imports/no-unused-vars
        ?.filter(([_, isVisible]) => isVisible)
        .map(([key]) => data.rows.idRowsLookup[key]) || [];
    const checkedRows =
      data.selection?.map((s) => data.rows.idRowsLookup[s]) || [];
    setTableRows({ filteredRows, checkedRows, rawData: data });
  };

  const docTypes = [
    {
      label: i18n.aimTablePage.dialog.process.form.docTypes.invoice,
      value: 'invoice',
    },
    {
      label: i18n.aimTablePage.dialog.process.form.docTypes.creditNote,
      value: 'creditNote',
    },
  ];

  const { handleSubmit, control, errors, getValues, setValue, watch } = useForm(
    {
      shouldUnregister: false,
      defaultValues: {
        docType: docTypes[0].value,
        flowType: null,
        accountCode: accountCodes[0].code,
        accountCodeSelect: accountCodes[0].code,
      },
    }
  );
  const accountCodeSelect = watch('accountCodeSelect');

  const fetchConfirmPayment = async (submittedData) => {
    if (
      submittedData.status === constants.PaymentStatuses.DONE.key &&
      checkMissingBillingInfoFromCustomSource(paymentDialog.row)
    ) {
      return setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.dialog.payment.missingBillingError,
      });
    }

    try {
      await updatePayment({
        id: paymentDialog.payment.id,
        paymentStatus: submittedData.status,
      });
      setPaymentDialog({
        payment: {},
        isOpen: false,
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.dialog.payment.success,
      });
      setLastUpdate(Date.now());
    } catch (err) {
      console.error('💥 ERROR: ', err);
      setPaymentDialog({
        isOpen: false,
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: `${i18n.dialog.payment.error}: ${err.message}`,
      });
    }
  };

  const checkMissingBillingInfo = () => {
    const hasPaymentsMissingBillingInfo = tableRows.checkedRows
      .filter((i) => !i.billing)
      .map((row) => {
        return participationsRef.current.find(
          (p) => p.id === row.participant?.id
        );
      })
      .some((participationData) =>
        checkMissingBillingInfoFromCustomSource(
          participationData.billingInformation
        )
      );

    if (hasPaymentsMissingBillingInfo)
      throw new Error(
        'Missing billing info for at least one selected purchase'
      );
  };

  const checkMissingFlowType = () => {
    const selectedFlowType = getValues('flowType');
    if (!selectedFlowType)
      throw new Error('Missing billing flow types for this event');
  };

  const onCreateFlowSubmit = async (data) => {
    try {
      showLoader();
      const { docType, flowType, accountCode } = data;
      const currentMaxFlowNumber = await getMaxBillingFlowNumber(eventId);

      const user = appState.user.getValue();

      const newFlow = await createBillingFlow({
        docType,
        flowNumber: currentMaxFlowNumber + 1,
        user: `${user?.givenName || ''} ${user?.familyName || ''}`,
        billingFlowTypeId: flowType,
        billingFlowEventId: eventId,
        accountCode: accountCode,
      });
      const billingInfoToCreate = tableRows.checkedRows?.filter((row) =>
        checkMissingBillingInfoFromCustomSource(
          row.originalProduct?.payment.BillingInformation
        )
      );
      await PromisePool.for(billingInfoToCreate || [])
        .withConcurrency(10)
        .handleError(async (error) => {
          console.error('💥 ERROR: ', error);
          throw error;
        })
        .process(async (product) => {
          const customerBillingInfo = participationsRef.current.find(
            (c) => c.id === product.participant.id
          );
          const createBillingInfoInput = {
            ...customerBillingInfo?.billingInformation,
          };
          delete createBillingInfoInput.id;

          const { id } = await createBillingInformation(createBillingInfoInput);
          return updatePayment({
            id: product.paymentId,
            paymentBillingInformationId: id,
          });
        });

      await PromisePool.for(tableRows.checkedRows || [])
        .withConcurrency(10)
        .handleError(async (error) => {
          console.error('💥 ERROR: ', error);
          throw error;
        })
        .process(async ({ id }) => {
          await updateProduct({
            id,
            billingStatus: constants.BillingStatuses.PROCESSED.id,
          });
          await createProductBillingFlow({
            productBillingFlowBillingFlowId: newFlow.id,
            productBillingFlowProductId: id,
          });
        });
      await aws.standardAPI.post('aimlambdaproxy', '/billing/generate-flow', {
        body: {
          billingFlowIds: [newFlow.id],
        },
      });
      setLastUpdate(Date.now());
      setSnackbar({
        isOpen: true,
        message: processDialog.snackbar.success,
        severity: AimSnackbarSeverity.success,
      });
      hideLoader();
    } catch (err) {
      setSnackbar({
        isOpen: true,
        message: processDialog.snackbar.error,
        severity: AimSnackbarSeverity.error,
      });
    }
  };

  const ProcessFlowDialog = () => {
    return (
      <>
        <AimDialogForm
          handleSubmit={handleSubmit}
          errors={errors}
          title={processDialog.title}
          open={processDialog.isOpen}
          onClose={() => setProcessDialog({ isOpen: false })}
          onDisagree={() => setProcessDialog({ isOpen: false })}
          onAgree={(data) => {
            checkMissingBillingInfo();
            checkMissingFlowType();
            onCreateFlowSubmit(data);
          }}
          agreeText={processDialog.agreeText}
          disagreeText={processDialog.disagreeText}
        >
          <AimSelectForm
            control={control}
            name="accountCodeSelect"
            label={
              i18n.aimTablePage.dialog.process.form.labels.selectAccountCode
            }
            formControlStyle={formControlStyle}
            onChange={(accountCode) => {
              const item = accountCodes.find((x) => x.code === accountCode);
              setValue('accountCode', item.code != 'other' ? item.code : '');
              return accountCode;
            }}
          >
            {accountCodes.map((item) => {
              return (
                <AimSelectMenuItem key={item.code} value={item.code}>
                  {item.code
                    ? `${item.code} - ${item.description}`
                    : item.description}
                </AimSelectMenuItem>
              );
            })}
          </AimSelectForm>
          <AimTextFieldForm
            control={control}
            name="accountCode"
            label={
              i18n.aimTablePage.dialog.process.form.labels.insertAccountCode
            }
            formControlStyle={{
              ...formControlStyle,
              display: accountCodeSelect === 'other' ? undefined : 'none',
            }}
            isRequired
          />
          <AimSelectForm
            control={control}
            errors={errors}
            label={i18n.aimTablePage.dialog.process.form.labels.flowType}
            name="flowType"
            formControlStyle={formControlStyle}
            isRequired
          >
            {billingFlowTypes.map((flowType) => (
              <AimSelectMenuItem key={flowType.id} value={flowType.id}>
                {flowType.name}
              </AimSelectMenuItem>
            ))}
          </AimSelectForm>
        </AimDialogForm>
      </>
    );
  };

  return (
    <div>
      <div
        style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}
      >
        <AimIconAndTextButton
          variant="none"
          text={i18n.aimTablePage.header.backButton}
          style={{ padding: 0 }}
          onClick={() => {
            history.push(backUrl);
          }}
        >
          <ArrowBackIcon />
        </AimIconAndTextButton>
      </div>
      <AimTitleAndButtons title={tableTitle || i18n.aimTablePage.header.title}>
        {buttons.map((b) =>
          b.text ? (
            <AimIconAndTextButton
              key={b.text}
              text={b.text}
              variant={b.variant}
              onClick={b.onClick}
              disabled={b.disabled}
            >
              {b.icon}
            </AimIconAndTextButton>
          ) : (
            <Tooltip key={b.tooltip} title={b.tooltip}>
              <AimIconButton
                variant={b.variant}
                onClick={b.onClick}
                disabled={b.disabled}
              >
                {b.icon}
              </AimIconButton>
            </Tooltip>
          )
        )}
      </AimTitleAndButtons>

      <div
        style={{
          height: `calc(${
            appState.mainContainerSize.getValue().height
          }px - 260px)`,
          background: 'white',
        }}
      >
        <AimDataGrid
          columns={columns}
          rows={datagridRows}
          checkboxSelection
          disableSelectionOnClick
          onStateChange={(data) => debouncedSelectedVisibleRows(data)}
          tableName={tableName || 'billing'}
          pinnedColumns={{ left: [], right: ['actions'] }}
          sortModel={[{ field: 'participationName', sort: 'asc' }]}
        />
      </div>
      {paymentDialog?.payment && (
        <PaymentDialog
          i18n={i18n.dialog.payment}
          intl={intl}
          isOpen={paymentDialog.isOpen}
          dialogState={paymentDialog}
          handleConfirm={fetchConfirmPayment}
          onClose={() => setPaymentDialog({ isOpen: false })}
          onDisagree={() => setPaymentDialog({ isOpen: false })}
        />
      )}
      <AimConfirmDialog
        dialog={dialog}
        setDialog={setDialog}
        onAgree={async () => {
          try {
            await dialogOnAgreeRef.current?.();
            dialog.refreshRows && setLastUpdate(Date.now());
            setSnackbar({
              isOpen: true,
              message: dialog.snackbar.success,
              severity: AimSnackbarSeverity.success,
            });
          } catch (err) {
            setSnackbar({
              isOpen: true,
              message: dialog.snackbar.error,
              severity: AimSnackbarSeverity.error,
            });
          }
        }}
        intl={intl}
      />
      <ProcessFlowDialog />
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};
