const translation = {
  dragAndDropFileUploader: (intl) => ({
    title: intl.formatMessage({
      escription: 'drag and drop your files label',
      defaultMessage: 'Drag and drop your files',
    }),
    subTitle: intl.formatMessage({
      description: 'or label',
      defaultMessage: 'or',
    }),
    selectFileText: intl.formatMessage({
      description: 'select file label',
      defaultMessage: 'Select files from your PC',
    }),
    fileLimitText: intl.formatMessage({
      description: 'limit of file reached label',
      defaultMessage: 'Limit of file reached',
    }),
    uploadButton: intl.formatMessage({
      description: 'upload label',
      defaultMessage: 'Upload',
    }),
    fileSizeError: intl.formatMessage({
      description: 'file size error label',
      defaultMessage: 'File is larger than',
    }),
  }),
};

export default translation;
