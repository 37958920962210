import React from 'react';

import { useForm } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';

import { AimTypography } from '../../atoms';

import { AimTextFieldForm } from '@aim/components';

export const PenaltyDialog = ({
  penaltyPrice,
  i18nDialog,
  onAgree,
  refButton,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: { penaltyPrice },
  });

  const sendData = (data) => {
    console.log('🚀 ~ sendData ~ data:', data);
    onAgree && onAgree(data.penaltyPrice);
  };

  const formControlStyle = { width: 'calc(100% - 20px)', margin: 0 };

  return (
    <form
      onSubmit={handleSubmit(sendData)}
      style={{ padding: '0 10px 0px 10px' }}
    >
      <Grid container>
        <Grid item>
          <AimTypography variant="text">
            {i18nDialog.page.dialog.penalty.message}
          </AimTypography>
        </Grid>
        <Grid item xs={12}>
          <AimTextFieldForm
            control={control}
            formControlStyle={formControlStyle}
            label={i18nDialog.page.dialog.penalty.penaltyAmount}
            name="penaltyPrice"
            type="number"
          />
        </Grid>
      </Grid>
      <input type={'submit'} hidden ref={refButton} />
    </form>
  );
};
