import React, { useEffect, useMemo, useState } from 'react';

import {
  DataGridPro as DataGridProInternal,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  useGridApiRef,
  getGridDefaultColumnTypes,
  GridToolbarQuickFilter,
  DEFAULT_GRID_COL_TYPE_KEY,
} from '@mui/x-data-grid-pro';

import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

const defaultColumnTypes = getGridDefaultColumnTypes();

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
});

import BackgroundSvg from './BackgroundSvg';
import { GridToolbarSaveFilters } from './toolbar/GridToolbarSaveFilters';
import { GridToolbarSaveUsernames } from './toolbar/GridToolbarSaveUsername';
import { GridToolbarSaveVisibleColumns } from './toolbar/GridToolbarSaveVisibleColumns';
import { useAimMediaQuery } from '../../hooks';

export const getTableNameSortModel = (tableName) =>
  `aim-mui-datagrid-${tableName}-sort-model`;
export const useAimDataGridApiRef = useGridApiRef;
export const AimDataGrid = ({
  columns,
  rows,
  tableName,
  pinnedColumns = {},
  sortModel = [],
  exportVisible = false,
  ...rest
}) => {
  const [filterModel] = useState(
    localStorage.getItem(`aim-mui-datagrid-${tableName}-filter-model`)
      ? JSON.parse(
          localStorage.getItem(`aim-mui-datagrid-${tableName}-filter-model`)
        )
      : undefined
  );
  const [savedSortModel] = useState(
    localStorage.getItem(`aim-mui-datagrid-${tableName}-sort-model`)
      ? JSON.parse(
          localStorage.getItem(`aim-mui-datagrid-${tableName}-sort-model`)
        )
      : sortModel
  );
  const [hiddenColumns] = useState(
    localStorage.getItem(`aim-mui-datagrid-${tableName}-saved-hidden-columns`)
      ? JSON.parse(
          localStorage.getItem(
            `aim-mui-datagrid-${tableName}-saved-hidden-columns`
          )
        )
      : undefined
  );

  const [ids] = useState(
    localStorage.getItem(`aim-mui-datagrid-${tableName}-ids`)
      ? JSON.parse(localStorage.getItem(`aim-mui-datagrid-${tableName}-ids`))
      : undefined
  );

  // const theme = useTheme();
  const { isMobile, isMobileOnly } = useAimMediaQuery();

  const onSortModelChange = (data) => {
    const LOCAL_STORAGE_SORT_NAME = `aim-mui-datagrid-${tableName}-sort-model`;
    if (!data?.length) {
      localStorage.removeItem(LOCAL_STORAGE_SORT_NAME);
    } else {
      localStorage.setItem(LOCAL_STORAGE_SORT_NAME, JSON.stringify(data));
    }
  };

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  const CustomToolbar = useMemo(() => {
    const ToolbarFunction = () => (
      <GridToolbarContainer
        style={
          isMobileOnly
            ? {
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                paddingBottom: 20,
              }
            : { position: 'relative' }
        }
      >
        {!isMobileOnly && (
          <>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {exportVisible && <GridToolbarExport />}
            <GridToolbarSaveFilters tableName={tableName} />
            <GridToolbarSaveVisibleColumns tableName={tableName} />
            <GridToolbarSaveUsernames
              tableName={tableName}
              rows={rows}
              columns={innerColumns}
            />
          </>
        )}
        <GridToolbarQuickFilter
          style={isMobileOnly ? {} : { position: 'absolute', right: '0.5rem' }}
        />
      </GridToolbarContainer>
    );
    return ToolbarFunction;
  }, [tableName, rows, innerColumns]);

  const classes = useStyles();
  const apiRef = useGridApiRef();

  const innerColumns = React.useMemo(() => {
    /**
     * Function that takes an operator and wrap it to skip filtering for selected rows.
     */
    const wrapOperator = (operator) => {
      const getApplyFilterFn = (filterItem, column) => {
        if (filterItem.operatorValue === 'isAnyOf') {
          const innerFilterFn = (item) => {
            if (
              !Array.isArray(filterItem.value) ||
              filterItem.value.length === 0
            ) {
              return true;
            }
            const filterItemValue = filterItem.value.map((val) => val.trim());

            return filterItemValue.some((filterValue) => {
              return (item.value.toString() || '').match(
                new RegExp(filterValue, 'i')
              );
            });
          };
          return innerFilterFn;
        } else {
          const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
          return innerFilterFn;
        }
      };

      return {
        ...operator,
        getApplyFilterFn,
      };
    };

    return columns.map((col) => {
      if (col.type === 'boolean') {
        col.renderCell = (params) => {
          if (params.value) {
            return <CheckIcon />;
          } else {
            return '';
          }
        };
      }
      const filterOperators =
        col.filterOperators ??
        (
          defaultColumnTypes[col.type] ||
          defaultColumnTypes[DEFAULT_GRID_COL_TYPE_KEY]
        ).filterOperators;

      return {
        ...col,
        filterOperators: filterOperators.map((operator) =>
          wrapOperator(operator)
        ),
      };
    });
  }, [columns]);

  useEffect(() => {
    if (filterModel && !isObjectEmpty) {
      apiRef.current.setFilterModel(filterModel);
    }
  }, [innerColumns, rows, filterModel]);

  useEffect(() => {
    if (savedSortModel && !isObjectEmpty) {
      apiRef.current.setSortModel(savedSortModel);
    }
  }, [innerColumns, rows, savedSortModel]);

  // removed: set it back and reapply the initial sort pattern immediately (ca 1 second)
  // useEffect(() => {
  //   if (Object.keys(apiRef.current).length > 0) {
  //     const gridSortModel = apiRef.current.getSortModel();
  //     if (
  //       innerColumns.length &&
  //       sortModel.length &&
  //       JSON.stringify(sortModel) !== JSON.stringify(gridSortModel)
  //     ) {
  //       apiRef.current.setSortModel(sortModel);
  //       apiRef.current.applySorting();
  //     }
  //   }
  // }, [sortModel, innerColumns]);

  useEffect(() => {
    if (ids) {
      if (rows.length > 0) {
        const filteredRows = ids
          .map((id) => rows.find((r) => r.id === id))
          //to prevent undefined rows otherwise datagrid will throw an error
          .filter((r) => r);
        apiRef.current.setFilterModel({ items: [] });
        apiRef.current.setRows(filteredRows);
      }
    }
  }, [innerColumns, rows, ids]);

  return (
    <DataGridProInternal
      className={classes.root}
      key="1"
      apiRef={apiRef}
      style={{ background: 'white' }}
      columns={innerColumns}
      rows={rows}
      density="compact"
      components={{
        Toolbar: CustomToolbar,
        NoRowsOverlay: BackgroundSvg,
      }}
      slots={{ toolbar: CustomToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      initialState={{
        filter: {
          filterModel: filterModel || {
            items: [],
            quickFilterExcludeHiddenColumns: true,
          },
        },
        columns: {
          columnVisibilityModel: hiddenColumns,
        },
        pinnedColumns,
        sorting: {
          sortModel,
        },
      }}
      onSortModelChange={(data) => onSortModelChange(data)}
      {...rest}
    />
  );
};

export const DataGridPro = DataGridProInternal;
