import { aws, appState, constants } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateFieldValueMutation = /* GraphQL */ `
  mutation UpdateFieldValue(
    $input: UpdateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    updateFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventFieldsQuery = /* GraphQL */ `
  query getFieldsListByContexts(
    $id: ID!
    $serviceName: String
    $filter: ModelFieldContextFilterInput
  ) {
    getEvent(id: $id) {
      standardFields(filter: { isDeleted: { ne: true } }) {
        items {
          key
          controlType
          id
          label
          options
          placeholder
          blockField {
            id
            order
            block {
              id
              firstField
            }
          }
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isHidden
              isRequired
            }
          }
        }
      }
      services(filter: { serviceName: { eq: $serviceName } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: true } }) {
            items {
              controlType
              id
              label
              options
              placeholder
              blockField {
                id
                order
                block {
                  id
                  firstField
                }
              }
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isHidden
                  isRequired
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const createFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    aws.API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('create-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    aws.API.graphql({
      query: updateFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getEventFields = ({
  eventId,
  serviceName,
  contextsOfUse,
  isLoader = true,
}) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    aws.API.graphql({
      query: getEventFieldsQuery,
      variables: {
        id: eventId,
        serviceName,
        filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
      },
    })
      .then(({ data }) => {
        const result = {
          isParticipant: data.getEvent.isParticipant,
          isSpeaker: data.getEvent.isSpeaker,
          isReviewer: data.getEvent.isReviewer,
          standardFields: data.getEvent.standardFields.items,
          customFields: data.getEvent.services.items.length
            ? data.getEvent.services.items[0].customFields.items
            : [],
        };
        resolve(result);
      })
      .catch((e) => {
        console.error('list-event-field-by-context', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
