export default {
  abstractDetail: (intl) => ({
    abstractInfo: {
      category: intl.formatMessage({
        description: 'category title',
        defaultMessage: 'Category',
      }),
      subCategory: intl.formatMessage({
        description: 'subcategory title',
        defaultMessage: 'Subcategory',
      }),
      tags: intl.formatMessage({
        description: 'tags title',
        defaultMessage: 'Tags',
      }),
      presentation: intl.formatMessage({
        description: 'presentation title',
        defaultMessage: 'Presentation',
      }),
      status: intl.formatMessage({
        description: 'status title',
        defaultMessage: 'Status',
      }),
      keywords: intl.formatMessage({
        description: 'keywords title',
        defaultMessage: 'Keywords',
      }),
    },
  }),
};
