import { aws } from '@aim/common';
const { API } = aws;

const getEmailsTemplatesQuery = /* GraphQL */ `
  query GetEmailsTemplates($id: ID!, $templateType: String!) {
    getEvent(id: $id) {
      id
      emailTemplates(filter: { type: { eq: $templateType } }) {
        items {
          id
          type
          name
          subject
          isActive
          body
          trigger
          greetings
          fieldMapping
          ownerType
          unlayerTemplate {
            id
            name
            content {
              model
              string
            }
            isDeleted
            isDefault
            ownerType
          }
        }
      }
    }
  }
`;

const getDefaultUnlayerTemplateQuery = /* GraphQL */ `
  query GetDefaultUnlayerTemplate($id: ID!) {
    getEvent(id: $id) {
      id
      unlayerTemplates(filter: { isDefault: { eq: "true" } }) {
        items {
          id
          name
          content {
            model
            string
          }
        }
      }
    }
  }
`;

const getEventPreTitlesQuery = /* GraphQL */ `
  query GetEventPreTitles($id: ID!) {
    getEvent(id: $id) {
      id
      preTitles {
        items {
          id
          key
          preTitle
          title
        }
      }
    }
  }
`;

export const getEmailTemplates = ({ eventId, templateType, ownerType }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getEmailsTemplatesQuery,
      variables: { id: eventId, templateType, ownerType },
    })
      .then((response) => {
        const nextTemplates = response.data.getEvent.emailTemplates.items.filter(
          (t) => t.trigger.startsWith('manual') && t.isActive === 'true'
        );
        resolve(nextTemplates);
      })
      .catch((e) => {
        console.error('list-event-email-templates', e);
        reject();
      });
  });

export const getPreTitles = (eventId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getEventPreTitlesQuery,
      variables: { id: eventId },
    })
      .then((response) => resolve(response.data.getEvent.preTitles.items))
      .catch((e) => {
        console.error('list-event-pretitles', e);
        reject();
      });
  });

export const getDefaultTemplate = (eventId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getDefaultUnlayerTemplateQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        const defaultTemplate =
          response.data.getEvent.unlayerTemplates.items.length > 0
            ? response.data.getEvent.unlayerTemplates.items[0]
            : null;
        resolve(defaultTemplate);
      })
      .catch((e) => {
        console.error('get-event-default-template', e);
        reject();
      });
  });
