import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

import { format, isEqual } from 'date-fns';

// Material-UI
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';

// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { styled } from '../../styled';
import { richTextToHTML } from '../../atoms/AimRichText/AimRichText';
import { AimTypography } from '../../atoms/AimTypography';
import { AimRadio } from '../../atoms/AimRadio';
import { AimRadioButton } from '../../atoms/AimRadioButton';
import { AimFormControlLabel } from '../../atoms/AimFormControlLabel';
// import { AimIconAndTextButton } from '../../atoms/AimIconAndTextButton';
import { theme } from '../../../theme';

import registerIcon from './assets/images/register.svg';
import abstractIcon from './assets/images/abstract.svg';
import abstractGrantIcon from './assets/images/abstractGrant.svg';
import educationalGrantIcon from './assets/images/educationalGrant.svg';
import allotmentIcon from './assets/images/allotment.svg';
import scientificEventIcon from './assets/images/scientificEvent.svg';
import socialEventIcon from './assets/images/socialEvent.svg';
import programIcon from './assets/images/program.svg';
import visaIcon from './assets/images/visa.svg';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
});

const ServicesContainer = styled('div')(({ backgroundColorOverride }) => ({
  backgroundColor: backgroundColorOverride || theme.colors.greyScale.white,
  width: '100%',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

const useStyles = makeStyles((orTheme) => ({
  cardRoot: ({ isSelected }) => ({
    paddingTop: isMobile ? 'auto' : 'calc(50% - 50px)',
    alignItems: isMobile ? 'center' : 'start',
    width: isMobile ? '100%' : '180px',
    height: '180px',
    borderRadius: 1,
    cursor: 'pointer',
    boxShadow: '0 0 black',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.light.grey,
    border: isSelected
      ? 'solid 2px ' + orTheme.palette.action.selected
      : 'solid 2px transparent',
  }),
  cardContentRoot: {
    flex: 1,
    flexDirection: 'column',
    padding: '0!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DescriptionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export const WelcomePageTemplate = ({ model, history }) => {
  const classes = useStyles();
  const isButtonDisabled = () => {
    const now = new Date();
    const start = new Date(model.activationDate);
    const end = new Date(model.deactivationDate);
    return now >= start && now <= end ? false : true;
  };
  // States
  const [selectedDescr, setSelectedDescr] = useState(0);

  const handleChange = (val) => {
    setSelectedDescr(parseInt(val));
  };

  if (!model) return null;

  const setInnerHtml = (html) => {
    return { __html: richTextToHTML(html) };
  };

  const isStartEqEnd = isEqual(
    new Date(model?.eventStartDate),
    new Date(model?.eventEndDate)
  );
  const startFormatted = format(new Date(model?.eventStartDate), 'dd MMMM');
  const dates = isStartEqEnd
    ? startFormatted
    : `${startFormatted} - ${format(new Date(model?.eventEndDate), 'dd MMMM')}`;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AimTypography variant="textBold">
            {'Choose description'}
          </AimTypography>
          <AimRadio
            value={selectedDescr}
            onChange={(e) => handleChange(e.target.value)}
            formControlWidth="calc(100% - 10px)"
            style={{
              // marginLeft: 16,
              display: 'flex',
            }}
          >
            <AimFormControlLabel
              key={0}
              value={0}
              control={<AimRadioButton />}
              label={'Description'}
            />
            <AimFormControlLabel
              key={1}
              value={1}
              control={<AimRadioButton />}
              label={'Description post-event'}
            />
          </AimRadio>
        </Grid>
      </Grid>
      <RootContainer
        style={{ backgroundImage: `url("${model.coverImage || ''}")` }}
      >
        <Container>
          <AimTypography
            variant="body1"
            component="h2"
            style={{
              fontSize: isMobile ? '2rem' : '6rem',
              fontWeight: '700',
              color: model.titleColor,
              marginBottom: '20px',
            }}
          >
            {model?.title}
          </AimTypography>
          <AimTypography
            style={{
              fontWeight: '600',
              fontSize: isMobile ? '1.5rem' : '2.5rem',
              color: 'white',
              marginTop: 0,
              textAlign: 'center',
            }}
          >
            {dates}
          </AimTypography>
          {selectedDescr === 0 && (
            <DescriptionContainer>
              <div
                style={{
                  color: model.descriptionColor,
                  maxWidth: 'calc(100% - 40px)',
                  textAlign: 'center',
                  marginBottom: '30px',
                }}
                dangerouslySetInnerHTML={setInnerHtml(model.description)}
              />
            </DescriptionContainer>
          )}
          {selectedDescr === 1 && (
            <DescriptionContainer>
              <div
                style={{
                  color: model.descriptionColor,
                  maxWidth: 'calc(100% - 40px)',
                  textAlign: 'center',
                  marginBottom: '30px',
                }}
                dangerouslySetInnerHTML={setInnerHtml(
                  model.descriptionPostEvent
                )}
              />
            </DescriptionContainer>
          )}
        </Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* {participant && participant.isSpeaker && isAtLeastOneActiveService() && (
            <AimIconAndTextButton
              isUpperCase
              textColor="white"
              style={{
                color: 'white',
                marginTop: isMobile ? '1rem' : '8rem',
              }}
              text={i18n.buttons.plan}
              onClick={() => history.push(`/events/${eventId}/faculty`)}
            >
              <ArrowForward />
            </AimIconAndTextButton>
          )} */}
          {/* <AimIconAndTextButton
            isUpperCase
            textColor="white"
            style={{ color: 'white' }}
            text={'Join Event'}
          >
            <ArrowForward />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            isUpperCase
            textColor="white"
            style={{ color: 'white' }}
            text={'My Area'}
          >
            <ArrowForward />
          </AimIconAndTextButton> */}
        </div>
      </RootContainer>
      <ServicesContainer>
        <div
          style={{
            width: isMobile ? '100%' : '1280px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Grid
              container
              spacing={2}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {model.welcomePageBuyTicket && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={registerIcon} />
                      <AimTypography variant="h6">
                        {model.welcomePageBuyTicketLabelItem
                          ? model.welcomePageBuyTicketLabelItem
                          : model.welcomePageBuyTicketTitle}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12} sm={'auto'}>
                <Card className={classes.cardRoot}>
                  <CardContent classes={{ root: classes.cardContentRoot }}>
                    <img src={abstractIcon} />
                    <AimTypography variant="h6">
                      {model.welcomePageFacultyLabel
                        ? model.welcomePageFacultyLabel
                        : 'Faculty page'}
                    </AimTypography>
                  </CardContent>
                </Card>
              </Grid>

              {model.welcomePageSendAbstract && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={abstractIcon} />
                      <AimTypography variant="h6">
                        {model.welcomePageSendAbstractLabelItem
                          ? model.welcomePageSendAbstractLabelItem
                          : model.welcomePageSendAbstractTitle}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {model.welcomePageAbstractGrant && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={abstractGrantIcon} />
                      <AimTypography variant="h6">
                        {model.welcomePageSendAbstractLabelItem
                          ? model.welcomePageSendAbstractLabelItem
                          : model.welcomePageSendAbstractTitle}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {model.welcomePageEducationalGrant && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={educationalGrantIcon} />
                      <AimTypography variant="h6" textAlign="center">
                        {model.welcomePageEducationalGrantLabelItem
                          ? model.welcomePageEducationalGrantLabelItem
                          : model.welcomePageEducationalGrantTitle}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {model.welcomePageAllotment && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={allotmentIcon} />
                      <AimTypography variant="h6">
                        {model.welcomePageAllotmentLabelItem
                          ? model.welcomePageAllotmentLabelItem
                          : model.welcomePageAllotmentTitle}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {model.welcomePageScientificEvent && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={scientificEventIcon} />
                      <AimTypography
                        variant="h6"
                        boxStyle={{
                          fontWeight: 'bold',
                          margin: '10px 10px 0px',
                        }}
                      >
                        {model.welcomePageScientificEventLabelItem
                          ? model.welcomePageScientificEventLabelItem
                          : model.welcomePageAttendScientificEventsTitle}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {model.welcomePageSocialEvent && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={socialEventIcon} />
                      <AimTypography
                        variant="h6"
                        boxStyle={{
                          fontWeight: 'bold',
                          margin: '10px 10px 0px',
                        }}
                      >
                        {model.welcomePageAttendSocialEventsLabelItem
                          ? model.welcomePageAttendSocialEventsLabelItem
                          : model.welcomePageAttendSocialEventsTitle}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {model.welcomePageVisa && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={visaIcon} />
                      <AimTypography variant="h6">
                        {model.welcomePageVisaLabelItem
                          ? model.welcomePageVisaLabelItem
                          : model.welcomePageVisaTitle}{' '}
                      </AimTypography>
                      {/* <AimTypography variant="h4Regular" textAlign="center">
                    Not available for this event
                  </AimTypography> */}
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {model.welcomePageProgram && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={programIcon} />
                      <AimTypography
                        variant="h6"
                        boxStyle={{
                          fontWeight: 'bold',
                          margin: '10px 10px 0px',
                        }}
                      >
                        {model.welcomePageProgramLabelItem || 'Program'}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </ServicesContainer>
    </>
  );
};
