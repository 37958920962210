export default {
  dialog: (intl) => ({
    title: intl.formatMessage({
      description: 'assign travel title',
      defaultMessage: 'Assign travel',
    }),
    oneWay: intl.formatMessage({
      description: 'one way label',
      defaultMessage: 'A',
    }),
    return: intl.formatMessage({
      description: 'return label',
      defaultMessage: 'R',
    }),
    oneWayReturn: intl.formatMessage({
      description: 'one-Way return label',
      defaultMessage: 'One-Way Return',
    }),
    departureDate: intl.formatMessage({
      description: 'departure date label',
      defaultMessage: 'Departure date',
    }),
    arrivalDate: intl.formatMessage({
      description: 'arrival date label',
      defaultMessage: 'Arrival date',
    }),
    travelType: intl.formatMessage({
      description: 'travel type label',
      defaultMessage: 'Travel type',
    }),
    travelDetails: intl.formatMessage({
      description: 'travel details label',
      defaultMessage: 'Travel details',
    }),
    code: intl.formatMessage({
      description: 'code label',
      defaultMessage: 'Code',
    }),
    startingPoint: intl.formatMessage({
      description: 'starting point label',
      defaultMessage: 'Starting point',
    }),
    departureTime: intl.formatMessage({
      description: 'departure time label',
      defaultMessage: 'Departure time',
    }),
    pointOfArrival: intl.formatMessage({
      description: 'point of arrival label',
      defaultMessage: 'Point of arrival',
    }),
    arrivalTime: intl.formatMessage({
      description: 'arrival time label',
      defaultMessage: 'Arrival time',
    }),
    pnr: intl.formatMessage({
      description: 'pnr label',
      defaultMessage: 'N° PNR',
    }),
    ticketRequest: intl.formatMessage({
      description: 'ticket request label',
      defaultMessage: 'Ticket request',
    }),
    totalCost: intl.formatMessage({
      description: 'total cost label',
      defaultMessage: 'Total cost (€)',
    }),
    checkInLink: intl.formatMessage({
      description: 'regional check-in link label',
      defaultMessage: 'Regional check-in link',
    }),
    businessUnit: intl.formatMessage({
      description: 'business unit label',
      defaultMessage: 'Business unit',
    }),
    order: intl.formatMessage({
      description: 'order label',
      defaultMessage: 'Order',
    }),
    note: intl.formatMessage({
      description: 'note label',
      defaultMessage: 'Note',
    }),
    add: intl.formatMessage({
      description: 'add label',
      defaultMessage: 'Add',
    }),
    edit: intl.formatMessage({
      description: 'edit label',
      defaultMessage: 'Edit',
    }),
    fileLimitText: intl.formatMessage({
      description: 'privacy file selected label',
      defaultMessage: 'Privacy file selected',
    }),
    selectFileText: intl.formatMessage({
      description: 'select file label',
      defaultMessage: 'Select files from your PC',
    }),
    dragSubTitle: intl.formatMessage({
      description: 'or label',
      defaultMessage: 'or',
    }),
    dragTitle: intl.formatMessage({
      description: 'drag and drop your files label',
      defaultMessage: 'Drag and drop your files',
    }),
    uploadTicket: intl.formatMessage({
      description: 'ipload ticket label',
      defaultMessage: 'Upload ticket',
    }),
    airplane: intl.formatMessage({
      description: 'airplane option label',
      defaultMessage: 'Airplane',
    }),
    train: intl.formatMessage({
      description: 'train option label',
      defaultMessage: 'Train',
    }),
    bus: intl.formatMessage({
      description: 'bus option label',
      defaultMessage: 'Bus',
    }),
    personalTransport: intl.formatMessage({
      description: 'personal transport option label',
      defaultMessage: 'Personal transport',
    }),
    other: intl.formatMessage({
      description: 'other option label',
      defaultMessage: 'Other',
    }),
    ship: intl.formatMessage({
      description: 'ship option label',
      defaultMessage: 'Ship',
    }),
    groupFlight: intl.formatMessage({
      description: 'group flight option label',
      defaultMessage: 'Group flight',
    }),
    processed: intl.formatMessage({
      description: 'processed option label',
      defaultMessage: 'Processed',
    }),
    toProcess: intl.formatMessage({
      description: 'to process option label',
      defaultMessage: 'To process',
    }),
    guests: {
      guestIsSelectedParticipant: intl.formatMessage({
        description: 'the guest is selected participant label',
        defaultMessage: 'Select if the guest is selected participant',
      }),
      guestIsNotRegistered: intl.formatMessage({
        description: 'the guest is not registered label',
        defaultMessage: 'The guest is not registered',
      }),
      guestIsRegisteredParticipant: intl.formatMessage({
        description: 'the guest is a registered participant label',
        defaultMessage: 'Select if the guest is a registered participant',
      }),
      writeNameSurnameOrEmailPlaceholder: intl.formatMessage({
        description: 'write name, surname or email label',
        defaultMessage: 'write name, surname or email',
      }),
    },
    showPrice: intl.formatMessage({
      description: 'show price label',
      defaultMessage: 'Show price for participant',
    }),
  }),
};
