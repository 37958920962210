import React from 'react';

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { AimTypography } from './AimTypography';
import { AimTextField } from './AimTextField';

export const AimDatePicker = ({
  label,
  displayLabel = true,
  formControlStyle,
  variant,
  labelPlacementLeft,
  customLabel,
  fieldStyle,
  addAsterisk = false,
  autoOk = true,
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk={autoOk}
        {...rest}
        disableToolbar
        disableFocusRipple
        variant="standard"
        format="dd/MM/yyyy"
        margin="normal"
        //minDate={new Date()}
        //maxDate={new Date()} //maxDate
        id="date-picker-inline"
        KeyboardButtonProps={{
          'aria-label': 'change dassste',
          disableFocusRipple: true,
          disableRipple: true,
        }}
        TextFieldComponent={(props) => (
          <AimTextField
            {...props}
            textfieldVariant={variant}
            label={label}
            displayLabel={displayLabel}
            formControlStyle={formControlStyle}
            labelPlacementLeft={labelPlacementLeft}
            customLabel={customLabel}
            fieldStyle={fieldStyle}
            addAsterisk={addAsterisk}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

export const AimDatePickerForm = ({
  control,
  name,
  defaultValue = '',
  errors,
  isRequired,
  label,
  validate,
  onChange,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimDatePicker
          label={label}
          value={props.value}
          onChange={(e) => {
            props.onChange(e);
            onChange && onChange(e);
          }}
          addAsterisk={isRequired}
          {...rest}
        />
      )}
      rules={{
        required: isRequired,
        validate: validate,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return (
            <div>
              <AimTypography variant="formError">
                {message ||
                  (errors?.[name]?.type === 'validate'
                    ? `${label} is not valid`
                    : `${label} is required`)}
              </AimTypography>
            </div>
          );
        }}
      />
    )}
  </>
);
