import React from 'react';
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  View,
  Svg,
  Polygon,
} from '@react-pdf/renderer';

import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';

import { format } from 'date-fns';

import { appState, constants, utilities } from '@aim/common';

import Logo from './images/logo.jpg';
//DO NOT REMOVE... IT IMPORTS THE FONT

const { decodeDbNumber, formatNumber } = utilities;

const formatDate = (dateString) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  const date = zonedTimeToUtc(dateString, currentTimezone);

  return formatInTimeZone(date, currentTimezone, 'dd/MM/yyyy');
};

function ucwords(str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
}

const Footer = () => {
  return (
    <View style={styles.footer} fixed>
      <View style={{ flex: 0.5, alignItems: 'flex-start' }}>
        <Text
          style={{ fontSize: 8 }}
          render={({ pageNumber }) =>
            `${format(new Date(), 'dd/MM/yyyy')} ${format(
              new Date(),
              'HH:mm:ss'
            )} ${`Page: `}${pageNumber}`
          }
        />
      </View>
      <View style={{ flex: 1, alignItems: 'center' }}>
        <Text style={{ fontSize: 8 }}>
          {`AIM Group® - AIM Group International Sa® - AIM S.p.A.® 
          Copyright © 2003-${new Date().getFullYear()} AIM S.p.A.`}
        </Text>
      </View>
      <View style={{ flex: 0.5, alignItems: 'flex-end' }} />
    </View>
  );
};

const slightPadding = { padding: 10 };
const greyBackground = { backgroundColor: '#F8F6FA' };
const whiteBackground = { backgroundColor: 'white' };

const Separator = ({ backgroundColor = '#DBD9E2' }) => {
  return <View style={{ backgroundColor, height: 1 }} />;
};

const Spacer = ({ size }) => {
  return <View style={{ minHeight: size }} />;
};

const SectionTitle = ({ sectionTitle }) => {
  return (
    <View>
      <Text style={{ fontSize: 20 }}>{sectionTitle}</Text>
    </View>
  );
};
const SectionText = ({ text }) => {
  return (
    <View>
      <Text style={styles.text}>{`${text || '-'}`}</Text>
    </View>
  );
};

const RenderLabelValue = ({ label, value }) => (
  <View style={{ flexDirection: 'row', display: 'flex' }}>
    <View style={{ flex: 0.5 }}>
      <Text style={styles.label}>{`${label}:`}</Text>
    </View>
    <View style={{ flex: 0.5 }}>
      {typeof value === 'object' ? (
        value
      ) : (
        <Text style={styles.text}>{`${value || '-'}`}</Text>
      )}
    </View>
  </View>
);

const Star = ({ color = 'black' }) => (
  <Svg viewBox="0 0 20 20">
    <Polygon
      points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78"
      fill={color}
    />
  </Svg>
);

const AllotmentSection = ({ data, i18n, intl, showPrice }) => {
  const hotels = [data.allotmentInformations.data[data.ndx]]; //sorry about this

  return (
    <View>
      {hotels.map((hotel, ndx) => {
        const metadata = hotel?.payment?.paymentMetadata;
        let metadataList = [];

        if (metadata) {
          const rooms = JSON.parse(metadata).rooms || [];
          if (rooms.length > 0) {
            metadataList = [...rooms];
          }
        }

        return (
          <View
            key={ndx}
            style={{ ...greyBackground, ...slightPadding }}
            wrap={hotel.hotelRoomReservation.length < 5 ? false : true}
          >
            <SectionTitle sectionTitle={i18n.pdf.bookingInfo} />
            <Spacer size={5} />
            <Separator />
            <Spacer size={15} />
            {hotel.hotelRoomReservation.map((roomReservation, ndx) => {
              const guests = roomReservation?.guests?.items?.map((guest) => {
                return `${guest.givenName} ${guest.familyName}`;
              });

              const hotelRoomId = roomReservation.hotelRoom.id;

              const roomMetadata =
                metadataList.find((x) => x.id === hotelRoomId) || null;

              return (
                <View
                  wrap={false}
                  key={ndx}
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    ...whiteBackground,
                    marginTop: 5,
                    marginBottom: 5,
                    ...slightPadding,
                  }}
                >
                  <View style={{ flex: 0.5, paddingRight: 5 }}>
                    <RenderLabelValue
                      label={i18n.pdf.hotel}
                      value={hotel.hotelName}
                    />
                    <RenderLabelValue
                      label={i18n.pdf.hotelAddress}
                      value={hotel.hotelAddress}
                    />
                    <RenderLabelValue
                      label={i18n.pdf.hotelStars}
                      value={
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          {[...Array(hotel.hotelStars)].map((item, ndx) => {
                            return (
                              <View
                                key={`STAR_${ndx}`}
                                style={{ width: 10, height: 10 }}
                              >
                                <Star {...{ color: 'orange' }} />
                              </View>
                            );
                          })}
                        </View>
                      }
                    />
                    <RenderLabelValue
                      label={i18n.pdf.hotelPhone}
                      value={hotel.hotelPhone}
                    />
                    <RenderLabelValue
                      label={i18n.pdf.hotelEmail}
                      value={hotel.hotelEmail}
                    />
                    <Spacer size={10} />
                    <RenderLabelValue
                      label={i18n.pdf.roomTypology}
                      value={
                        roomReservation.hotelRoom.name ||
                        roomReservation.hotelRoom.frontofficeName
                      }
                    />
                    <RenderLabelValue
                      label={i18n.pdf.guests}
                      value={guests.join(', ')}
                    />
                    <RenderLabelValue
                      label={i18n.pdf.checkIn}
                      value={formatDate(roomReservation?.startDate)}
                    />
                    <RenderLabelValue
                      label={i18n.pdf.checkOut}
                      value={formatDate(roomReservation?.endDate)}
                    />
                    {roomReservation?.treatment ? (
                      <RenderLabelValue
                        label={i18n.pdf.hotelTreatment}
                        value={Object.values(constants.HotelTreatments)
                          .find(({ key }) => key === roomReservation.treatment)
                          ?.label(intl)}
                      />
                    ) : null}
                  </View>
                  {roomMetadata && showPrice !== false ? (
                    <View style={{ flex: 0.5 }}>
                      <RenderLabelValue
                        label={i18n.pdf.amountVatExcluded}
                        value={
                          decodeDbNumber(roomMetadata.amount)
                            ? formatNumber(decodeDbNumber(roomMetadata.amount))
                            : '-'
                        }
                      />
                      <RenderLabelValue
                        label={i18n.pdf.vat}
                        value={
                          decodeDbNumber(roomMetadata.vat)
                            ? decodeDbNumber(roomMetadata.vat)
                            : '-'
                        }
                      />
                      <RenderLabelValue
                        label={i18n.pdf.amountVatIncluded}
                        value={
                          (decodeDbNumber(roomMetadata.amount) / 100) *
                            decodeDbNumber(roomMetadata.vat) +
                          decodeDbNumber(roomMetadata.amount)
                            ? formatNumber(
                                (decodeDbNumber(roomMetadata.amount) / 100) *
                                  decodeDbNumber(roomMetadata.vat) +
                                  decodeDbNumber(roomMetadata.amount)
                              )
                            : '-'
                        }
                      />
                    </View>
                  ) : null}
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

const TravelSection = ({ data, i18n, intl }) => {
  const travels = [data.travelInformations.data[data.ndx]]; //sorry about this

  return (
    <View>
      {travels.map((travel, ndx) => {
        const guests = travel?.guests?.items?.map((guest) => {
          return `${guest.givenName} ${guest.familyName}`;
        });

        return (
          <View
            key={ndx}
            style={{ ...greyBackground, ...slightPadding }}
            wrap={false}
          >
            <SectionTitle sectionTitle={i18n.pdf.bookingInfo} />
            <Spacer size={5} />
            <Separator />
            <Spacer size={15} />

            {/* note only for personal transport type */}
            {travel?.travelRoute?.travelType ===
              constants.TravelTypes.PERSONALTRANSPORT.id && (
              <View
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  ...whiteBackground,
                  marginTop: 5,
                  marginBottom: 5,
                  ...slightPadding,
                }}
              >
                <SectionText text={i18n.pdf.personalTransportDescr} />
              </View>
            )}

            <View
              key={ndx}
              style={{
                flexDirection: 'row',
                display: 'flex',
                ...whiteBackground,
                marginTop: 5,
                marginBottom: 5,
                ...slightPadding,
              }}
            >
              <View style={{ flex: 0.5 }}>
                {/* travel type */}
                <RenderLabelValue
                  label={i18n.pdf.typeOfTravel}
                  // value={ucwords(travel?.travelRoute?.travelType)}
                  value={Object.values(constants.TravelTypes)
                    .find((t) => t.id === travel?.travelRoute?.travelType)
                    ?.label(intl)}
                />

                {/* travel code */}
                {travel?.travelRoute?.transportCode !== '' && (
                  <RenderLabelValue
                    label={i18n.pdf.travelCode}
                    value={travel?.travelRoute?.transportCode.toUpperCase()}
                  />
                )}

                {/* A/R */}
                <RenderLabelValue
                  label={i18n.pdf.aR}
                  value={travel.returnTrip === 'true' ? 'R' : 'A'}
                />

                {/* guests */}
                {guests?.length > 0 && (
                  <RenderLabelValue
                    label={i18n.pdf.accompanyingPersons}
                    value={guests.join(', ')}
                  />
                )}
              </View>
              <View style={{ flex: 0.5 }}>
                {/* terminal in */}
                {travel?.travelRoute?.terminalIn &&
                  travel?.travelRoute?.terminalIn?.name !== '' && (
                    <RenderLabelValue
                      label={i18n.pdf.departureFrom}
                      value={travel?.travelRoute?.terminalIn?.name}
                    />
                  )}

                {/* departure date */}
                {travel?.date && (
                  <RenderLabelValue
                    label={i18n.pdf.departureDate}
                    value={travel.date ? formatDate(travel.date) : '-'}
                  />
                )}

                {/* departure time */}
                {travel?.travelRoute?.startDate && (
                  <RenderLabelValue
                    label={i18n.pdf.departureTime}
                    value={travel?.travelRoute?.startDate}
                  />
                )}

                {/* terminal out */}
                {travel?.travelRoute?.terminalOut &&
                  travel?.travelRoute?.terminalOut?.name !== '' && (
                    <RenderLabelValue
                      label={i18n.pdf.arrivalTo}
                      value={travel?.travelRoute?.terminalOut?.name}
                    />
                  )}

                {/* arrival date */}
                {travel?.arrivalDate && (
                  <RenderLabelValue
                    label={i18n.pdf.arrivalDate}
                    value={
                      travel?.arrivalDate
                        ? formatDate(travel?.arrivalDate)
                        : '-'
                    }
                  />
                )}

                {/* arrival time */}
                {travel?.travelRoute?.endDate && (
                  <RenderLabelValue
                    label={i18n.pdf.arrivalTime}
                    value={travel?.travelRoute?.endDate}
                  />
                )}

                {/* prn code */}
                {travel?.pnrCode && (
                  <RenderLabelValue
                    label={i18n.pdf.pnr}
                    value={travel?.pnrCode}
                  />
                )}
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const TransferSection = ({ data, i18n }) => {
  const transfers = [data.transferInformations.data[data.ndx]]; //sorry about this
  return (
    <View>
      {transfers.map((travel, ndx) => {
        // const guests = travel?.guests?.items?.map((guest) => {
        //   return `${guest.givenName} ${guest.familyName}`;
        // });
        return (
          <View
            key={ndx}
            style={{ ...greyBackground, ...slightPadding }}
            wrap={false}
          >
            <SectionTitle sectionTitle={i18n.pdf.bookingInfo} />
            <Spacer size={5} />
            <Separator />
            <Spacer size={15} />

            <View
              key={ndx}
              style={{
                flexDirection: 'row',
                display: 'flex',
                ...whiteBackground,
                marginTop: 5,
                marginBottom: 5,
                ...slightPadding,
              }}
            >
              <View style={{ flex: 0.5 }}>
                <RenderLabelValue
                  label={i18n.pdf.date}
                  value={formatDate(travel.date)}
                />
                <RenderLabelValue
                  label={i18n.pdf.from}
                  value={`${travel.from}${
                    travel.pickUpLocation ? `, ${travel.pickUpLocation}` : ''
                  }`}
                />
                <RenderLabelValue label={i18n.pdf.time} value={travel.time} />
                <RenderLabelValue
                  label={i18n.pdf.to}
                  value={`${travel.to}${
                    travel.pickUpDestination
                      ? `, ${travel.pickUpDestination}`
                      : ''
                  }`}
                />
                `
              </View>
              <View style={{ flex: 0.5 }}>{/* here */}</View>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const PaymentDetails = ({
  // amount1,
  // VATRate1,
  // amount2,
  // VATRate2,
  total = '',
  paymentMethod = '',
  IBAN = '',
  paymentReason = '',
  paymentStatus = '',
  addressedTo = '',
  additionalServicePaymentType = null,
  i18n,
  showPrice,
}) => {
  if (additionalServicePaymentType === 'free') {
    return null;
  }
  return (
    <View style={{ ...greyBackground, ...slightPadding }} wrap={false}>
      <SectionTitle sectionTitle={i18n.pdf.paymentDetailsTitle} />

      <Spacer size={5} />

      <Separator />
      <Spacer size={15} />
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        {showPrice !== false && (
          <View style={{ flex: 0.5 }}>
            <RenderLabelValue label={i18n.pdf.total} value={total} />
          </View>
        )}
        <View style={{ flex: 0.5 }}>
          <RenderLabelValue
            label={i18n.pdf.paymentMethod}
            value={paymentMethod}
          />
          <RenderLabelValue label={i18n.pdf.iban} value={IBAN} />
          <RenderLabelValue
            label={i18n.pdf.paymentReason}
            value={paymentReason}
          />
          <RenderLabelValue label={i18n.pdf.addressedTo} value={addressedTo} />
          <RenderLabelValue
            label={i18n.pdf.paymentStatus}
            value={paymentStatus}
          />
        </View>
      </View>
    </View>
  );
};

const ClientInformations = ({
  givenName = '-',
  familyName = '-',
  email = '-',
  phone = '-',
  guests = [],
  i18n,
}) => {
  const guestsValue = guests
    ?.map((guest) => `${guest.givenName} ${guest.familyName} ${guest.country}`)
    .join(', ');
  return (
    <View style={{ ...greyBackground, ...slightPadding }}>
      <SectionTitle sectionTitle={i18n.pdf.clientInformationsTitle} />

      <Spacer size={5} />

      <Separator />
      <Spacer size={15} />
      <RenderLabelValue label={i18n.pdf.name} value={givenName} />
      <RenderLabelValue label={i18n.pdf.surname} value={familyName} />
      <RenderLabelValue label={i18n.pdf.email} value={email} />
      <RenderLabelValue label={i18n.pdf.phone} value={phone} />
      {guestsValue && (
        <View>
          <RenderLabelValue label={i18n.pdf.guests} value={guestsValue} />
        </View>
      )}
    </View>
  );
};

const RegistrationInformations = ({
  registration = '-',
  start = '-',
  end = '-',
  i18n,
}) => {
  return (
    <View style={{ ...greyBackground, ...slightPadding }}>
      <SectionTitle sectionTitle={i18n.pdf.registrationInformationsTitle} />
      <Spacer size={5} />
      <Separator />
      <Spacer size={15} />

      <RenderLabelValue
        label={i18n.pdf.registration}
        value={registration || '-'}
      />
      <RenderLabelValue label={i18n.pdf.start} value={start || '-'} />
      <RenderLabelValue label={i18n.pdf.end} value={end || '-'} />
    </View>
  );
};

const AdditionalServicesInformations = ({
  title = '-',
  description = '-',
  date = '-',
  startTime = '-',
  endTime = '-',
  locationName = '-',
  locationAddress = '-',
  paymentType,
  payment,
  i18n,
  showPrice,
}) => {
  const metadata =
    payment && payment.paymentObject?.paymentMetadata
      ? JSON.parse(payment.paymentObject.paymentMetadata)
      : null;

  return (
    <View style={{ ...greyBackground, ...slightPadding }}>
      <SectionTitle sectionTitle={i18n.pdf.serviceInformationsTitle} />
      <Spacer size={5} />
      <Separator />
      <Spacer size={15} />
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View style={{ flex: paymentType === 'free' ? 0.5 : 1 }}>
          <RenderLabelValue label={i18n.pdf.event} value={title} />
          <RenderLabelValue label={i18n.pdf.description} value={description} />
          <RenderLabelValue label={i18n.pdf.date} value={date} />
          <RenderLabelValue label={i18n.pdf.startTime} value={startTime} />
          <RenderLabelValue label={i18n.pdf.endTime} value={endTime} />
          <RenderLabelValue label={i18n.pdf.location} value={locationName} />
          <RenderLabelValue label={i18n.pdf.address} value={locationAddress} />
        </View>
        {paymentType === 'free' ? (
          <View style={{ flex: 0.5 }}>
            <RenderLabelValue label={i18n.pdf.total} value={i18n.pdf.free} />
          </View>
        ) : null}
        {showPrice !== false && paymentType !== 'free' && metadata ? (
          <View style={{ flex: 0.5 }}>
            <RenderLabelValue
              label={`${i18n.pdf.amount} ${metadata.netAmount2 ? '#1' : ''}`}
              value={
                metadata.netAmount1
                  ? formatNumber(decodeDbNumber(metadata.netAmount1))
                  : '-'
              }
            />
            <RenderLabelValue
              label={`${i18n.pdf.vat} (%) ${metadata.netAmount2 ? '#1' : ''}`}
              value={
                metadata.vatRate1 ? decodeDbNumber(metadata.vatRate1) : '-'
              }
            />
            {metadata.netAmount2 ? (
              <View>
                <RenderLabelValue
                  label={`${i18n.pdf.amount} #2`}
                  value={
                    metadata.netAmount2
                      ? formatNumber(decodeDbNumber(metadata.netAmount2))
                      : '-'
                  }
                />
                <RenderLabelValue
                  label={`${i18n.pdf.vat} (%) #2`}
                  value={
                    metadata.vatRate2 ? decodeDbNumber(metadata.vatRate2) : '-'
                  }
                />
              </View>
            ) : null}
            <RenderLabelValue
              label={i18n.pdf.total}
              value={formatNumber(
                decodeDbNumber(payment?.paymentObject?.amount)
              )}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
};

const BillingInformations = ({
  invoiceTo = '-',
  companyOrganization = '-',
  address = '-',
  city = '-',
  postalCode = '-',
  region = '-',
  province = '-',
  country = '-',
  VATNumber = '-',
  fiscalCode = '-',
  reference = '-',
  email = '-',
  phone = '-',
  invoiceToPublicAuthority = null,
  uniqueCode = '-',
  IPACode = '-',
  splitPayment = null,
  isVATExent = null,
  additionalServicePaymentType = null,
  i18n,
}) => {
  if (additionalServicePaymentType === 'free') {
    return null;
  }
  return (
    <View style={{ ...greyBackground, ...slightPadding }}>
      <SectionTitle sectionTitle={i18n.pdf.billingInformationsTitle} />

      <Spacer size={5} />

      <Separator />
      <Spacer size={15} />
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View style={{ flex: 0.5 }}>
          <RenderLabelValue label={i18n.pdf.invoiceTo} value={invoiceTo} />
          <RenderLabelValue
            label={i18n.pdf.companyOrganization}
            value={companyOrganization}
          />
          <RenderLabelValue label={i18n.pdf.address} value={address} />
          <RenderLabelValue label={i18n.pdf.city} value={city} />
          <RenderLabelValue label={i18n.pdf.postalCode} value={postalCode} />
          <RenderLabelValue label={i18n.pdf.region} value={region} />
          <RenderLabelValue label={i18n.pdf.province} value={province} />
          <RenderLabelValue label={i18n.pdf.country} value={country} />
          <RenderLabelValue label={i18n.pdf.vatNumber} value={VATNumber} />
          <RenderLabelValue label={i18n.pdf.fiscalCode} value={fiscalCode} />
          <RenderLabelValue label={i18n.pdf.reference} value={reference} />
          <RenderLabelValue label={i18n.pdf.email} value={email} />
          <RenderLabelValue label={i18n.pdf.phone} value={phone} />
        </View>
        <View style={{ flex: 0.5 }}>
          <RenderLabelValue
            label={i18n.pdf.invoiceToPublicAuthority}
            value={invoiceToPublicAuthority ? i18n.pdf.yes : i18n.pdf.no}
          />
          <RenderLabelValue label={i18n.pdf.uniqueCode} value={uniqueCode} />
          <RenderLabelValue label={i18n.pdf.ipaCode} value={IPACode} />
          <RenderLabelValue
            label={i18n.pdf.splitPayment}
            value={splitPayment ? i18n.pdf.yes : i18n.pdf.no}
          />
          <RenderLabelValue
            label={i18n.pdf.isVatExentExtended}
            value={isVATExent ? i18n.pdf.yes : i18n.pdf.no}
          />
        </View>
      </View>
    </View>
  );
};

const renderClientInformations = ({ module }) => {
  const allowedModules = [
    'allotment',
    'registration',
    'travel',
    'transfer',
    'additionalServices',
  ];

  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

const renderRegistrationInformations = ({ module }) => {
  const allowedModules = ['registration'];

  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

const renderAdditionalServices = ({ module }) => {
  const allowedModules = ['additionalServices'];
  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

const renderBillingInformations = ({ module }) => {
  const allowedModules = ['registration', 'additionalServices'];

  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

const renderAllotmentInformations = ({ module }) => {
  const allowedModules = ['allotment'];

  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

const renderTravelInformations = ({ module }) => {
  const allowedModules = ['travel'];

  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

const renderTransferInformations = ({ module }) => {
  const allowedModules = ['transfer'];

  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

const renderPaymentDetails = ({ module }) => {
  const allowedModules = ['registration', 'additionalServices'];

  return allowedModules.indexOf(module) > -1 || allowedModules[0] === '*'
    ? true
    : false;
};

// Create Document Component
const PDFRenderer = ({
  clientInformations,
  billingInformations,
  paymentDetails,
  registrationInformations,
  additionalServicesInformations,
  allotmentInformations,
  travelInformations,
  transferInformations,
  module,
  i18n,
  intl,
  showPrice,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            ...styles.header,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          // fixed
        >
          <Image style={styles.image} src={Logo} />
        </View>
        {renderClientInformations({ module }) ? (
          <ClientInformations {...clientInformations} i18n={i18n} />
        ) : null}
        <Spacer size={20} />
        {renderRegistrationInformations({ module }) &&
        registrationInformations ? (
          <RegistrationInformations {...registrationInformations} i18n={i18n} />
        ) : null}
        {renderAdditionalServices({ module }) &&
        additionalServicesInformations ? (
          <AdditionalServicesInformations
            {...additionalServicesInformations}
            i18n={i18n}
            showPrice={showPrice}
          />
        ) : null}
        <Spacer size={20} />
        {billingInformations && renderBillingInformations({ module }) ? (
          <BillingInformations
            {...{
              ...billingInformations,
              i18n,
            }}
          />
        ) : null}
        <Spacer size={20} />
        {renderAllotmentInformations({ module }) ? (
          <AllotmentSection
            {...{ data: allotmentInformations, i18n, intl, showPrice }}
          />
        ) : null}
        {renderTravelInformations({ module }) ? (
          <TravelSection {...{ data: travelInformations, i18n, intl }} />
        ) : null}
        {renderTransferInformations({ module }) ? (
          <TransferSection {...{ data: transferInformations, i18n }} />
        ) : null}
        <Spacer size={20} />
        {paymentDetails && renderPaymentDetails({ module }) ? (
          <PaymentDetails
            {...{
              i18n,
              intl,
              ...paymentDetails,
              showPrice,
            }}
          />
        ) : null}

        <Footer />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
  },
  label: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 10,
    fontWeight: 400,
  },
  image: {
    width: 250,
    // height: 100,
    textAlign: 'center',
  },
  header: {
    marginBottom: 15,
    textAlign: 'center',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: 'center',
    color: 'black',
    borderTop: '0.5px solid black',
    paddingTop: 10,
    height: 30,
  },
});

export default PDFRenderer;
