import React, { Fragment, useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { AimTypography } from '../atoms';
import { AimFileList } from '../bundles/AimFileList';
import { styled } from '../styled';
import { theme } from '../../theme';
import { aws, fileHelper } from '@aim/common';

import { constants } from '@aim/common';

import AbstractInfo from './AbstractDetailPage/AbstractInfo';
import AuthorItem from './AbstractDetailPage/AuthorItem';
import translation from './AbstractDetailPage/translation';
import {
  AimIconButton,
  AimTextField,
  AimIconAndTextButton,
  AimCheckbox,
} from '@aim/components';

import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';

import Grid from '@material-ui/core/Grid';
import { AimMegadraft } from '../atoms/AimMegadraft/AimMegadraft';

const { downloadFromS3 } = fileHelper;

const AbstractSectionsContainer = styled('div')(({ variant }) => ({
  backgroundColor:
    variant && variant === 'white'
      ? theme.colors.primary.white
      : theme.colors.greyScale.backgroundGrey,
  marginTop: 6,
  paddingTop: 10,
}));

const downloadFile = async ({ id, extension, s3Folder }) => {
  const link = `${s3Folder}${id}${extension}`;

  var res = await aws.Storage.get(link);
  window.open(res);
};

const formControlStyle = { width: 'calc(100% - 10px)' };

const sortAbstractSections = (sections) => {
  return sections
    ? sections.sort((a, b) => {
        if (
          a.fieldDefinition?.contextsOfUse?.items?.[0]?.position <
          b.fieldDefinition?.contextsOfUse?.items?.[0]?.position
        )
          return -1;
        if (
          b?.fieldDefinition?.contextsOfUse?.[0]?.position >
          a?.fieldDefinition?.contextsOfUse?.[0]?.position
        )
          return 1;
        return 0;
      })
    : [];
};

const sortAbstractFieldsDefinition = (item) => {
  return item
    ? item.sort((a, b) => {
        if (
          a.fieldDefinition?.contextsOfUse?.items?.[0]?.position <
          b.fieldDefinition?.contextsOfUse?.items?.[0]?.position
        )
          return -1;
        if (
          b?.fieldDefinition?.contextsOfUse?.[0]?.position >
          a?.fieldDefinition?.contextsOfUse?.[0]?.position
        )
          return 1;
        return 0;
      })
    : [];
};

const UploadedFiles = ({ data, i18n, s3Folder }) => {
  if (data.length > 0) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <AimTypography
            variant={`textBold`}
          >{`Uploaded files translation`}</AimTypography>
        </Grid>
        {data.map((file, ndx) => {
          return (
            <Grid
              key={ndx}
              item
              container
              xs={12}
              style={{
                backgroundColor: '#F8F6FA',
                marginTop: 2.5,
                marginBottom: 2.5,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Grid
                item
                xs={10}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <CheckIcon style={{ color: '#29E129' }} />
                <AimTypography variant={`text`} boxStyle={{ marginLeft: 10 }}>
                  {file.originalName}
                </AimTypography>
              </Grid>

              <Grid
                item
                xs={2}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <AimTypography variant={`text`}>
                  {(file.size / 1024).toFixed(2)}
                  {` kB`}
                </AimTypography>
                <AimIconButton
                  variant="lightBlueFill"
                  onClick={() => {
                    downloadFile({
                      s3Folder,
                      id: file.id,
                      extension: file.extension,
                    });
                  }}
                >
                  <GetAppIcon />
                </AimIconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return <></>;
};

const ReviewHeader = ({ i18n }) => (
  <Grid item xs={12} container spacing={2}>
    <Grid item xs={6}>
      <AimTypography variant="textBold">{`Criterio`}</AimTypography>
    </Grid>
    <Grid item xs={6}>
      <AimTypography variant="textBold">{`Punteggio`}</AimTypography>
    </Grid>
    {/* <Grid item xs={4}>
      <AimTypography variant="textBold">{`Note`}</AimTypography>
    </Grid> */}
  </Grid>
);

const ReviewItem = ({
  questionAndResponse,
  renderScore,
  // , renderNotes
}) => (
  <Grid item container xs={12} style={{ pageBreakBefore: 'avoid' }}>
    <Grid item xs={12}>
      {/* <pre>{JSON.stringify(questionAndResponse, null, '\t')}</pre> */}
    </Grid>
    <Grid item xs={6}>
      <AimTextField
        type="text"
        formControlStyle={formControlStyle}
        // label={i18n.page.form.deadline.label}
        placeholder="placeholder"
        value={questionAndResponse.title}
        disabled
        inputPropsStyle={{ color: 'black' }}
      />
    </Grid>
    <Grid item xs={6}>
      <AimTextField
        type="text"
        formControlStyle={formControlStyle}
        placeholder="placeholder"
        value={renderScore({
          type: questionAndResponse.type,
          response: questionAndResponse.response,
        })}
        disabled
        inputPropsStyle={{ color: 'black' }}
      />
    </Grid>
    {/* <Grid item xs={4}>
      <AimTextField
        type="text"
        formControlStyle={formControlStyle}
        placeholder="placeholder"
        value={renderNotes()} //todo
        disabled
        inputPropsStyle={{ color: 'black' }}
      />
    </Grid> */}
  </Grid>
);

const Reviews = ({
  // questionTypes,
  reviews,
  criteriaModel,
  i18n,
  renderScore,
  renderNotes,
}) => {
  const questions = [];

  if (criteriaModel) {
    const _criteriaModel = JSON.parse(criteriaModel);
    _criteriaModel.pages?.forEach((page) => {
      page.elements?.forEach((element) => {
        questions.push(element);
      });
    });
  }

  const mappedReviews = [];

  reviews &&
    reviews.forEach((review) => {
      let questionsAndResponses = [];
      const parsedResponses = JSON.parse(review.reviewResponse);

      for (const questionKey in parsedResponses) {
        if (!review.isViewNoteFrontend && questionKey === 'note') continue;
        const ndx = questions.findIndex(
          (question) => question.name === questionKey
        );
        if (ndx > -1) {
          const mappedQuestionAndResponse = {
            ...questions[ndx],
            order: parseInt(questionKey.replace(/^\D+/g, '')),
          };
          mappedQuestionAndResponse['response'] = parsedResponses[questionKey];
          delete mappedQuestionAndResponse['name'];
          questionsAndResponses.push(mappedQuestionAndResponse);
        }
      }

      const matrixSingleChoice = [];

      questionsAndResponses.forEach((el) => {
        if (el.type === 'matrix') {
          el.rows.forEach((row, rowNdx) => {
            let resp = parseInt(el.response[rowNdx]);
            resp = !isNaN(resp) ? el.columns[resp].value : ' ';
            matrixSingleChoice.push({
              type: 'text',
              title: row.text,
              response: resp,
              order: el.order,
            });
          });
        }
      });

      let questionsAndResponsesFiltered = [...questionsAndResponses].filter(
        (x) => !x.type.includes('matrix')
      );

      questionsAndResponses = [
        ...questionsAndResponsesFiltered,
        ...matrixSingleChoice,
      ];

      questionsAndResponses.length > 0 &&
        mappedReviews.push({
          ...review,
          questionsAndResponses: questionsAndResponses.sort((a, b) =>
            a.order > b.order ? 1 : -1
          ),
        });
    });

  return (
    <>
      {mappedReviews &&
        mappedReviews
          // .concat(mappedReviews, mappedReviews)
          .map((review, ndx) => {
            return (
              <Grid
                container
                key={ndx}
                style={{
                  backgroundColor: `white`,
                  padding: 10,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Grid item xs={12}>
                  <AimTypography margin={0} variant={`h2`}>
                    {`${review.reviewer?.participant?.givenName} ${review.reviewer?.participant?.familyName}`}
                  </AimTypography>
                </Grid>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <ReviewHeader {...{ i18n }} />
                  {review.questionsAndResponses.map(
                    (questionAndResponse, ndx) => {
                      return (
                        <ReviewItem
                          key={ndx}
                          {...{
                            questionAndResponse,
                            renderScore,
                            renderNotes,
                          }}
                        />
                      );
                    }
                  )}
                </Grid>
              </Grid>
            );
          })}
    </>
  );
};

const renderScore = ({ type, response }) => {
  let score = null;

  switch (type) {
    case 'boolean':
    case 'radiogroup':
    case 'text':
    case 'rating':
    case 'dropdown':
      score = response;
      break;

    case 'checkbox':
      score = response.join(', ');
      break;

    default:
      score = ' '; // unknown case... returning an empty string not to show _placeholder_
  }

  return score;
};

export const AbstractDetailPage = ({
  intl,
  abstractDocument,
  maxHeight,
  onDownloadAttachments,
  hideAbstractInfo = false,
  abstractFiles = [],
  eventId,
  abstractDocumentId,
  reviews = [],
  hideReviewsFromPage = false,
  hideFilesFromPage = false,
  isReviewable = false,
  review,
  setDialog,
  printWithoutReviewsFrontOffice = false,
  preTitles,
  variant,
}) => {
  const i18n = translation.abstractDetail(intl);
  const pageRef = useRef(null);
  const sortedAbstractSections = sortAbstractSections(
    abstractDocument?.abstractSections?.items || []
  );
  const [
    skipReviewForConflictLabel,
    setSkipReviewForConflictLabel,
  ] = useState();

  useEffect(() => {
    if (abstractDocument) {
      setSkipReviewForConflictLabel(
        abstractDocument.abstractService?.skipReviewForConflictLabel
      );
    }
  }, [abstractDocument]);

  if (abstractDocument) {
    sortAbstractFieldsDefinition(
      abstractDocument?.presenter?.fieldValues?.items || []
    );
    /* abstractDocument?.authors?.items.map((author) =>
      sortAbstractFieldsDefinition(author.fieldValues?.items || [])
    ); */
  }

  const [hideReviews, setHideReviews] = useState(null);

  const toggleNotToEvaluateAbstract = () => {
    setDialog({
      isOpen: true,
      title: i18n.page.review.declineDialog.title,
      message: i18n.page.review.declineDialog.text,
    });
  };

  const print = useReactToPrint({
    content: () => pageRef.current,
    pageStyle:
      '@page{size:auto;margin:0}@media print{body{-webkit-print-color-adjust:exact;padding:10px}.hideFromPrint{display:none}}',
  });

  useEffect(() => {
    if (hideReviews !== null) {
      print();
    }
  }, [hideReviews]);

  // Functions
  const extractAuthorFields = ({ affiliation = null, ...author }) => {
    console.log('author', author);
    const nextAffiliation = affiliation
      ? {
          name: 'affiliation',
          value: affiliation.institution ?? '',
        }
      : null;
    return [
      ...Object.keys(author).map((key) => {
        return {
          name: key ?? '',
          value: author[key] ?? '',
        };
      }),
      ...(nextAffiliation ? [nextAffiliation] : []),
    ];
  };

  const s3Folder =
    abstractDocumentId && eventId
      ? `events/${eventId}/abstracts/${abstractDocumentId}/`
      : null;

  // Render
  return (
    <>
      <div style={{ maxHeight: maxHeight || 100 }} ref={pageRef}>
        <AimTypography isUpperCase variant="text">
          {i18n.page.title}
        </AimTypography>
        {!hideAbstractInfo && (
          <AbstractInfo data={abstractDocument} i18n={i18n} variant={variant} />
        )}
        {abstractDocument?.authors?.length && !hideAbstractInfo ? (
          <>
            <AimTypography variant="text" isUpperCase>
              {i18n.page.authors.title}
            </AimTypography>
            {abstractDocument.authors.map((author) => {
              return (
                <AuthorItem
                  key={author.id}
                  count={Object.keys(author).length || 0}
                  fields={extractAuthorFields(author)}
                  preTitles={preTitles}
                  i18n={i18n}
                  variant={variant}
                />
              );
            })}
          </>
        ) : null}
        <AimTypography variant="text" isUpperCase>
          {i18n.page.abstract}
        </AimTypography>
        <AbstractSectionsContainer variant={variant}>
          <AimTypography variant="textBold">
            {i18n.page.abstractSections.title}
          </AimTypography>
          <AimTypography variant="h2">{abstractDocument?.title}</AimTypography>
          {sortedAbstractSections?.length
            ? sortedAbstractSections.map((s) => {
                return (
                  <>
                    <AimTypography variant="textBold">
                      {s.fieldDefinition.label}
                    </AimTypography>
                    <AimMegadraft
                      variant={review ? 'transparent' : 'white'}
                      key={s.id}
                      readOnly
                      value={s?.value ? JSON.parse(s?.value) : undefined}
                      marginLeft={50}
                    />
                  </>
                );
              })
            : null}
          {abstractFiles.length > 0 && (
            <div>
              <AimTypography variant="h2">
                {i18n.page.files.title}
              </AimTypography>
              {abstractFiles.map((file, idx) => (
                <Grid
                  key={idx}
                  item
                  xs={12}
                  style={{
                    padding: 5,
                    backgroundColor: '#F8F6FA',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      padding: 5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckIcon style={{ color: '#29E129', marginRight: 10 }} />
                    <AimTypography
                      ellipsis
                      style={{
                        flex: 1,
                      }}
                      variant={'h4regular'}
                    >
                      {file?.originalName}
                    </AimTypography>
                    <AimIconButton
                      variant={'lightBlueFill'}
                      small="true"
                      onClick={() =>
                        downloadFile({
                          s3Folder,
                          id: file.id,
                          extension: file.extension,
                        })
                      }
                    >
                      <GetAppIcon />
                    </AimIconButton>
                  </div>
                </Grid>
              ))}
            </div>
          )}
          {!hideFilesFromPage && (
            <div
              {...{ className: `hideFromPrint` }}
              style={{ pageBreakBefore: 'always' }}
            >
              <UploadedFiles {...{ data: abstractFiles, i18n, s3Folder }} />
            </div>
          )}

          <div
            {...(hideReviews && { className: `hideFromPrint` })}
            style={{ pageBreakBefore: 'always' }}
          >
            {!hideReviewsFromPage && (
              <Reviews
                {...{
                  // questionTypes: reviews.questionTypes,
                  reviews: reviews.reviews || [],
                  criteriaModel: reviews.reviewCriteriaModel || null,
                  renderScore,
                  // renderNotes,
                }}
              />
            )}
          </div>
          {abstractDocument?.attachments?.items?.length ? (
            <AimFileList
              files={abstractDocument?.attachments?.items}
              onDownload={onDownloadAttachments}
            />
          ) : null}
          <div style={{ height: 30 }} />
        </AbstractSectionsContainer>
      </div>
      {isReviewable &&
        review &&
        review.status !== constants.reviewStatus.PROCESSED && (
          <Grid
            xs={12}
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              backgroundColor: 'rgb(236, 234, 241) ',
            }}
          >
            <AimCheckbox
              variant="gray"
              onClick={() => {
                toggleNotToEvaluateAbstract();
              }}
              checked={
                review.status === constants.reviewStatus.REJECTED ? true : false
              }
              disabled={
                review.status === constants.reviewStatus.REJECTED ? true : false
              }
            />
            <AimTypography
              variant={`h5`}
              margin={0}
              boxStyle={{ paddingLeft: 10 }}
            >
              {skipReviewForConflictLabel ||
                i18n.page.reviewable.notEvaluateForConflictOfInterest}
            </AimTypography>
          </Grid>
        )}

      {!hideReviewsFromPage && (
        <Grid
          xs={12}
          container
          style={{
            justifyContent: 'flex-end',
            flex: 1,
            display: 'flex',
            paddingBottom: 30,
          }}
        >
          <AimIconAndTextButton
            text={`Export with reviews`}
            variant="blueFill"
            onClick={() => setHideReviews(false)}
          />
          <AimIconAndTextButton
            text={`Export without reviews`}
            variant="violetFill"
            onClick={() => setHideReviews(true)}
          />
        </Grid>
      )}
      {printWithoutReviewsFrontOffice && (
        <Grid
          xs={12}
          item
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <AimIconAndTextButton
            text={`Print`}
            variant="violetFill"
            onClick={() => setHideReviews(true)}
          />
        </Grid>
      )}
    </>
  );
};
