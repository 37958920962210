import { constants, googleHelper, geoDbHelper } from '@aim/common';

const {
  getGoogleMapsCountriesByInput,
  getGoogleMapsPlaceByPlaceId,
} = googleHelper;

const { getGeoDbCountriesByInput, getGeoDbCitiesByInput } = geoDbHelper;

export const getFieldValueByFieldDefinitionKey = (
  participation,
  key,
  defaultValue
) =>
  JSON.parse(
    participation?.fieldValues?.items?.find?.(
      (i) => i?.fieldDefinition?.key === key
    )?.value ?? JSON.stringify(defaultValue || '')
  );

export const extractBillingInformationFromFieldValues = async (
  participation,
  joinNameAndSurname = true,
  invoiceTo = constants.InvoiceToType.INDIVIDUAL.id
) => {
  let country = '';
  let countryIsoCode = '';
  let city = '';

  const countryField = getFieldValueByFieldDefinitionKey(
    participation,
    'country'
  );
  const cityField = getFieldValueByFieldDefinitionKey(
    participation,
    'city'
  );

  try {
    const countriesRes = await getGeoDbCountriesByInput(countryField.value);
    country = countriesRes.find((country) => country.id === countryField.id)?.label || '';

    const cityRes = await getGeoDbCitiesByInput(cityField.value);
    city = cityRes.find((city) => city.id === cityField.id)?.label || '';
  } catch (e) {
    console.error('extractBillingInformationFromFieldValues', e);
  }

  if (invoiceTo === constants.InvoiceToType.COMPANY.id) {
    return {
      invoiceTo: constants.InvoiceToType.COMPANY.id,
      name: getFieldValueByFieldDefinitionKey(participation, 'authority'),
      city: getFieldValueByFieldDefinitionKey(participation, 'authority_city'),
      address: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_address'
      ),
      postalCode: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_post_code'
      ),
      province: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_province'
      ),
      region: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_region'
      ),
      country: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_country'
      ),
      phone: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_phone'
      ),
      countryIsoCode,
      vatCode: getFieldValueByFieldDefinitionKey(participation, 'vat_code'),
      taxCode: getFieldValueByFieldDefinitionKey(participation, 'fiscal_code'),
      reference: '',
      email: participation?.email || '',
      pec: '',
      invoiceToPublicAuthority: false,
      uniqueCode: '',
      ipaCode: '',
      isSplitPayment: false,
      isVatEvent: false,
    };
  } else {
    return {
      invoiceTo: constants.InvoiceToType.INDIVIDUAL.id,
      name: `${participation?.givenName || ''} ${joinNameAndSurname ? participation?.familyName || '' : ''
        }`.trim(),
      ...(joinNameAndSurname
        ? {}
        : { surname: `${participation?.familyName || ''}`.trim() }),
      address: getFieldValueByFieldDefinitionKey(participation, 'address'),
      city: getFieldValueByFieldDefinitionKey(participation, 'city'),
      postalCode: getFieldValueByFieldDefinitionKey(participation, 'zip_code'),
      region: getFieldValueByFieldDefinitionKey(participation, 'region'),
      province: getFieldValueByFieldDefinitionKey(participation, 'province'),
      country,
      city,
      countryIsoCode,
      vatCode: getFieldValueByFieldDefinitionKey(participation, 'vat_code'),
      taxCode: getFieldValueByFieldDefinitionKey(participation, 'fiscal_code'),
      reference: '',
      email: participation?.email || '',
      pec: '',
      phone: participation?.phone || '',
      invoiceToPublicAuthority: false,
      uniqueCode: '',
      ipaCode: '',
      isSplitPayment: false,
      isVatEvent: false,
    };
  }
};
