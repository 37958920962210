import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventGatewaysQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceType: String) {
    getEvent(id: $id) {
      id
      gateways(filter: { service: { eq: $serviceType } }) {
        items {
          id
          service
          shop {
            id
            gatewayType
            iban
            ibanCausal
            paymentType
          }
        }
      }
    }
  }
`;

const createBillingInformationMutation = /* GraphQL */ `
  mutation CreateBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBillingInformationMutation = /* GraphQL */ `
  mutation UpdateBillingInformation(
    $input: UpdateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    updateBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSponsorMutation = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateAgencyMutation = /* GraphQL */ `
  mutation UpdateAgency(
    $input: UpdateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    updateAgency(input: $input, condition: $condition) {
      id
    }
  }
`;
const getParticipationBillingInformationQuery = /* GraphQL */ `
  query GetParticipationBillingInformation($id: ID!) {
    getParticipation(id: $id) {
      id
      givenName
      familyName
      email
      phone
      fieldValues {
        items {
          id
          value
          fieldDefinition {
            id
            key
          }
        }
      }
      event {
        id
      }
      billingInformation {
        id
        invoiceTo
        name
        surname
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
        purchaseOrderNumber
        venue
        po {
          id
          isMandatory
          originalName
          size
          extension
        }
        cig
        cup
        tripletta
      }
    }
  }
`;
const getSponsorBillingInformationQuery = /* GraphQL */ `
  query GetSponsorBillingInformation($id: ID!) {
    getSponsor(id: $id) {
      country
      city
      event {
        id
      }
      billingInformation {
        id
        invoiceTo
        name
        surname
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
        purchaseOrderNumber
        venue
        po {
          id
          isMandatory
          originalName
          size
          extension
        }
        cig
        cup
        tripletta
      }
    }
  }
`;

const getAdditionalServicesQuery = /* GraphQL */ `
  query getAdditionalServices($id: ID!) {
    getEvent(id: $id) {
      id
      additionalServices(filter: { priceIncluded: { eq: true } }) {
        items {
          id
          typology
          title
        }
      }
    }
  }
`;

const getProductQuery = /* GraphQL */ `
  query getProductBillingInformationQuery($id: ID!) {
    getProduct(id: $id) {
      clientId
      serviceId
      payment {
        id
        BillingInformation {
          id
          invoiceTo
          name
          surname
          address
          city
          postalCode
          region
          province
          country
          countryIsoCode
          vatCode
          taxCode
          reference
          email
          pec
          phone
          invoiceToPublicAuthority
          uniqueCode
          ipaCode
          isSplitPayment
          isVatEvent
          purchaseOrderNumber
          venue
          po {
            id
            isMandatory
            originalName
            size
            extension
          }
          cig
          cup
          tripletta
        }
      }
    }
  }
`;

const getAgencyBillingInformationQuery = /* GraphQL */ `
  query getAgency($id: ID!) {
    getAgency(id: $id) {
      event {
        id
      }
      country
      city
      billingInformation {
        id
        invoiceTo
        name
        surname
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
        purchaseOrderNumber
        venue
        po {
          id
          isMandatory
          originalName
          size
          extension
        }
        cig
        cup
        tripletta
      }
    }
  }
`;

const getPurchaseQuery = /* GraphQL */ `
  query getPurchaseBillingInformationQuery($id: ID!) {
    getPurchase(id: $id) {
      eventId
      purchaseId
      payment {
        id
        BillingInformation {
          id
          invoiceTo
          name
          surname
          address
          city
          postalCode
          region
          province
          country
          countryIsoCode
          vatCode
          taxCode
          reference
          email
          pec
          phone
          invoiceToPublicAuthority
          uniqueCode
          ipaCode
          isSplitPayment
          isVatEvent
          purchaseOrderNumber
          venue
          po {
            id
            isMandatory
            originalName
            size
            extension
          }
          cig
          cup
          tripletta
        }
      }
    }
  }
`;

const updatePaymentMutation = /* GraphQL */ `
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
    }
  }
`;

const getDefaultVatRateQuery = /* GraphQL */ `
  query GetDefaultVat {
    vatByIsDefault(isDefault: "true", filter: { isDisabled: { eq: false } }) {
      items {
        id
        amount
      }
    }
  }
`;

export const getEventGateway = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.aws.API.graphql({
      query: getEventGatewaysQuery,
      variables: { ...input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.gateways.items[0]);
      })
      .catch((e) => {
        console.error('event-gateways', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getBillingInformationsPax = (participationId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getParticipationBillingInformationQuery,
      variables: { id: participationId },
    })
      .then((response) => resolve(response.data.getParticipation))
      .catch((e) => {
        console.error('get-participation-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getBillingInformationsSponsor = (sponsorId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getSponsorBillingInformationQuery,
      variables: { id: sponsorId },
    })
      .then((response) => {
        resolve(response.data.getSponsor);
      })
      .catch((e) => {
        console.error('get-sposor-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getBillingInformationsAgency = (agencyId, useLoader = true) =>
  new Promise((resolve, reject) => {
    console.log('agencyId', agencyId);
    useLoader && showLoader();
    aws.API.graphql({
      query: getAgencyBillingInformationQuery,
      variables: { id: agencyId },
    })
      .then((response) => {
        resolve(response.data.getAgency);
      })
      .catch((e) => {
        console.error('get-agency-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: createBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createBillingInformation.id))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateBillingInformation.id))
      .catch((e) => {
        console.error('update-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-participation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateAgency = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateAgencyMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.UpdateAgency))
      .catch((e) => {
        console.error('update-agency', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
export const updateSponsor = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateSponsorMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateSponsor))
      .catch((e) => {
        console.error('update-participation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getAdditionalServices = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getAdditionalServicesQuery,
      variables: { id: eventId },
    })
      .then((response) => resolve(response.data.getEvent.additionalServices))
      .catch((e) => {
        console.error('get-additional-services', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getProduct = (productId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getProductQuery,
      variables: { id: productId },
    })
      .then((response) => resolve(response.data.getProduct))
      .catch((e) => {
        console.error('get-product', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getPurchase = (productId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getPurchaseQuery,
      variables: { id: productId },
    })
      .then((response) => resolve(response.data.getPurchase))
      .catch((e) => {
        console.error('get-product', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updatePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: updatePaymentMutation,
      variables: { input },
    })
      .then(resolve)
      .catch((e) => {
        console.error('update-payment', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const getDefaultVat = (useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getDefaultVatRateQuery,
    })
      .then((response) => resolve(response.data.vatByIsDefault?.items?.[0]))
      .catch((e) => {
        console.error('get-default-vat', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
