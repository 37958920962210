import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DragHandleIcon from '@material-ui/icons/DragHandle';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { styled } from './../../styled';

const StyledDragListIcon = styled(ListItemIcon)({
  position: 'absolute',
  right: 10,
  top: '50%',
  msTransform: 'translateY(-50%)',
  transform: 'translateY(-50%)',
});

const StyledItemWrapperComponent = styled('div')(({ rowWidth }) => ({
  width: rowWidth,
}));

const StyledList = styled(List)({
  width: '100%',
  padding: 0,
});

const DraggableListItem = ({
  item,
  items,
  setItems,
  index,
  draggableItemStyle,
  hideDragIcon,
  ItemComponent,
  itemComponentProps,
  rowWidth = '90%',
  customDraggableIdKey,
}) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging && draggableItemStyle ? draggableItemStyle : {}),
  });
  // console.log('draggable-id', `item-${index}-${nanoid()}`);
  return (
    <Draggable
      draggableId={`item-${item[customDraggableIdKey] || index}}`}
      index={index}
    >
      {(provided, snapshot) => {
        console.log('draggle props', provided.draggableProps);
        return (
          <ListItem
            key={item.id}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            ref={provided.innerRef}
          >
            <StyledItemWrapperComponent
              {...provided.dragHandleProps}
              rowWidth={rowWidth}
            >
              <ItemComponent
                item={item}
                items={items}
                setItems={setItems}
                index={index}
                {...itemComponentProps}
              />
              {!hideDragIcon && (
                <StyledDragListIcon>
                  <DragHandleIcon />
                </StyledDragListIcon>
              )}
            </StyledItemWrapperComponent>
          </ListItem>
        );
      }}
    </Draggable>
  );
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const AimDraggableList = ({
  items,
  setItems,
  onDragEnd,
  draggableItemStyle,
  hideDragIcon,
  ItemComponent,
  itemComponentProps,
  rowWidth,
  keyName,
  HeaderGrid,
  customDraggableIdKey,
}) => {
  const onDragEndDefault = (result) => {
    console.log('🚀 ~ onDragEndDefault ~ result:', result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    console.log('items on drag end', items);
    const nextItems = reorder(
      [...items],
      result.source.index,
      result.destination.index
    );
    console.log('🚀 ~ onDragEndDefault ~ nextItems:', nextItems);

    setItems(nextItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd || onDragEndDefault}>
      {HeaderGrid}
      <StyledList disablePadding>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <DraggableListItem
                  item={item}
                  items={items}
                  setItems={setItems}
                  index={index}
                  draggableItemStyle={draggableItemStyle}
                  hideDragIcon={hideDragIcon}
                  ItemComponent={ItemComponent}
                  itemComponentProps={itemComponentProps}
                  key={keyName ? item[keyName] : index}
                  style={{ width: '100%' }}
                  rowWidth={rowWidth}
                  customDraggableIdKey={customDraggableIdKey}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </StyledList>
    </DragDropContext>
  );
};
