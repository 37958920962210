import React from 'react';

import { AimTypography } from '../../atoms';

import { styled } from '../../styled';
import { theme } from '../../../theme';
import { AimCheckbox } from '@aim/components';

const ItemContainer = styled('div')(({ variant }) => ({
  display: 'flex',
  background:
    variant && variant === 'white'
      ? theme.colors.primary.white
      : theme.colors.greyScale.backgroundGrey,
  marginTop: 10,
}));

const FieldValueContainer = styled('div')(({ count }) => ({
  padding: 5,
  flex: 1,
  minWidth: `calc(100% / ${count})`,
  maxWidth: `calc(100% / ${count})`,
}));

const fieldsOrder = [
  'preTitle',
  'name',
  'surname',
  'city',
  'country',
  'speciality',
  'degree',
  'jobTitle',
  'affiliation',
  'isPresentingAuthor',
];

const AuthorItem = ({ i18n, fields, count, preTitles, variant }) => {
  const sortedFields = fieldsOrder.map((key) => {
    return fields.find((obj) => obj.name === key) || { name: key, value: '-' };
  });
  console.log('sortedFields', sortedFields);

  // Get title value from selected title
  let titleField = sortedFields.find((field) => field.name === 'preTitle');
  const preTitle = preTitles.find((title) => title.id === titleField.value);
  titleField.value = preTitle?.title;

  return (
    <ItemContainer variant={variant}>
      {sortedFields.map(({ name, value }, idx) =>
        name !== 'isPresentingAuthor' ? (
          <FieldValueContainer key={idx} count={count}>
            <AimTypography variant="textBold">
              {i18n.labels[name]}
            </AimTypography>
            <AimTypography variant="text">
              {typeof value === 'object' ? value?.label || '-' : value || '-'}
            </AimTypography>
          </FieldValueContainer>
        ) : (
          <AimCheckbox
            checked={value}
            label={i18n.labels[name]}
            labelPlacement="top"
          />
        )
      )}
    </ItemContainer>
  );
};

export default AuthorItem;
