import React, { useEffect, useState } from 'react';
import {
  Packer,
  Document,
  TextRun,
  Paragraph,
  UnderlineType,
  HeadingLevel,
  PageBreak,
  ImageRun,
  AlignmentType,
} from 'docx';
import { AimIconAndTextButton } from '@aim/components';
import GetAppIcon from '@material-ui/icons/GetApp';
import translation from './AbstractDetailWord/translation';
import { fileHelper } from '@aim/common';
import { saveAs } from 'file-saver';

const pxToPt = (px) => {
  const conversionRate = 0.75;
  if (typeof px === 'string') {
    const number = parseInt(px.slice(0, -2), 10);
    const pt = number * conversionRate;
    return pt;
  }
  if (typeof px === 'number') {
    const pt = px * conversionRate;
    return pt;
  }
};

/* const generateTextRunStyle = (element) => {
  if (element) {
    return {
      size: element.fontSize ? `${pxToPt(element.fontSize)}pt` : '14pt',
      bold: element.isBold,
      underline: element.isUnderlined
        ? {
            type: UnderlineType.SINGLE,
          }
        : false,
    };
  }
  return {};
}; */

const prepareSection = ({
  documentTemplate,
  modelItem,
  intl,
  withImages,
  downloadedFiles,
}) => {
  const i18n = translation.abstractDetail(intl);

  const itemsToRender = documentTemplate.flatMap(
    ({ iterateOnProperty, type }) => {
      if (type) {
        switch (type) {
          case 'title': {
            return new Paragraph({
              text: modelItem.title,
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 300,
              },
            });
          }
          case 'info': {
            const info = {
              category: {
                value: modelItem.category?.name,
                label: i18n.abstractInfo.category,
              },
              subCategory: {
                value: modelItem.subCategory?.name,
                label: i18n.abstractInfo.subCategory,
              },
              tags: {
                value: modelItem.tags?.join(','),
                label: i18n.abstractInfo.tags,
              },
              presentation: {
                value: modelItem.proposedPresentationTypology?.name,
                label: i18n.abstractInfo.presentation,
              },
              keywords: {
                value: modelItem.keywords,
                label: i18n.abstractInfo.keywords,
              },
              status: {
                value: modelItem.status,
                label: i18n.abstractInfo.status,
              },
            };

            const infoItems = [];
            Object.values(info).map((value) => {
              infoItems.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: value && `${value.label}: `,
                      bold: true,
                      style: { marginRight: 20 },
                    }),
                    new TextRun({
                      text: value ? value.value : '-',
                    }),
                  ],
                  spacing: {
                    after: 100,
                  },
                })
              );
            });
            return infoItems;
          }
          default:
            break;
        }
      } else if (iterateOnProperty) {
        // Prendo i dati di ogni sezione (es: abstractSections)
        const sectionData = modelItem[iterateOnProperty].items;

        if (sectionData.length === 0) return [];
        switch (iterateOnProperty) {
          case 'authors': {
            const authorsTitle = new Paragraph({
              text: 'Authors',
              spacing: {
                before: 200,
              },
              heading: HeadingLevel.HEADING_1,
            });

            const authorsArray = [];
            sectionData.map((author, index) => {
              const authorName = `${author.name.charAt(0).toUpperCase()}. ${
                author.surname
              }`;
              const isPresenter = author.isPresentingAuthor;
              isPresenter
                ? authorsArray.push(
                    new TextRun({
                      text: authorName,
                      underline: {
                        type: UnderlineType.SINGLE,
                        color: '000000',
                      },
                    })
                  )
                : authorsArray.push(new TextRun({ text: authorName }));

              const next = sectionData[index + 1];
              if (next) authorsArray.push(new TextRun({ text: ', ' }));
            });
            return [
              authorsTitle,
              new Paragraph({
                children: authorsArray,
              }),
            ];
          }
          case 'abstractSections': {
            // Per ogni sezione (es: abstractSection)
            const sectionDataItems = sectionData
              .map((section) => {
                const itemsArray = [];

                const sectionTitle = section.fieldDefinition.label;
                const sectionBlocks = JSON.parse(section.value).blocks;

                itemsArray.push(
                  new Paragraph({
                    children: [
                      new TextRun({ text: sectionTitle, bold: true, break: 2 }),
                    ],
                  })
                );

                const temp = sectionBlocks
                  .map((block) => {
                    if (block.type === 'atomic') {
                      if (withImages) {
                        //to prevent that the file il more large than the word file
                        const originalImageWidth =
                          downloadedFiles?.[block?.data?.src]?.width;
                        let imageWidth = originalImageWidth;
                        let imageHeight =
                          downloadedFiles?.[block?.data?.src]?.height;
                        if (imageWidth > 600) {
                          imageHeight = (600 / imageWidth) * imageHeight;
                          imageWidth = 600;
                        }

                        const matchExtension = block?.data?.src.match(
                          /\.[0-9a-z]+$/i
                        );
                        const extension = matchExtension
                          ? matchExtension[0].substring(1)
                          : '';

                        return new Paragraph({
                          children: [
                            new ImageRun({
                              type: extension,
                              data:
                                downloadedFiles?.[block?.data?.src]?.file?.Body,
                              transformation: {
                                width: imageWidth, //downloadedFiles?.[block?.data?.src]?.width,
                                height: imageHeight, //downloadedFiles?.[block?.data?.src]?.height,
                              },
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        });
                      } else {
                        return new Paragraph({
                          children: [
                            new TextRun({
                              text: `<FILE IMAGE=${modelItem.code}_${block.data.name}>`,
                              break: 2,
                            }),
                          ],
                        });
                      }
                    } else {
                      if (block.text) {
                        return new Paragraph({
                          children: [
                            new TextRun({ text: block.text, break: 2 }),
                          ],
                        });
                      }
                    }
                  })
                  .filter((s) => s !== undefined);
                const total = itemsArray.concat(temp);
                return total;
              })
              .flat();
            return sectionDataItems;
          }
        }
      }
    }
  );
  // Add PageBreak at the end of each abstract document
  itemsToRender.push(
    new Paragraph({
      children: [new PageBreak()],
    })
  );
  return itemsToRender;
};

const prepareWordTemplate = ({
  model,
  documentTemplate,
  intl,
  withImages,
  downloadedFiles,
}) => {
  const modelArray = Array.isArray(model) ? model : [model];

  return modelArray.map((modelItem) => {
    const p = prepareSection({
      modelItem,
      documentTemplate,
      intl,
      withImages,
      downloadedFiles,
    }).filter((p) => p !== undefined);

    return p;
  });
};

export const AbstractDetailWord = ({
  documentTemplate,
  model,
  files,
  intl,
}) => {
  const [downloadedFiles, setDownloadedFiles] = useState({});

  useEffect(() => {
    Promise.all(
      files.map(({ s3Url, ...fileData }) => {
        return fileHelper.getS3LinkWithDownload({ s3Url, fileData });
      })
    ).then((nextDownloadedFiles) => {
      Promise.all(
        nextDownloadedFiles.map((file, index) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.onload = function () {
              resolve({ file, width: img.width, height: img.height });
            };
            img.src = files?.[index]?.s3Url;
          });
        })
      ).then((nextDownloadedFilesWithSizes) => {
        const mapped = nextDownloadedFilesWithSizes.reduce(
          (prev, next, index) => {
            prev[files?.[index]?.s3Url] = next;
            return prev;
          },
          {}
        );
        setDownloadedFiles(mapped);
      });
    });
  }, [files]);

  const generateWordDocument = (withImages = false, downloadedFiles = {}) => {
    const commonStyle = {
      font: 'Arial',
      size: '14pt',
      bold: false,
      italics: false,
      underline: false,
    };

    const doc = new Document({
      styles: {
        default: {
          //COMMON STYLE
          document: {
            run: commonStyle,
          },
          heading1: {
            run: {
              bold: true,
              color: '000000',
              size: '18pt',
            },
            paragraph: {
              spacing: {
                after: 120,
              },
            },
          },
        },
      },
      sections: [
        {
          properties: {},
          children: prepareWordTemplate({
            documentTemplate,
            model,
            intl,
            withImages,
            downloadedFiles,
          }).flat(),
        },
      ],
    });

    Packer.toBlob(doc).then(async (blob) => {
      if (withImages) {
        saveAs(blob, 'abstracts.docx');
      } else {
        await fileHelper.downloadZipFromS3(
          [
            ...files,
            {
              originalName: 'abstract book.docx',
              Body: blob,
              folder: 'abstracts',
            },
          ],
          'abstracts.zip'
        );
      }
    });
  };

  return (
    <>
      <AimIconAndTextButton
        text="Download"
        onClick={() => generateWordDocument(false, downloadedFiles)}
        style={{ background: 'white', marginTop: 20, marginBottom: 20 }}
      >
        <GetAppIcon />
      </AimIconAndTextButton>
      <AimIconAndTextButton
        text="Download With images rtf"
        onClick={() => generateWordDocument(true, downloadedFiles)}
        style={{ background: 'white', marginTop: 20, marginBottom: 20 }}
        disabled={!Object.values(downloadedFiles)}
      >
        <GetAppIcon />
      </AimIconAndTextButton>
    </>
  );
};
