import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getPrintOptionsItemsQuery = /* GraphQL */ `
  query GetPrintOptionsItems($id: ID!) {
    getEvent(id: $id) {
      id
      agenda: agendaDraft {
        id
        printOptionsItem {
          items {
            id
            key
            fontSize
            isBold
            isUnderlined
            isItalic
            position
          }
        }
      }
    }
  }
`;

export const getEventPrintOptions = async (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getPrintOptionsItemsQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        let attendeePrintOption = {};
        const nextPrintOptions = response.data.getEvent.agenda.printOptionsItem.items.map(
          (po) => {
            let nextPo = {
              ...po,
              position: po.position ? JSON.parse(po.position) : null,
            };
            if (po.key === constants.PrintOptions.attendeeName.key) {
              attendeePrintOption = nextPo;
            } else if (po.key === constants.PrintOptions.abstractAuthors.key) {
              const isUniformToFaculty =
                nextPo?.position?.[
                  constants.PrintOptions.abstractAuthors.options.position?.[2]
                    .key
                ];
              if (isUniformToFaculty) {
                nextPo = {
                  ...nextPo,
                  isBold: attendeePrintOption.isBold,
                  isItalic: attendeePrintOption.isItalic,
                  isUnderlined: attendeePrintOption.isUnderlined,
                  position: {
                    ...nextPo.position,
                    ...attendeePrintOption.position,
                  },
                };
              }
            }

            return nextPo;
          }
        );
        resolve(nextPrintOptions);
      })
      .catch((e) => {
        console.error('agenda-get-event-print-options', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
