/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';

import { debounce } from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { ExportDialog } from './ExportDialog';

import {
  AimDataGrid,
  AimTypography,
  AimDialog,
  AimIconButton,
  AimSnackbar,
  AimSnackbarSeverity,
  AimIconAndTextButton,
  AimTitleAndButtons,
  AimBigStats,
  AimStats,
  theme,
  AimCardList,
  AimButtonsPanel,
} from '@aim/components';

import { aws, appState, pdfHelper, utilities } from '@aim/common';

import CardsFilter from './CardsFilter';
import PageCard from './PageCard';
import { useModel } from './useModel';
import { useRef } from 'react';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const createCardTemplate = ({
  cardsTemplate,
  labelsTranslations,
  buttonsTranslations,
  CardActions,
}) => ({ row }) => {
  const labels = cardsTemplate
    .filter((key) => typeof key !== 'object')
    .map((key) => ({
      label: labelsTranslations[key],
      value: row[key],
    }));

  // get buttons to add
  const buttonsTemplate =
    buttonsTranslations &&
    cardsTemplate.find((key) => {
      return typeof key === 'object' && key['buttons'];
    });
  const buttons = buttonsTemplate?.buttons.map((button) => {
    return {
      label: buttonsTranslations[button],
      value: row[button],
    };
  });

  return (
    <PageCard
      row={row}
      title={row.title}
      subtitle={row.subtitle}
      labels={labels}
      buttons={buttons}
      cardActions={CardActions && <CardActions row={row} />}
    ></PageCard>
  );
};

/**
 * Build a AIM page from a model.
 * @typedef {Object|function} Model - The model responsible for the page or a function with refresh data.
 * @property {object} model.header - The object containing the header part definition.
 * @property {function(tData, data, translation.header.title)|string} model.header.title - A simple string or a function to calculate the title with transformed and original data array and the translation as params.
 * @property {object} model.header.backButton - The object for the page back button.
 * @property {function(tData, data) | boolean} model.header.backButton.hiddenWhen - Function to determine if back button is hidden with transformed and original data array as params. Or a simple boolean.
 * @property {string='back'} model.header.backButton.label - The label for the back button.
 * @property {function} model.header.backButton.onClick - The back button function.
 * @property {object} model.header.action - The object containing header buttons.
 * @property {string} model.header.action.[name] - Header button object.
 * @property {string} model.header.action.[name].icon - Header button icon.
 * @property {string} model.header.action.[name].variant - Header button color variant.
 * @property {function(selectedRows) | string} model.header.action.[name].dialog - The name of the dialog you want to use (looks inside dialog object).
 * @property {function(selectedRows) | string} model.header.action.[name].snackbar - The name of the snackbar you want to use for feedback (looks inside snackbar object).
 * @property {function(selectedRows)} model.header.action.[name].disabledWhen - Header button function to determine if it is disabled. Selected rows as parameter, you need to return a boolean.
 * @property {function(selectedRows)} model.header.action.[name].hiddenWhen - Header button function to determine if it is hidden. Selected rows as parameter, you need to return a boolean.
 * @property {function(selectedRowsObject, allRowsObject)} model.header.action.[name].onClick - Header button onClick function, selected rows objects and all rows objects as parameters, if function return 'dialog' if you want to use a dialog. Or 'dialog' if you want to use a dialog
 * @property {object} model.tableState - The object containing definitions for initial data loading.
 * @property {object[]} model.tableState.rows - Array of objects containing definitions for initial table rows.
 * @property {object[]} model.tableState.stats - Array of objects containing definitions for initial table stats.
 * @property {object[]} model.tableState.bigStats - Array of objects containing definitions for initial table bigStats.
 * @property {object} model.tableState.isButtonsBoxOpen -The object containing definitions for initial buttons box state .
 * @property {object} model.dataLoader - The object containing definitions for data loading.
 * @property {string} model.dataLoader.query - The query for the initial data loading.
 * @property {object} model.dataLoader.variables - Variables for the query.
 * @property {function} model.dataLoader.transform - Transformation function for data shaping.transform
 * @property {function} model.dataLoader.initialDisplayDataFilter - filter function for inital data display
 * @property {object[] | function(tData, data)} model.dataLoader.bigStats - Array for big stats. Or a function to handle dynamic bigStats fields, transformed data and original data as parameters.
 * @property {string} model.dataLoader.bigStats[].title - A string to get names from translation.stats, if nothing there the string itself will be used.
 * @property {function(tdata, data)|string} model.dataLoader.bigStats[].value - Function for calculations, transformed data and original data as parameters. Or a string for static values
 * @property {object[] | function(tData, data)} model.dataLoader.stats - Array for stats. Or a function to handle dynamic stats fields, transformed data and original data as parameters.
 * @property {string} model.dataLoader.stats[].title - A string to get names from translation.stats, if nothing there the string itself will be used.
 * @property {function(tdata, data)|string} model.dataLoader.stats[].value - Function for calculations, transformed data and original data as parameters. Or a string for static values
 * @property {object} model.card - The object containing definitions for the card.
 * @property {boolean=false} model.card.isCardSelectable - True if you want selectable card.
 * @property {object[]} model.card.labelsTemplate - Definitions array of string with labels keys.
 * @property {object[object[]]} model.card.cardsTemplate[buttons[]] - object buttons as array of strings included in cards template array.
 * @property {(string[]|object[])} model.card.filters - The object containing definitions for the filters.
 * @property {string} model.card.filters[].column - Name for the column for filtering, based on transformed data.
 * @property {string} model.card.filters[].type - Type of control/component to use.
 * @property {('string'|'checkbox'|'select')} model.card.filters[].type - Type of control/component to use. string.
 * @property {object[]} model.card.cardAction - The object containing definitions for the single card action.
 * @property {string} model.card.cardAction[].icon - card action icon.
 * @property {string} model.card.cardAction.variant - card action color variant.
 * @property {function(row)} model.card.cardAction.disabledWhen - card action function to determine if it is disabled. Selected rows as parameter, you need to return a boolean.
 * @property {function(row)} model.card.cardAction.hiddenWhen - card action function to determine if it is hidden. Selected rows as parameter, you need to return a boolean.
 * @property {function(row, allRows)} model.card.cardAction.onClick - card action onClick function, current row and all rows as parameter, if function return 'dialog' if you want to use a dialog. Or 'dialog' if you want to use a dialog.
 * @property {string} model.card.cardAction.dialog - The name of the dialog you want to use (looks inside dialog object).
 * @property {string} model.card.cardAction.snackbar - The name of the snackbar you want to use for feedback (looks inside snackbar object).


 * @property {object} model.table - The object containing definitions for the table.
 * @property {boolean=false} model.table.isRowSelectable - True if you want selectable rows.
 * @property {object[]|function} model.table.columnsTemplate - Definitions array for table columns or a function that return that.
 * @property {string} model.table.columnsTemplate[].id - Column name, based on transformed data.
 * @property {boolean=false} model.table.columnsTemplate[].numeric - Is data numeric.
 * @property {boolean=false} model.table.columnsTemplate[].disablePadding - Is padding disabled.
 * @property {boolean=false} model.table.columnsTemplate[].isCheckbox - Is a checkbox.
 * @property {boolean=false} model.table.columnsTemplate[].isDisabled - Is disabled.
 * @property {boolean=false} model.table.columnsTemplate[].hideLabel - header label is not visible.
 * @property {boolean=false} model.table.columnsTemplate[].hiddenWhen - header label is not visible.
 * @property {(string[]|object[])} model.table.filters - The object containing definitions for the filters.
 * @property {string} model.table.filters[].column - Name for the column for filtering, based on transformed data.
 * @property {string} model.table.filters[].type - Type of control/component to use.
 * @property {('string'|'checkbox'|'select')} model.table.filters[].type - Type of control/component to use. string.
 * @property {object[]} model.table.buttonsBox - Buttons Panel.

 * @property {string || number} model.table.buttonsBox[].id - Id of button who open the panel.
 * @property {string} model.table.buttonsBox[].icon - Icon of button who open the panel.
 * @property {string} model.table.buttonsBox[].variant - Variant of button who open the panel.
 * @property {string} model.table.buttonsBox[].text - Text of button who open the panel.
 * @property {function(tableState)} model.table.buttonsBox[].onApply- action function to determine onApply.
 * @property {function(tableState)} model.table.buttonsBox[].onCancel- action function to determine onCancel.
 * @property {object[]} model.table.buttonsBox[].items - Buttons panel items.
 * @property {string} model.table.buttonsBox[].items[].id- Id of one button of the panel.
 * @property {string} model.table.buttonsBox[].items[].icon- Icon of one button of the panel.
 * @property {string} model.table.buttonsBox[].items[].text- Text of one button of the panel.
 * @property {function(checkedItems,tableState)} model.table.buttonsBox[].items[].onClick- action function to determine onClick.
 * @property {boolean=false} model.table.buttonsBox[].items[].disabled- Boolean disabled button.

 * @property {function(row)} model.table.rowActionText - Row action text function to determine if row action cover text is enabled.
 * @property {object[]} model.table.rowAction - The object containing definitions for the single row action.
 * @property {string} model.table.rowAction[].icon - Row action icon.
 * @property {string} model.table.rowAction.variant - Row action color variant.
 * @property {function(row)} model.table.rowAction.disabledWhen - Row action function to determine if it is disabled. Selected rows as parameter, you need to return a boolean.
 * @property {function(row)} model.table.rowAction.hiddenWhen - Row action function to determine if it is hidden. Selected rows as parameter, you need to return a boolean.
 * @property {function(row, allRows)} model.table.rowAction.onClick - Row action onClick function, current row and all rows as parameter, if function return 'dialog' if you want to use a dialog. Or 'dialog' if you want to use a dialog.
 * @property {function(row) | string} model.table.rowAction.dialog - The name of the dialog you want to use (looks inside dialog object).
 * @property {function(row) | string} model.table.rowAction.snackbar - The name of the snackbar you want to use for feedback (looks inside snackbar object).
 * @property {object} model.dialog - The object containing definitions for the dialogs.
 * @property {object} model.dialog.[name] - The name of the dialog
 * @property {function} model.dialog.[name].onAgree - Agree function for dialog.
 * @property {function} model.dialog.[name].onDisagree - Disagree function for the dialog.
 * @property {object} model.snackbar - The object containing definitions for the snackbars (empty).
 */

/**
 * AimTablePage props object
 * @typedef {Object} AimTablePageProps - The model responsible for the page.
 * @property {Model} model - The {@link Model} containing all components of the toast.
 * @property {object} translation - i18n translations
 * /

/**
 * A component for table pages
 * @param {AimTablePageProps} aimTablePageProps - {@link AimTablePageProps} component props.
 * @return {ReactComponent}
 */

const consoleStyle = [
  'background: #fdf6e3',
  'color: #657b83',
  'padding: 4px',
].join(';');

const makeConsoleFns = (debug) => ({
  consoleLog: (value, ...args) => {
    if (!debug) return;
    console.log(`%c${value}`, consoleStyle, ...args);
  },
  consoleCount: (value) => {
    if (!debug) return;
    console.count(value);
  },
  startTimer: (timerName) => {
    if (!debug) return;
    console.time(timerName);
  },
  stopTimer: (timerName) => {
    if (!debug) return;
    console.timeEnd(timerName);
  },
});

const AimPageBase = ({
  model,
  translation,
  intl,
  isCardPage,
  isTablePage,
  isStatsOpenOnInit = false,
  debug,
  ...rest
}) => {
  const loadDataRef = useRef();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [originalDataObject, setOriginalDataObject] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [bigStatsData, setBigStatsData] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [headCells, setHeadCells] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isStatsOpen, setIsStatsOpen] = useState(isStatsOpenOnInit);
  const [isButtonsBoxOpen, setIsButtonsBoxOpen] = useState({});
  const [displayDataArray, setDisplayDataArray] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [dialogState, setDialog] = useState({ isOpen: false });
  const [dialogExportState, setDialogExport] = useState({ isOpen: false });
  const [checkedExportItems, setCheckedExportItems] = useState([]);
  const [snackbarState, setSnackbar] = useState({ isOpen: false });
  const [tableTitle, setTableTitle] = useState();
  const [subTitle, setSubTitle] = useState();
  const [tableRows, setTableRows] = useState({
    checkedRows: [],
    filteredRows: [],
    rawData: {},
  });

  const { consoleLog, consoleCount, startTimer, stopTimer } = makeConsoleFns(
    debug
  );
  consoleCount('render');

  const {
    header,
    dataLoader,
    card: { cardsTemplate, filters: cardFilters, cardAction },
    preTableComponent,
    table: {
      tableContainerMaxHeight,
      isRowSelectable,
      columnsTemplate,
      rowAction,
      rowActionText,
      buttonsBox,
      sortModel,
    },
    dialog,
    snackbar,
    tableState,
    setNewModel,
  } = useModel();
  // const {
  //   header,
  //   dataLoader,
  //   card: { cardsTemplate, filters: cardFilters, cardAction } = {},
  //   preTableComponent,
  //   table: {
  //     isRowSelectable,
  //     columnsTemplate,
  //     filters: tableFilters,
  //     rowAction,
  //     rowActionText,
  //     buttonsBox,
  //   } = {},
  //   dialog,
  //   snackbar,
  //   tableState,
  // } = nodelObj;

  loadDataRef.current = async (tState) => {
    if (!dataLoader) return;
    let tData = tState?.rows;
    let nextStats = tState?.stats;
    let nextBigStats = tState?.bigStats;
    let nextIsButtonBoxOpen = tState?.isButtonsBoxOpen;

    if (!tData) {
      const data = await getData({
        query: dataLoader.query,
        variables: dataLoader.variables,
      });

      consoleLog('data', data);
      startTimer('>> TRANSFORM DATA');
      tData = dataLoader.transform ? await dataLoader.transform(data) : data;
      stopTimer('>> TRANSFORM DATA');
      dataLoader.transform && consoleLog('tData', tData);
      setOriginalDataObject(data);
      const bigStatsObj =
        dataLoader.bigStats && typeof dataLoader.bigStats === 'function'
          ? dataLoader.bigStats(tData, data)
          : dataLoader.bigStats;
      if (bigStatsObj) {
        nextBigStats = bigStatsObj.map((obj) => {
          const title = translation?.stats?.[obj.title] || obj.title;
          const value =
            typeof obj.value === 'function'
              ? obj.value(tData, data)
              : obj.value;
          return { title, value };
        });
        consoleLog('nextBigStats', nextBigStats);
      }
      const statsObj =
        dataLoader.stats && typeof dataLoader.stats === 'function'
          ? dataLoader.stats(tData, data)
          : dataLoader.stats;
      if (statsObj) {
        nextStats = statsObj.map((obj) => {
          const title = translation?.stats?.[obj.title] || obj.title;
          const value =
            typeof obj.value === 'function'
              ? obj.value(tData, data)
              : obj.value;
          return { title, value };
        });
        consoleLog('nextStats', nextStats);
      }
    }
    setBigStatsData(nextBigStats);
    setStatsData(nextStats);
    setDataArray(tData);
    setDisplayDataArray(
      dataLoader?.initialDisplayDataFilter
        ? dataLoader.initialDisplayDataFilter(tData)
        : tData
    );
    setIsButtonsBoxOpen(nextIsButtonBoxOpen || {});
    if (!tData.length) setCheckedItems([]);
  };

  const realoadData = () => loadDataRef.current();

  useEffect(() => {
    consoleLog('model', model);

    if (model && typeof model === 'function') {
      setNewModel(model(realoadData));
    } else {
      setNewModel(model);
    }
  }, [model]);

  useEffect(() => {
    dataLoader &&
      isFirstLoad &&
      loadDataRef
        .current({
          ...tableState,
          rows: tableState?.rows?.length ? tableState.rows : null,
        })
        .then(() => setIsFirstLoad(false));
  }, [dataLoader]);

  useEffect(() => {
    !isFilterOpen && setDisplayDataArray(dataArray);
  }, [isFilterOpen]);

  useEffect(() => {
    if (!dataArray || !dataArray.length) return;

    let nextColumnsTemplate;
    if (typeof columnsTemplate === 'function') {
      nextColumnsTemplate = columnsTemplate(dataArray, originalDataObject);
    } else {
      nextColumnsTemplate = columnsTemplate;
    }
    let nextHeadCells =
      nextColumnsTemplate && dataArray && dataArray?.length
        ? nextColumnsTemplate
            .filter(
              (column) =>
                !column?.hiddenWhen || !column.hiddenWhen(originalDataObject)
            )
            .map((column) => {
              if (typeof column === 'string') {
                return {
                  field: column,
                  type: 'string',
                  headerName: hideLabel
                    ? ''
                    : translation.table.columns[column] || column,
                  hideSortIcons: column.sortable === false,
                  flex: 1,
                  minWidth: 150,
                };
              }
              const {
                id,
                field,
                numeric = false,
                hideLabel = false,
                type,
                isCheckbox,
                renderCell,
                valueGetter,
                valueFormatter,
                headerName,
              } = column;
              let nextType = type;
              if (isCheckbox) {
                nextType = 'boolean';
              }
              return {
                field: field || id,
                type: nextType ? nextType : numeric ? 'number' : 'string',
                headerName: hideLabel
                  ? ''
                  : headerName || translation.table.columns[id] || id,
                hideSortIcons: column.sortable === false,
                flex: 1,
                minWidth: 150,
                isCheckbox,
                ...(renderCell && { renderCell }),
                valueGetter,
                valueFormatter,
              };
            })
        : [];

    if (rowAction) {
      nextHeadCells = [
        ...nextHeadCells,
        {
          field: 'actions',
          headerName: '', // non tradotto xk andrebbe nascosto, devo capire come si fa
          type: 'button',
          hideSortIcons: true,
          renderCell: (props) => RowActions(props),
          width: Object.keys(rowAction).length * 38 + 20,
        },
      ];
    }

    setHeadCells(nextHeadCells);
  }, [columnsTemplate, rowAction, dataArray, originalDataObject, RowActions]);

  const HeaderActionsBase = ({ action, checkedItems }) =>
    Object.keys(action).map((k) => {
      const {
        icon,
        variant,
        disabledWhen,
        hiddenWhen,
        onClick,
        dialog: dialogRaw,
        snackbar: snackbarRaw,
        isUpperCase,
      } = action[k];

      let dialogName = '';
      if (typeof dialogRaw === 'function') {
        dialogName = dialogRaw(checkedItems);
      } else {
        dialogName = dialogRaw;
      }
      let snackbarName = '';
      if (typeof snackbarRaw === 'function') {
        snackbarName = snackbarRaw(checkedItems);
      } else {
        snackbarName = snackbarRaw;
      }

      const isHidden =
        hiddenWhen && hiddenWhen(checkedItems, originalDataObject, dataArray);
      if (isHidden) return null;

      const onClickFn = async () => {
        if (onClick && typeof onClick === 'function') {
          const result = await onClick(checkedItems, dataArray, tableRows);
          if (result !== 'dialog') {
            if (snackbarName) {
              const snackbarType =
                result === true
                  ? 'success'
                  : result === false
                  ? 'error'
                  : 'info';

              setSnackbar({
                isOpen: true,
                message: translation.snackbar[snackbarName][snackbarType],
                severity: AimSnackbarSeverity[snackbarType],
              });
            }
          } else {
            if (dialogName) {
              setDialog({
                dialogName,
                isOpen: true,
                ...dialog[dialogName],
                ...translation.dialog[dialogName],
                snackbarName, // I store snackbar name for after the action
                payload: checkedItems,
              });
            }
            if (snackbarName) {
              setSnackbar({
                snackbarName,
                isOpen: !dialogName, // if a dialog is defined then I will open after the action
                ...snackbar[snackbarName],
                ...translation.snackbar[snackbarName],
                message: translation.snackbar[snackbarName]?.info || '',
                payload: checkedItems,
              });
            }
          }
        } else {
          // if a dialog is defined I use this
          if (dialogName) {
            setDialog({
              dialogName,
              isOpen: true,
              ...dialog[dialogName],
              ...translation.dialog[dialogName],
              snackbarName,
              payload: checkedItems,
            });
          }
          if (snackbarName) {
            setSnackbar({
              snackbarName,
              isOpen: !dialogName,
              ...snackbar[snackbarName],
              ...translation.snackbar[snackbarName],
              message: translation.snackbar[snackbarName]?.info || '',
              payload: checkedItems,
            });
          }
        }
      };

      const { label, tooltip = '', disabledTooltip } = translation.header
        ?.action[k]
        ? translation.header.action[k]
        : { label: null, tooltip: '', disabledTooltip: '' };

      const isDisabled =
        disabledWhen &&
        disabledWhen(checkedItems, originalDataObject, dataArray);

      const currentTooltip = isDisabled ? disabledTooltip : tooltip;

      return label ? (
        <Tooltip key={`header-action-${k}`} title={currentTooltip}>
          <span>
            <AimIconAndTextButton
              small
              key={k}
              variant={variant}
              disabled={isDisabled}
              onClick={onClickFn}
              text={label}
              isUpperCase={isUpperCase}
            >
              {icon}
            </AimIconAndTextButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip key={`header-action-${k}`} title={currentTooltip}>
          <span>
            <AimIconButton
              small
              key={k}
              aria-label={k}
              variant={variant}
              disabled={isDisabled}
              onClick={onClickFn}
            >
              {icon}
            </AimIconButton>
          </span>
        </Tooltip>
      );
    });

  const HeaderActions = React.memo(HeaderActionsBase);

  const TitleAndButtonsBase = ({
    title,
    subTitle,
    isFilter,
    isFilterOpen,
    setIsFilterOpen,
    setIsStatsOpen,
    isButtonsBox,
    isButtonsBoxOpen,
    setIsButtonsBoxOpen,
    action,
    checkedItems,
  }) => {
    let currentTitle;
    if (typeof title === 'function')
      currentTitle = title(
        dataArray,
        originalDataObject,
        translation.header.title
      );
    else if (translation.header.title) currentTitle = translation.header.title;
    else currentTitle = title;

    setTableTitle(currentTitle);

    if (subTitle && typeof subTitle === 'function') {
      setSubTitle(
        subTitle(dataArray, originalDataObject, translation.header.title)
      );
    }

    return (
      <AimTitleAndButtons
        title={currentTitle}
        titleChildren={
          dataLoader.bigStats || dataLoader.stats ? (
            <InfoIcon
              style={{
                fontSize: '1.25rem',
                paddingLeft: 5,
                color: theme.colors.greyScale.grey3,
                cursor: 'pointer',
              }}
              onClick={() => setIsStatsOpen((currentStatus) => !currentStatus)}
            />
          ) : undefined
        }
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isFilter && (
            <AimIconButton
              variant="lightBlue"
              small
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              <FilterListIcon />
            </AimIconButton>
          )}
          {!header?.hideExcelExport && !isCardPage && (
            <AimIconButton
              small
              variant="green"
              onClick={() =>
                isTablePage
                  ? utilities.exportExcel({
                      rawData: tableRows.rawData,
                      filename: tableTitle,
                    })
                  : setDialogExport({
                      isOpen: true,
                      title: 'Export Excel',
                      agreeText: 'export',
                      type: 'excel',
                    })
              }
            >
              <CallMadeIcon />
            </AimIconButton>
          )}
          {!header?.hidePdfExport && !isCardPage && (
            <AimIconButton
              small
              variant="lightBlue"
              onClick={() =>
                isTablePage
                  ? utilities.exportDataGridToPdf({
                      rawData: tableRows.rawData,
                      fileName: tableTitle,
                      tableTitle,
                    })
                  : setDialogExport({
                      isOpen: true,
                      title: 'Export Pdf',
                      agreeText: 'export',
                      type: 'pdf',
                    })
              }
            >
              <PictureAsPdfOutlinedIcon />
            </AimIconButton>
          )}

          {isButtonsBox &&
            isButtonsBox.map((x, index) => {
              return (
                <>
                  {!x.hidden && (
                    <AimIconAndTextButton
                      small
                      key={'btn' + index}
                      variant={x.variant}
                      onClick={() => {
                        setIsButtonsBoxOpen({
                          ...isButtonsBoxOpen,
                          [x.id]: !isButtonsBoxOpen[x.id],
                        });
                      }}
                      text={x.text}
                      disabled={x.disabled}
                    >
                      {x.icon}
                    </AimIconAndTextButton>
                  )}
                </>
              );
            })}
          <HeaderActions action={action} checkedItems={checkedItems} />
        </div>
      </AimTitleAndButtons>
    );
  };

  const TitleAndButtons = React.memo(TitleAndButtonsBase);

  const RowActions = ({ row, rowNode }) => {
    if (!rowAction) return null;
    const isActionTextEnabled = rowActionText && rowActionText(row);
    if (isActionTextEnabled) {
      return (
        <AimTypography variant="text">
          {translation.table?.rowActionText}
        </AimTypography>
      );
    } else {
      return Object.keys(rowAction).map((k) => {
        const {
          icon,
          variant,
          disabledWhen,
          hiddenWhen,
          onClick,
          dialog: dialogRaw,
          snackbar: snackbarRaw,
        } = rowAction[k];

        let dialogName = '';
        if (typeof dialogRaw === 'function') {
          dialogName = dialogRaw(row);
        } else {
          dialogName = dialogRaw;
        }
        let snackbarName = '';
        if (typeof snackbarRaw === 'function') {
          snackbarName = snackbarRaw(row);
        } else {
          snackbarName = snackbarRaw;
        }

        const isHidden = hiddenWhen && hiddenWhen(row, rowNode);
        if (isHidden) return null;

        const isDisabled =
          disabledWhen && disabledWhen(row, originalDataObject);

        const { tooltip = '', disabledTooltip = '' } = translation.table
          ?.rowAction[k]
          ? translation.table?.rowAction[k]
          : { tooltip: '', disabledTooltip: '' };

        const currentTooltip = isDisabled ? disabledTooltip : tooltip;

        return (
          <Tooltip key={`row-action-${k}`} title={currentTooltip}>
            <span>
              <AimIconButton
                small
                key={`${k}-${row.id}`}
                aria-label={k}
                variant={variant}
                disabled={isDisabled}
                onClick={async () => {
                  // if onClick is defined I use this
                  if (onClick && typeof onClick === 'function') {
                    const result = await onClick(row, dataArray);
                    if (result !== 'dialog') {
                      if (snackbarName) {
                        const snackbarType =
                          result === true
                            ? 'success'
                            : result === false
                            ? 'error'
                            : 'info';

                        setSnackbar({
                          isOpen: true,
                          message:
                            translation.snackbar[snackbarName][snackbarType],
                          severity: AimSnackbarSeverity[snackbarType],
                        });
                      }
                    } else {
                      if (dialogName) {
                        setDialog({
                          dialogName,
                          isOpen: true,
                          ...dialog[dialogName],
                          ...translation.dialog[dialogName],
                          snackbarName, // I store snackbar name for after the action
                          payload: row,
                        });
                      }
                      if (snackbarName) {
                        setSnackbar({
                          snackbarName,
                          isOpen: !dialogName, // if a dialog is defined then I will open after the action
                          ...snackbar[snackbarName],
                          ...translation.snackbar[snackbarName],
                          message:
                            translation.snackbar[snackbarName]?.info || '',
                          payload: row,
                        });
                      }
                    }
                  } else {
                    // if a dialog is defined I use this
                    if (dialogName) {
                      setDialog({
                        dialogName,
                        isOpen: true,
                        ...dialog[dialogName],
                        ...translation.dialog[dialogName],
                        snackbarName, // I store snackbar name for after the action
                        payload: row,
                      });
                    }
                    if (snackbarName) {
                      setSnackbar({
                        snackbarName,
                        isOpen: !dialogName, // if a dialog is defined then I will open after the action
                        ...snackbar[snackbarName],
                        ...translation.snackbar[snackbarName],
                        message: translation.snackbar[snackbarName]?.info || '',
                        payload: row,
                      });
                    }
                  }
                }}
              >
                {icon}
              </AimIconButton>
            </span>
          </Tooltip>
        );
      });
    }
  };

  // const RowTemplateBase = React.useMemo(
  //   () => createRowTemplate(isRowSelectable, columnsTemplate, RowActions),
  //   [isRowSelectable, columnsTemplate, RowActions]
  // );

  // const RowTemplate = React.memo(RowTemplateBase);

  const CardActions = ({ row }) => {
    if (!cardAction) return null;
    return Object.keys(cardAction).map((k) => {
      const {
        icon,
        variant,
        disabledWhen,
        hiddenWhen,
        onClick,
        dialog: dialogName,
        snackbar: snackbarName,
      } = cardAction[k];

      const isHidden = hiddenWhen && hiddenWhen(row);
      if (isHidden) return null;

      const isDisabled = disabledWhen && disabledWhen(row);

      const { tooltip, disabledTooltip } = translation.card?.cardAction[k]
        ? translation.card?.cardAction[k]
        : { tooltip: '', disabledTooltip: '' };

      const currentTooltip = isDisabled ? disabledTooltip : tooltip;

      return (
        <Tooltip key={`row-action-${k}`} title={currentTooltip}>
          <span>
            <AimIconButton
              small
              key={`${k}-${row.id}`}
              aria-label={k}
              variant={variant}
              disabled={isDisabled}
              onClick={async () => {
                // if onClick is defined I use this
                if (onClick && typeof onClick === 'function') {
                  const result = await onClick(row, dataArray);
                  if (result !== 'dialog') {
                    if (snackbarName) {
                      const snackbarType =
                        result === true
                          ? 'success'
                          : result === false
                          ? 'error'
                          : 'info';

                      setSnackbar({
                        isOpen: true,
                        message:
                          translation.snackbar[snackbarName][snackbarType],
                        severity: AimSnackbarSeverity[snackbarType],
                      });
                    }
                  } else {
                    if (dialogName) {
                      setDialog({
                        dialogName,
                        isOpen: true,
                        ...dialog[dialogName],
                        ...translation.dialog[dialogName],
                        snackbarName, // I store snackbar name for after the action
                        payload: row,
                      });
                    }
                    if (snackbarName) {
                      setSnackbar({
                        snackbarName,
                        isOpen: !dialogName, // if a dialog is defined then I will open after the action
                        ...snackbar[snackbarName],
                        ...translation.snackbar[snackbarName],
                        message: translation.snackbar[snackbarName]?.info || '',
                        payload: row,
                      });
                    }
                  }
                } else {
                  // if a dialog is defined I use this
                  if (dialogName) {
                    setDialog({
                      dialogName,
                      isOpen: true,
                      ...dialog[dialogName],
                      ...translation.dialog[dialogName],
                      snackbarName, // I store snackbar name for after the action
                      payload: row,
                    });
                  }
                  if (snackbarName) {
                    setSnackbar({
                      snackbarName,
                      isOpen: !dialogName, // if a dialog is defined then I will open after the action
                      ...snackbar[snackbarName],
                      ...translation.snackbar[snackbarName],
                      message: translation.snackbar[snackbarName]?.info || '',
                      payload: row,
                    });
                  }
                }
              }}
            >
              {icon}
            </AimIconButton>
          </span>
        </Tooltip>
      );
    });
  };

  const CardTemplateBase = React.useMemo(
    () =>
      createCardTemplate({
        isRowSelectable,
        cardsTemplate,
        labelsTranslations: translation?.card?.labels,
        buttonsTranslations: translation?.card?.buttons,
        CardActions,
      }),
    [isRowSelectable, columnsTemplate, RowActions]
  );

  const CardTemplate = React.memo(CardTemplateBase);

  const handleExport = (type, headCells) => {
    // extract rows with props to export from dataArray
    const nextDataArray = checkedItems.length
      ? dataArray.filter((x) => checkedItems.includes(x.id))
      : dataArray;
    const rowsToExport = nextDataArray.map((row) => {
      const nextKeys = Object.keys(row).reduce(
        (res, curr) =>
          checkedExportItems.includes(curr)
            ? { ...res, [curr]: row[curr] }
            : res,
        {}
      );
      return nextKeys;
    });

    // create head of pdf/excel
    const head = checkedExportItems.map((headId) => {
      const headCellChecked = headCells.find((cell) => cell.id === headId);
      return headCellChecked.label;
    });

    if (type === 'pdf') {
      handleExportPdf(checkedExportItems, head, rowsToExport);
    }
    if (type === 'excel') {
      handleExportExcel(checkedExportItems, head, rowsToExport);
    }
  };

  const handleExportPdf = (keys, head, rows) => {
    // create body of pdf
    const body = rows.map((row) => {
      return keys.reduce((res, curr) => [...res, row[curr] || ''], []);
    });

    const tables = [
      {
        head: [head],
        body,
      },
    ];

    pdfHelper.exportTablePdfWithStandardTemplate({
      tables,
      eventData: appState.eventInfo.getValue(),
      tableName: subTitle ? { tableTitle, subTitle } : { tableTitle },
    });
  };

  const handleExportExcel = (keys, header, rows) => {
    utilities.objectsToExcel({
      filename: `export table ${tableTitle}`,
      header,
      keys,
      objects: rows,
    });
  };

  const updateVisibleAndCheckedRows = (data) => {
    const filteredRows =
      Object.entries(data.filter?.visibleRowsLookup || {})
        ?.filter(([_, isVisible]) => isVisible)
        .map(([key]) => data.rows.idRowsLookup[key]) || [];
    const checkedRows =
      data.selection?.map((s) => data.rows.idRowsLookup[s]) || [];
    // const allRows =
    //   data.rows.allRows?.map((s) => data.rows.idRowsLookup[s]) || [];
    setTableRows({ filteredRows, checkedRows, rawData: data });
    setCheckedItems(checkedRows);
  };

  //to prevent too many rerender
  const debouncedSelectedVisibleRows = useRef(
    debounce((data) => {
      updateVisibleAndCheckedRows(data);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSelectedVisibleRows.cancel();
    };
  }, [debouncedSelectedVisibleRows]);

  const handleOnAgree = async () => {
    setDialog({ ...dialogState, isOpen: false });
    try {
      dialogState.onAgree &&
        (await dialogState.onAgree(
          dialogState.payload,
          dataArray,
          checkedItems
        ));

      if (
        dialogState?.snackbarName &&
        dialogState.snackbarName === snackbarState.snackbarName
      ) {
        setSnackbar({
          isOpen: true,
          message: translation.snackbar[snackbarState.snackbarName].success,
          severity: AimSnackbarSeverity.success,
        });
      }
    } catch (e) {
      console.error(e);

      if (dialogState.snackbarName === snackbarState.snackbarName) {
        setSnackbar({
          isOpen: true,
          message: translation.snackbar[snackbarState.snackbarName]?.error,
          severity: AimSnackbarSeverity.error,
        });
      }
    }
  };

  const handleOnDisagree = async () => {
    setDialog({ ...dialogState, isOpen: false });
    dialogState.onDisagree &&
      (await dialogState.onDisagree(dialogState.payload, dataArray));
  };

  const StatsComponentBase = () =>
    dataLoader && (dataLoader.bigStats || dataLoader.stats) ? (
      <Collapse in={isStatsOpen}>
        <div
          style={{
            backgroundColor: theme.colors.greyScale.grey2,
            padding: '10px',
            borderRadius: 3,
          }}
        >
          {dataLoader.bigStats && (
            <AimBigStats data={bigStatsData} containerStyle={{}} />
          )}
          {dataLoader.stats && (
            <AimStats data={statsData} containerStyle={{}} />
          )}
        </div>
      </Collapse>
    ) : null;

  const StatsComponent = React.memo(StatsComponentBase);

  let isBackButtonHidden = false;
  if (header?.backButton?.hiddenWhen) {
    if (typeof header.backButton.hiddenWhen === 'function') {
      isBackButtonHidden = header.backButton.hiddenWhen(
        dataArray,
        originalDataObject
      );
    } else if (typeof header.backButton.hiddenWhen === 'boolean') {
      isBackButtonHidden = header.backButton.hiddenWhen;
    }
  }

  return (
    <div
      style={{
        flex: 1,
        height: `calc(${
          appState?.mainContainerSize?.getValue?.()?.height
            ? `${appState?.mainContainerSize?.getValue?.()?.height}px`
            : '100vh'
        } - 152px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      {header && (
        <>
          {!isBackButtonHidden && (
            <BackButton
              onClick={header.backButton.onClick}
              label={translation.header.backButton || 'back'}
            />
          )}
          {isTablePage &&
            buttonsBox &&
            buttonsBox.length > 0 &&
            buttonsBox.map((el, index) => {
              return (
                <AimButtonsPanel
                  key={'buttonsBox' + index}
                  isButtonsBoxOpen={isButtonsBoxOpen[el.id]}
                  model={el.items}
                  title={el.text}
                  onItemClick={(item) =>
                    item.onClick(checkedItems, {
                      rows: dataArray,
                      stats: statsData,
                      bigStats: bigStatsData,
                      isButtonsBoxOpen: isButtonsBoxOpen,
                    })
                  }
                  onApply={() => el.onApply && el.onApply(tableState)}
                  onCancel={() => el.onCancel && el.onCancel(tableState)}
                />
              );
            })}
          {isCardPage && (
            <CardsFilter
              isFilterOpen={isFilterOpen}
              model={cardFilters}
              rows={dataArray}
              translation={translation.card}
              onFilterResult={setDisplayDataArray}
            />
          )}
          <TitleAndButtons
            {...header}
            isFilter={cardFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            setIsStatsOpen={setIsStatsOpen}
            checkedItems={checkedItems}
            isButtonsBox={buttonsBox}
            isButtonsBoxOpen={isButtonsBoxOpen}
            setIsButtonsBoxOpen={setIsButtonsBoxOpen}
          />
          <StatsComponent />
        </>
      )}
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {preTableComponent
          ? (typeof preTableComponent === 'function' &&
              preTableComponent(
                dataArray,
                originalDataObject,
                setDisplayDataArray
              )) ||
            preTableComponent
          : null}
        {isTablePage ? (
          <>
            {tableTitle ? (
              <AimDataGrid
                columns={headCells}
                rows={displayDataArray}
                checkboxSelection={isRowSelectable}
                disableSelectionOnClick
                onStateChange={(data) => debouncedSelectedVisibleRows(data)}
                tableName={tableTitle}
                pinnedColumns={{ left: [], right: ['actions'] }}
                sortModel={sortModel}
                {...rest}
              />
            ) : null}
          </>
        ) : null}
        {isCardPage ? (
          dataArray.length === 0 ? (
            <Typography style={{ fontSize: '1rem', marginTop: '32px' }}>
              {translation.card.empty}
            </Typography>
          ) : (
            <AimCardList items={displayDataArray} itemKey="id">
              <CardTemplate />
            </AimCardList>
          )
        ) : null}
      </div>

      <AimDialog
        open={dialogState.isOpen}
        title={dialogState.title}
        agreeText={dialogState.agreeText}
        onAgree={handleOnAgree}
        onAgreeDisabled={dialogState.disabled}
        disagreeText={dialogState.disagreeText}
        onDisagree={handleOnDisagree}
        onClose={() => setDialog({ isOpen: false })}
        disableEnforceFocus
      >
        <AimTypography variant="text">{dialogState.message}</AimTypography>
        {dialogState.children || null}
      </AimDialog>

      <AimDialog
        open={dialogExportState.isOpen}
        title={dialogExportState.title}
        disableEnforceFocus
        agreeText={dialogExportState.agreeText}
        onAgree={() => handleExport(dialogExportState.type, headCells)}
        disagreeText={dialogExportState.disagreeText}
        onDisagree={handleOnDisagree}
        onClose={() => setDialogExport({ isOpen: false })}
      >
        <ExportDialog
          intl={intl}
          headCells={headCells}
          checkedItems={checkedExportItems}
          setCheckedItems={setCheckedExportItems}
        />
      </AimDialog>

      <AimSnackbar
        open={snackbarState.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbarState.severity || AimSnackbarSeverity.info}
      >
        {snackbarState.message}
      </AimSnackbar>
    </div>
  );
};

export const AimTablePage = React.memo((props) => (
  <AimPageBase {...props} isTablePage />
));
export const AimCardPage = React.memo((props) => (
  <AimPageBase {...props} isCardPage />
));

export const BackButton = ({ label, onClick }) => (
  <AimIconAndTextButton
    style={{ padding: 0, display: 'flex', justifyContent: 'flex-start' }}
    variant="text"
    text={label}
    onClick={onClick}
  >
    <ArrowBack />
  </AimIconAndTextButton>
);

export const getData = ({ query, variables, useLoader = true }) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({ query, variables })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        console.error(`Error:`, e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
