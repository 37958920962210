import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { appState, constants } from '@aim/common';

const fontFamilies = {
  roboto: 'Roboto',
  robotoCondensed: 'Roboto Condensed',
  arial: 'Arial',
  arial_mt_std: 'Arial MT Std',
  karbon: 'Karbon',
  hind: 'Hind',
};

const fontWeights = {
  thin: '100',
  light: '300',
  regular: 'fontWeightRegular',
  medium: 'fontWeightMedium',
  bold: 'fontWeightBold',
};

const variantsBACKEND = {
  pageTitle: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: '1.75rem',
  },
  h1: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: '1.75rem',
  },
  h1Regular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: '1.75rem',
  },
  h2: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: '1.3125rem',
  },
  h2Regular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: '1.3125rem',
  },
  h3: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.bold,
    fontSize: '1.125rem',
  },
  h4: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: '1.125rem',
  },
  h4Regular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: '1.125rem',
  },
  h5: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  h5Regular: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
  },
  h6: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  text: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
  },
  textBold: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  columnHeader: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  cardHeader: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.bold,
    fontSize: '1.125rem',
  },
  cardContent: {
    fontFamily: fontFamilies.robotoCondensed,
    fontWeight: fontWeights.regular,
    fontSize: '1.125rem',
  },
  formError: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
    color: 'red',
    marginTop: 0,
  },
  feRegistrationTitle: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.bold,
    fontSize: '2.5rem',
    marginTop: 0,
  },
  textThin: {
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights.thin,
    fontSize: '1.938rem',
  },
};

const variantsFRONTEND = ({ eventConfiguration }) => {
  return {
    h1: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.bold,
      fontSize: '2rem',
    },
    h2: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.regular,
      fontSize: '1.3125rem',
    },
    h3: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.bold,
      fontSize: '1.125rem',
      letterSpacing: '0.113rem',
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.bold,
      fontSize: '1.125rem',
    },
    h5: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.regular,
      fontSize: '1rem',
      letterSpacing: '0.1rem',
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.bold,
      fontSize: '0.875rem',
    },
    text: {
      fontFamily: eventConfiguration.font
        ? eventConfiguration.font
        : fontFamilies.hind,
      fontWeight: fontWeights.regular,
      fontSize: '0.875rem',
    },
    textBold: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.bold,
      fontSize: '0.875rem',
    },
    textThin: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.thin,
      fontSize: '1.938rem',
    },
    button: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.bold,
      fontSize: '1rem',
      letterSpacing: '0.113rem',
      textTransform: 'uppercase',
    },
    formError: {
      fontFamily: eventConfiguration.font,
      fontWeight: fontWeights.regular,
      fontSize: '0.875rem',
      color: 'red',
      marginTop: 0,
    },
    /*
  pageTitle: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.regular,
    fontSize: '2rem',
  },
  h1Regular: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.regular,
    fontSize: '2rem',
  },
  h2Regular: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.regular,
    fontSize: '1.3125rem',
  },
  h4Regular: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.regular,
    fontSize: '1.125rem',
  },
  h5Regular: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },
  columnHeader: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
  },
  cardHeader: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.bold,
    fontSize: '1.125rem',
  },
  cardContent: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.regular,
    fontSize: '1.125rem',
  },
  formError: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.regular,
    fontSize: '0.875rem',
    color: 'red',
    marginTop: 0,
  },
  feRegistrationTitle: {
    fontFamily: fontFamilies.arial,
    fontWeight: fontWeights.bold,
    fontSize: '2.5rem',
    marginTop: 0,
  },*/
  };
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const ellipsisStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const variantTemplate = appState.template.getValue();

export const AimTypography = ({
  variant,
  textAlign = 'left',
  children,
  margin = 1,
  ellipsis,
  boxStyle,
  italic = false,
  style,
  isUpperCase = false,
  ...rest
}) => {
  const italicStyle = {
    fontStyle: 'italic',
  };
  const [eventConfiguration, setEventConfiguration] = useState({});

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  // object with variants h1...h6 / text / textBold
  const nextVariantTemplate =
    variantTemplate === constants.Templates.BACKEND
      ? variantsBACKEND
      : variants[variantTemplate]({ eventConfiguration });
  return (
    <Typography
      {...rest}
      style={{ ...style, ...(ellipsis ? ellipsisStyle : {}) }}
      component="div"
    >
      <Box
        {...nextVariantTemplate[variant]}
        textAlign={textAlign}
        m={margin}
        {...(italic && italicStyle)}
        style={{
          ...boxStyle,
          ...(ellipsis ? ellipsisStyle : {}),
          ...(isUpperCase ? { textTransform: 'uppercase' } : {}),
        }}
      >
        {children}
      </Box>
    </Typography>
  );
};

// const getSeparator = (separatorType, locale) => {
// thread on stackoverflow https://stackoverflow.com/a/51411310/22422709
// const numberWithGroupAndDecimalSeparator = 1000.1;
// return Intl.NumberFormat(locale)
//   .formatToParts(numberWithGroupAndDecimalSeparator)
//   .find((part) => part.type === separatorType).value;
// };

//   // replace this code with the code below, that should be always supported in all browsers
// const thousandSeparator = getSeparator('group') || '.';
// const decimalSeparator = getSeparator('decimal') || ',';
const thousandSeparator = Number(1000).toLocaleString().charAt(1) || '.';
const decimalSeparator = Number(1.1).toLocaleString().charAt(1) || ',';

export const AimTypographyCurrency = ({ prefix, style, children }) => (
  <NumberFormat
    value={children}
    prefix={prefix}
    decimalSeparator={decimalSeparator}
    thousandSeparator={thousandSeparator}
    style={{
      margin: 8,
      fontFamily: fontFamilies.roboto,
      fontWeight: fontWeights.regular,
      textAlign: 'right',
      ...style,
    }}
    displayType="text"
    type="text"
    isNumericString
    allowNegative={true}
  />
);
