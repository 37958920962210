import React, { useEffect, useState } from 'react';

import { nanoid } from 'nanoid';
const fns = require('date-fns-tz');

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styled } from '@material-ui/core/styles';

import { createDefaultValues } from './../AimDynamicForm';

import translation from './translation';
import { getDynamicFields } from './getDynamicFields';
import { appState, constants } from '@aim/common';

import { AimTypography } from './../../atoms';
import { AimList } from './../../bundles';

import {
  getParticipationUserAndFieldValues,
  getParticipantProducts,
} from './gqlHelper';
import { theme } from '@aim/components';

// divide standard and custom fields for the ui
// use baseFields
// make good use of profiles and typologies from event
// filter results by router state

const StyledCard = styled(Card)(() => ({
  height: '100%',
  width: '100%',
  borderRadius: 3,
  boxShadow: '0px 3px 6px #00000029',
}));

const Row = ({ row: item, index }) => {
  return (
    <Grid
      container
      style={{
        backgroundColor:
          index % 2 === 0 ? theme.colors.greyScale.backgroundGrey : 'white',
      }}
    >
      <Grid item xs={3}>
        <AimTypography variant="text" italic>
          {item.service}
        </AimTypography>
      </Grid>
      <Grid item xs={9}>
        <AimTypography variant="text">{item.description}</AimTypography>
      </Grid>
    </Grid>
  );
};

export const ModificationRequestDialog = ({
  intl,
  participationRequest,
  showHiddenFields = true,
  excludePaxAnagraphicFields = false,
  isRequiredEnabled = true,
  onLoadEnd = () => {},
  i18nDialog,
}) => {
  console.log('🚀 ~ participationRequest:', participationRequest);
  const i18n = translation.participationEdit(intl);

  // fields collections
  const [baseFieldsCollection, setBaseFieldsCollection] = useState();
  /* const [standardFieldsCollection, setStandardFieldsCollection] = useState({});
  const [customFieldsCollection, setCustomFieldsCollection] = useState({}); */
  const [participation, setParticipation] = useState();
  const [data, setData] = useState([]);
  console.log('🚀 ~ data:', data);
  const [differences, setDifferences] = useState();
  const [anagraphic, setAnagraphic] = useState([
    { key: i18n.labels.title, value: 'title' },
    { key: i18n.labels.givenName, value: 'givenName' },
    { key: i18n.labels.familyName, value: 'familyName' },
    { key: i18n.labels.email, value: 'email' },
    { key: i18n.labels.phone, value: 'phone' },
    { key: i18n.labels.notes, value: 'notes' },
  ]);

  // TODO discount code
  // const currentType = watch('baseDefaultValues.type');
  // const currentFeeDateRange = watch('baseDefaultValues.feeDateRange');

  const sort = (fields) =>
    fields.sort((a, b) => a.contextsOfUse.position - b.contextsOfUse.position);

  const createCollection = (fields, contextsOfUse, showHiddenFields) =>
    contextsOfUse
      .map((ctx) => ctx)
      .reduce((res, curr) => {
        const filtered = fields.filter(
          (f) => f.contextsOfUse.contextName === curr
        );
        const nextFiltered = showHiddenFields
          ? filtered
          : filtered.filter((f) => !f.contextsOfUse.isHidden);

        const nextFields = sort(nextFiltered);
        return { ...res, [curr]: nextFields };
      }, {});

  const updateStAndCstFields = async (currentProfile) => {
    const { cluster, clusterId, fieldValues } = participationRequest;

    const {
      contextsOfUse,
      fields: [sFields, cFields],
    } = await getDynamicFields(
      participationRequest.event.id,
      cluster,
      clusterId,
      currentProfile || '',
      excludePaxAnagraphicFields
    );

    const nextStandardFieldCollection = createCollection(
      sFields.map((f) => ({
        ...f,
        prefix: 'standardDefaultValues',
        // disabled: isReadOnly,
        isRequired: isRequiredEnabled && f.contextsOfUse.isRequired,
        dirPath:
          f.controlType === 'upload'
            ? `events/${participationRequest.event.id}/participations/${participationRequest.id}/${f.id}`
            : null,
      })),
      contextsOfUse,
      showHiddenFields
    );
    const nextCustomFieldCollection = createCollection(
      cFields.map((f) => ({
        ...f,
        prefix: 'customDefaultValues',
        // disabled: isReadOnly,
        isRequired: isRequiredEnabled && f.contextsOfUse.isRequired,
        dirPath:
          f.controlType === 'upload'
            ? `events/${participationRequest.event.id}/participations/${participationRequest.id}/${f.id}`
            : null,
      })),
      contextsOfUse,
      showHiddenFields
    );

    /* setStandardFieldsCollection(nextStandardFieldCollection);
    setCustomFieldsCollection(nextCustomFieldCollection); */

    const nextStandardDefaultValues = createDefaultValues({
      fields: sFields,
      fieldValues: fieldValues ? fieldValues.items : [],
    });

    const nextCustomDefaultValues = createDefaultValues({
      fields: cFields,
      fieldValues: fieldValues ? fieldValues.items : [],
    });
    return {
      nextStandardFieldCollection,
      nextStandardDefaultValues,
      nextCustomFieldCollection,
      nextCustomDefaultValues,
    };
  };

  // 1. alla ricezione della participation request come prop, voglio ottenere il partecipante vero salvato a db
  useEffect(() => {
    if (!participationRequest) return;

    if (participationRequest.participationId) {
      getParticipationUserAndFieldValues(
        participationRequest.participationId
      ).then((participation) => {
        console.log('🚀 ~ ).then ~ participation:', participation);
        const participationServices = participation.n2nConnection?.items.map(
          (service) => {
            console.log('🚀 ~ ).then ~ service:', service);
            return {
              service: Object.values(
                constants.AdditionalServicesServiceTypology
              )
                .find(
                  (additionalService) =>
                    additionalService.id ===
                    service.additionalServices?.typology
                )
                .label(intl),
              description: service.additionalServices?.title,
            };
          }
        );
        if (participation.feeDateRange) {
          const { label, start, end } = participation.feeDateRange;
          const participationTicket = {
            service: 'Registration',
            description: `${label} - ${fns.format(
              new Date(start),
              'dd/MM/yyyy'
            )} - ${fns.format(new Date(end), 'dd/mm/yyyy')}`,
          };
          participationServices.unshift(participationTicket);
        }
        const completeParticipation = {
          ...participation,
          services: participationServices,
        };
        setParticipation(completeParticipation);

        const participationRequestServices = participationRequest.n2nConnection.items.map(
          (service) => {
            return {
              service: Object.values(
                constants.AdditionalServicesServiceTypology
              )
                .find(
                  (additionalService) =>
                    additionalService.id ===
                    service.additionalServices?.typology
                )
                .label(intl),
              description: service.additionalServices?.title,
            };
          }
        );
        if (participationRequest.feeDateRange) {
          const { label, start, end } = participationRequest.feeDateRange;
          const participationRequestTicket = participationRequest.feeDateRange && {
            service: 'Registration',
            description: `${label} - ${fns.format(
              new Date(start),
              'dd/MM/yyyy'
            )} - ${fns.format(new Date(end), 'dd/mm/yyyy')}`,
          };
          participationRequestServices.unshift(participationRequestTicket);
        }
        const completeParticipationRequest = {
          ...participationRequest,
          services: participationRequestServices,
        };

        setData([completeParticipation, completeParticipationRequest]);
      });
    } else {
      const participationRequestServices = participationRequest.n2nConnection.items.map(
        (service) => {
          return {
            service: Object.values(constants.AdditionalServicesServiceTypology)
              .find(
                (additionalService) =>
                  additionalService.id === service.additionalServices?.typology
              )
              .label(intl),
            description: service.additionalServices?.title,
          };
        }
      );
      const { label, start, end } = participationRequest.feeDateRange;
      const participationRequestTicket = participationRequest.feeDateRange && {
        service: 'Registration',
        description: `${label} - ${fns.format(
          new Date(start),
          'dd/MM/yyyy'
        )} - ${fns.format(new Date(end), 'dd/mm/yyyy')}`,
      };
      participationRequestServices.unshift(participationRequestTicket);
      const completeParticipationRequest = {
        ...participationRequest,
        services: participationRequestServices,
      };

      setData([completeParticipationRequest]); // todo: edit in completeParticipationRequest
    }
  }, [participationRequest]);

  useEffect(() => {
    if (!participation) return;

    const getParticipationServices = async () => {
      const servicesForPaxOrDelegation = await getParticipantProducts({
        clientId: participationRequest.participationId,
      });
      console.log(
        '🚀 ~ getParticipationServices ~ servicesForPaxOrDelegation:',
        servicesForPaxOrDelegation
      );
    };

    getParticipationServices();
  }, [participation]);

  // 2. Ottengo le differenza tra il partipant e la participationRequest
  useEffect(() => {
    if (!data) return;
    // find differences
    const getDifferentKeys = (before, after) => {
      const differentKeys = [];

      for (const key in after) {
        if (before[key] !== after[key]) {
          differentKeys.push(key);
        }
      }

      return differentKeys;
    };

    const differentKeys = getDifferentKeys(data[0], data[1]);
    setDifferences(differentKeys);
  }, [data]);

  useEffect(() => {
    if (!participationRequest) return;

    const load = async () => {
      updateStAndCstFields(participationRequest?.profile?.id).then(
        async ({
          nextStandardFieldCollection,
          nextStandardDefaultValues,
          nextCustomFieldCollection,
          nextCustomDefaultValues,
        }) => {
          const standardFieldsObjects = nextStandardFieldCollection.pax.map(
            (sf) => {
              const value = nextStandardDefaultValues[sf.id];
              return { key: sf.label, value };
            }
          );

          const customFieldsObjects = nextCustomFieldCollection.pax.map(
            (cf) => {
              const value = nextCustomDefaultValues[cf.id];
              return { key: cf.label, value };
            }
          );

          setBaseFieldsCollection([
            ...standardFieldsObjects,
            ...customFieldsObjects,
          ]);
          onLoadEnd();
        }
      );
    };

    load();
  }, [participationRequest]);

  const renderValue = (key, value, index) => {
    if (!differences) return;
    const isAfter = index === 1;

    const isDiff = differences.find((diff) => {
      return diff === key;
    });

    return (
      <AimTypography
        variant="h5Regular"
        boxStyle={{ margin: '0px 0px 0px 2px' }}
        style={
          isDiff &&
          isAfter && { backgroundColor: theme.colors.secondary.lightBlue }
        }
      >
        {value || '-'}
      </AimTypography>
    );
  };

  return (
    <Grid container spacing={3} direction="row">
      {data &&
        data.map((participant, index) => (
          <Grid
            item
            key={participant.id}
            xs={6}
            sm={6}
            style={{
              height: `calc(${
                appState.mainContainerSize.getValue().height
              }px - 275px)`,
            }}
          >
            <StyledCard>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {data.length > 1 && (
                  <AimTypography variant="h2" boxStyle={{ marginLeft: '0px' }}>
                    {index === 0
                      ? i18nDialog.page.dialog.before
                      : i18nDialog.page.dialog.after}
                  </AimTypography>
                )}
                <Divider />
                <div style={{ height: '100%' }}>
                  <div style={{ height: '40%' }}>
                    <AimTypography
                      variant="h4Regular"
                      boxStyle={{ marginLeft: '0px' }}
                    >
                      {i18n.labels.anagraphic.toUpperCase()}
                    </AimTypography>
                    <Grid
                      container
                      direction="column"
                      wrap="wrap"
                      style={{ height: '80%' }}
                    >
                      {anagraphic.map((anagraphicField) => (
                        <Grid
                          key={nanoid()}
                          item
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                          }}
                        >
                          <AimTypography variant="h5" boxStyle={{ margin: 0 }}>
                            {`${anagraphicField.key}: `}
                          </AimTypography>
                          {renderValue(
                            anagraphicField.value,
                            participant[anagraphicField.value],
                            index
                          )}
                        </Grid>
                      ))}
                      {baseFieldsCollection &&
                        baseFieldsCollection.map((fv) => (
                          <Grid
                            key={nanoid()}
                            item
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '50%',
                            }}
                          >
                            <AimTypography
                              variant="h5"
                              boxStyle={{ margin: 0 }}
                            >
                              {`${fv.key}: `}
                            </AimTypography>
                            {renderValue('fieldValues', fv.value, index)}
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                  {participant.services && participant.services.length > 0 && (
                    <div style={{ flex: 1 }} key={nanoid()}>
                      <AimTypography
                        variant="h4Regular"
                        boxStyle={{ marginLeft: '0px' }}
                      >
                        {i18n.labels.services.toUpperCase()}
                      </AimTypography>
                      <div
                        style={{
                          maxHeight: '300px',
                          overflowX: 'hidden',
                          overflowY: 'scroll',
                        }}
                      >
                        <AimList
                          rows={participant.services}
                          rowKey="id"
                          deletable={false}
                          itemStyle={{
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          HeaderRow={
                            <Grid container xs={12}>
                              <Grid item xs={3}>
                                <AimTypography variant="columnHeader">
                                  {i18nDialog.page.dialog.services}
                                </AimTypography>
                              </Grid>
                              <Grid item xs={9}>
                                <AimTypography variant="columnHeader">
                                  {i18nDialog.page.dialog.description}
                                </AimTypography>
                              </Grid>
                            </Grid>
                          }
                        >
                          <Row />
                        </AimList>
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
    </Grid>
  );
};
