import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import format from 'date-fns/format';

import { format as formatTz } from 'date-fns-tz';
const currentTimezone = appState.eventInfo.getValue().timezone;
import enGB from 'date-fns/locale/en-GB';

import { constants, appState } from '@aim/common';

import { AimTypography } from '../atoms';

import { styled } from '../styled';
import { theme } from '../../theme';
import { groupBy } from 'lodash';
import { ProgramTemplate } from './ProgramTemplate';

const StyledRow = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  flex: 1,
  marginTop: 5,
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

const StyledLabel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StyledValue = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  textAlign: 'left',
  marginLeft: 2,
});

const StyledTag = styled('span')({
  padding: 4,
  backgroundColor: 'pink',
  fontSize: '0.75rem',
  fontFamily: 'Roboto',
  borderRadius: 8,
  marginLeft: 6,
});

const InterventionsWrapper = styled('div')({
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  padding: '30px 0',
  margin: '20px auto',
});

const Intervention = styled('div')({
  padding: '12px 8px',
  borderBottom: `1px solid ${theme.colors.greyScale.grey4}`,
});

const InterventionSpeaker = styled(AimTypography)({
  display: 'inline-block',
  marginLeft: 5,
  '&:first-child': {
    marginLeft: 0,
  },
});

const LectureTag = styled('span')({
  color: theme.colors.secondary.lightBlue,
  fontWeight: 'bold',
  fontSize: '0.7rem',
  marginLeft: 3,
});

const Roles = ({ roles }) =>
  roles.map((r) => (
    <StyledRow key={r.id} style={{ width: '50%' }}>
      <StyledLabel>
        <AimTypography variant="h6" margin="0">
          {r.role}:
        </AimTypography>
      </StyledLabel>
      <StyledValue>
        <AimTypography variant="text" margin="0">
          {r.attendees.map((a, idx) => (
            <div key={a.id}>
              <span>
                {a.givenName} {a.familyName}
              </span>
              {idx !== r.attendees.length - 1 && <span>, </span>}
            </div>
          ))}
        </AimTypography>
      </StyledValue>
    </StyledRow>
  ));

export const AgendaSessionTemplate = ({
  eventId,
  session,
  sessionProgram,
  i18n,
  intl,
  onInterventionClick,
}) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (!session) return;

    // const nextRoles = session.subSessions.items
    //   .map((ss) =>
    //     ss.attendeeRoles.items.map((item) => {
    //       const attendees =
    //         item.attendees?.items.map((att) => att.participation) || [];
    //       const next = {
    //         id: item.id,
    //         role: item.role.frontofficeName || item.role.name,
    //         attendees,
    //       };
    //       return next;
    //     })
    //   )
    //   .flat();

    const nextRoles = session.attendeeRoles.items.map((item) => {
      const attendees =
        item.attendees?.items.map((att) => att.participation) || [];
      const next = {
        id: item.id,
        role: item.role.frontofficeName || item.role.name,
        attendees,
      };
      return next;
    });

    setRoles(nextRoles);
  }, [session]);

  const formattedTime = (date) => {
    if (date) return format(date, 'HH:mm');
  };
  const formattedData = (date) => {
    if (date) return format(date, 'dd MMMM yyyy');
  };

  const formattedInTimeZone = (date) => {
    return formatTz(date, "zzz '(UTC' x 'h)'", {
      timeZone: currentTimezone,
      locale: enGB,
    });
  };

  const sortDates = (dates) => dates.sort((a, b) => a.start - b.start);

  const extractAuthorFields = (author) => {
    if (!author || !author.fieldValues) return '';

    const constfieldsArray = author.fieldValues.items.map((f) => {
      return {
        name: f.fieldDefinition?.label ?? '',
        value: f?.value != null ? JSON.parse(f?.value) : '',
      };
    });

    return `${
      constfieldsArray.find((field) => field.key === 'abstract_name_dotted')
        ?.value ||
      constfieldsArray.find((field) => field.key === 'abstract_name_extended')
        ?.value ||
      ''
    } ${
      constfieldsArray.find((field) => field.key === 'abstract_surname')
        ?.value || ''
    } `;
  };

  const calcPosterCode = (
    posterSessions,
    restart,
    orderNumber,
    currentPosterSession
  ) => {
    const { initial, posterSessionNumber, id: posterId } = currentPosterSession;
    const prefix = `${initial}.${posterSessionNumber}.`;
    let calculatedCode = 1;
    if (restart) calculatedCode = orderNumber + 1;
    else {
      const totalPostersCount = posterSessions
        .filter(
          (ps) =>
            ps.posterSessionNumber < currentPosterSession.posterSessionNumber
        )
        .map((ps) => ps?.posters?.items || [])
        .flat().length;

      calculatedCode = totalPostersCount + orderNumber + 1;
    }

    return prefix + calculatedCode;
  };

  const headInfo = ['room', 'type'];
  // const isPosterSession = Object.prototype.hasOwnProperty.call(
  //   session,
  //   'posters'
  // ); // session.hasOwnProperty('posters');

  // Renders
  const renderField = (key) =>
    session[key] && (
      <StyledRow key={key} style={{ width: '50%' }}>
        <StyledLabel>
          <AimTypography variant="h6" margin="0">
            {i18n.card[key]}:
          </AimTypography>
        </StyledLabel>
        <StyledValue>
          <AimTypography
            textAlign="left"
            variant="text"
            margin="0"
            ellipsis
            style={{
              color: theme.colors.greyScale.black,
              width: 'calc(50% - 6rem)',
            }}
          >
            {session[key] ||
              ` - ${i18n.card.emptyField.msg1} ${key} ${i18n.card.emptyField.msg2} -`}
          </AimTypography>
        </StyledValue>
      </StyledRow>
    );

  const renderAttedeeTypes = () =>
    Object.values(constants.agendaSessionAttendees).map((att, idx) => {
      if (
        !Object.prototype.hasOwnProperty.call(session, att.id) ||
        session[att.id].items.length < 1
      )
        return null;

      const attendees = session[att.id].items;

      return (
        <StyledRow key={idx} style={{ width: '50%' }}>
          <StyledLabel>
            <AimTypography variant="h6" margin="0">
              {att.label(intl)}:
            </AimTypography>
          </StyledLabel>
          <StyledValue>
            <AimTypography variant="text" margin="0">
              {attendees.map((att, idx) => (
                <>
                  <span>
                    {att.givenName} {att.familyName}
                  </span>
                  {idx !== attendees.length - 1 && <span>, </span>}
                </>
              ))}
            </AimTypography>
          </StyledValue>
        </StyledRow>
      );
    });

  const renderTags = () => {
    const { tags } = session;
    if (!tags) return null;

    const singleTag = typeof tags === 'string';

    return (
      <StyledRow style={{ width: '50%' }}>
        <StyledLabel>
          <AimTypography variant="h6" margin="0">
            {i18n.card['tags']}:
          </AimTypography>
        </StyledLabel>
        <StyledValue>
          <div style={{ display: 'flex' }}>
            {singleTag && <StyledTag>#{tags}</StyledTag>}
            {!singleTag &&
              tags.map((tag, idx) => (
                <StyledTag key={idx}>#{tag.text}</StyledTag>
              ))}
          </div>
        </StyledValue>
      </StyledRow>
    );
  };

  const Interventions = () => {
    // const speeches =
    // session?.speeches?.filter((i) => (!isPosterSession ? !!i.type : i)) ||
    // session?.posters?.items;
    console.log('session', session);
    const currentUser = appState.user.getValue();

    const moderatorCheck = (r) => r.role?.name === 'Moderator';

    const sessionModerators = [
      // Filter pax which are moderators...
      ...session.attendeeRoles.items.filter(moderatorCheck), // ...for main agenda session
      ...session.subSessions.items.flatMap((ss) => [
        ...ss.attendeeRoles.items.filter(moderatorCheck),
        ...ss.speeches.items.flatMap((speech) =>
          speech.attendeeRoles.items.filter(moderatorCheck)
        ),
      ]), // ...or for subsessions
    ].flatMap((r) => r.attendees?.items || []);

    const isModerator = !!sessionModerators.find(
      (p) =>
        p.participation?.id ===
        currentUser?.userAndParticipation?.participation?.id
    );

    const visibibleAsModerator =
      session.isInterventionsVisibleToSpeakerAndModerator && isModerator;

    console.log(
      'session.subSessions.items',
      session.subSessions.items,
      session.isInterventionsVisibleOnSessionDetails,
      !visibibleAsModerator
    );
    if (
      !session.subSessions.items.length ||
      (!session.isInterventionsVisibleOnSessionDetails && !visibibleAsModerator)
    ) {
      return null;
    }

    return (
      <ProgramTemplate
        intl={intl}
        sessions={sessionProgram}
        eventId={eventId}
        isFrontOffice
        previewPdf={false}
        onlyInterventions
      />
    );
    // const sortedSpeeches = isPosterSession
    //   ? speeches.sort((a, b) => a.orderNumber - b.orderNumber)
    //   : sortDates(speeches);

    // const sortedSpeeches = sortDates(session.speeches);

    // return (
    //   <InterventionsWrapper>
    //     <AimTypography
    //       variant="h2"
    //       boxStyle={{ fontWeight: 'bold', margin: '10px 20px 0px 20px' }}
    //     >
    //       {/* {isPosterSession ? 'Posters' : 'Interventions'} */}
    //       Interventions
    //     </AimTypography>
    //     <div style={{ margin: '10px auto 10px' }}>
    //       {sortedSpeeches.map((i, index) => {
    //         const groupedAttendees = groupBy(i.attendees, 'role');
    //         const attendees =
    //           (i.abstract && [
    //             `${i.abstract.presenter?.participant?.givenName || ''} ${
    //               i.abstract?.presenter?.participant?.familyName || ''
    //             }`,
    //             ...i.abstract.authors.items.map((a) =>
    //               extractAuthorFields(a)?.trim()
    //             ),
    //           ]) ||
    //           // i.attendees?.map((att) => {
    //           Object.entries(groupedAttendees)?.map(([key, value]) => {
    //             const groupAtt = value;
    //             // test to remove
    //             // att = {
    //             //   ...att,
    //             //   userShowCase: { city: 'Rome', country: 'Italy' },
    //             // };

    //             const renderAtt = groupAtt
    //               ?.map((att) => {
    //                 const fromCityCountry = att.userShowCase?.city
    //                   ? ` (${att.userShowCase?.city}${
    //                       att.userShowCase?.country
    //                         ? ', ' + att.userShowCase?.country.toUpperCase()
    //                         : ''
    //                     })`
    //                   : '';
    //                 return `${att.givenName} ${att.familyName}${fromCityCountry}`;
    //               })
    //               .join(', ');
    //             return (
    //               <div key={`${index}`}>
    //                 {key && key !== 'undefined' ? `${key}: ` : ''} {renderAtt}
    //               </div>
    //             );
    //           }) ||
    //           [];
    //         const isLecture =
    //           i.titleDescription ===
    //           constants.AgendaSpeechesTitleDescriptions.LECTURE;
    //         return (
    //           <Intervention
    //             key={index}
    //             onClick={() =>
    //               i.abstract?.id &&
    //               // onInterventionClick?.(isPosterSession ? i.id : i.abstract?.id)
    //               onInterventionClick?.(i.abstract?.id)
    //             }
    //             style={{
    //               margin: i.isSubsession ? '0 15px' : '0 25px',
    //               cursor:
    //                 onInterventionClick && i.abstract?.id
    //                   ? 'pointer'
    //                   : 'default',
    //             }}
    //           >
    //             {/* {isPosterSession && (
    //               <AimTypography margin="0" variant="textBold">
    //                 {calcPosterCode(
    //                   session.agenda.posterSessions.items,
    //                   session.agenda.restartPosterSessionsNum,
    //                   i.orderNumber,
    //                   session
    //                 )}
    //               </AimTypography>
    //             )} */}
    //             <AimTypography
    //               variant="textBold"
    //               margin="0"
    //               {...{ isUpperCase: i.isSubsession }}
    //             >
    //               {i.title || i.name || i.abstract?.title || ''}
    //               {isLecture && <LectureTag>LECTURE</LectureTag>}
    //             </AimTypography>
    //             <div>
    //               {attendees.map((a, idx) => {
    //                 return (
    //                   <InterventionSpeaker key={idx} variant="text" margin="0">
    //                     {a}
    //                     {idx !== attendees.length - 1 && !!a.trim() && (
    //                       <span>, </span>
    //                     )}
    //                   </InterventionSpeaker>
    //                 );
    //               })}
    //             </div>
    //             {/* {!isPosterSession && ( */}
    //             <AimTypography variant="text" margin="0">
    //               {`${format(i.start, 'HH:mm')} - ${format(i.end, 'HH:mm')}`}
    //             </AimTypography>
    //             {/* )} */}
    //           </Intervention>
    //         );
    //       })}
    //     </div>
    //   </InterventionsWrapper>
    // );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
        // width: 'calc(100% - 20px)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flex: 1,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
        }}
      >
        <div style={{ width: '100%' }}>
          {session && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column-reverse' : 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    ...(isMobile && { marginTop: 20 }),
                  }}
                >
                  <div>
                    <div>
                      <StyledLabel>
                        <AimTypography
                          variant="textBold"
                          margin="0"
                          style={{
                            color: session.sessionTypology?.color || 'black',
                            // (isPosterSession ? 'orange' : 'black'),
                          }}
                        >
                          {session.sessionTypology?.description}
                        </AimTypography>
                      </StyledLabel>
                    </div>

                    <div>
                      <StyledLabel>
                        <AimTypography
                          variant="h2"
                          margin="0"
                          boxStyle={{ fontWeight: 'bold' }}
                        >
                          {session.name || session.title}
                        </AimTypography>
                      </StyledLabel>
                    </div>
                    {session.symposium?.buyOperation && (
                      <StyledRow>
                        <StyledLabel>
                          <AimTypography variant="text" margin="0">
                            {`${i18n.dialogs.previewDialog.sponsoredBy} ${session.symposium?.buyOperation.sponsor.name}`}
                          </AimTypography>
                        </StyledLabel>
                      </StyledRow>
                    )}
                    <div style={{ marginBottom: 10 }}>
                      <StyledValue>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: isMobile ? 'column' : 'row',
                            }}
                          >
                            {/* {!isPosterSession ? ( */}
                            <>
                              <AimTypography variant="text" margin="0">
                                {`${formattedData(
                                  session.start
                                )}, ${formattedTime(
                                  session.start
                                )} - ${formattedTime(session.end)} - `}
                              </AimTypography>
                            </>
                            <AimTypography
                              variant="textBold"
                              margin="0"
                              style={{ marginLeft: isMobile ? 0 : 5 }}
                            >
                              {`${session.room}`}
                            </AimTypography>
                          </div>
                        </div>
                        {session.start && (
                          <AimTypography variant="textBold" margin="0">
                            {`TIMEZONE ${formattedInTimeZone(session.start)}`}
                          </AimTypography>
                        )}
                      </StyledValue>
                    </div>
                  </div>
                  {/* join button and addToMyAgenda button */}
                </div>
                {session.coverImage && (
                  <StyledRow>
                    <StyledValue>
                      <img
                        src={session.coverImage.img}
                        style={{
                          minHeight: 250,
                          maxHeight: 350,
                          width: '100%',
                          margin: '0 auto',
                          objectFit: 'cover',
                        }}
                        alt={session.coverImage.text}
                      />
                    </StyledValue>
                  </StyledRow>
                )}
              </div>
              {headInfo.map((key) => renderField(key))}
              {renderAttedeeTypes()}
              <Roles roles={roles} />
              {renderTags()}
              {session.description && (
                <div style={{ marginTop: 16, width: 'calc(100% - 16px)' }}>
                  <AimTypography variant="h6" margin="0">
                    {i18n.card.description}:
                  </AimTypography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: session.description,
                    }}
                  />
                </div>
              )}
              <Interventions />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
