import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import { AimDialog } from '@aim/components';
import { mailUpHelper, appState } from '@aim/common';
import { getDefaultTemplate, getPreTitles } from './gqlHelper';

const buildEmailForParticipations = async ({
  emailTemplate,
  participations,
  eventData,
}) => {
  let participationsEmailData = await mailUpHelper.getParticipationData({
    emailTemplate,
    participations,
    preTitles: eventData.preTitles.items,
  });
  return participationsEmailData;
};

const buildEmailForAbstracts = async ({
  emailTemplate,
  abstracts,
  eventData,
  intl,
}) => {
  const abstractsEmailData = await mailUpHelper.getAbstractsData({
    emailTemplate,
    abstracts,
    eventData,
    intl,
  });
  return abstractsEmailData;
};

const buildEmailForProducts = async ({
  emailTemplate,
  products,
  eventData,
}) => {
  const participationsEmailData = await mailUpHelper.getProductsData({
    emailTemplate,
    products,
    eventData,
  });

  return participationsEmailData;
};

const buildEmailForPurchases = async ({ emailTemplate, purchases }) => {
  let sponsorEmailData = await mailUpHelper.getPurchasesData({
    emailTemplate,
    purchases,
  });

  return sponsorEmailData;
};

const buildEmailForSponsors = async ({ emailTemplate, sponsors }) => {
  let sponsorEmailData = await mailUpHelper.getSponsorsData({
    emailTemplate,
    sponsors,
  });

  return sponsorEmailData;
};

const ManualMailPreviewDialogComponent = ({
  dialog,
  setDialog,
  openConfirmDialog,
  i18n,
  intl,
  eventId,
}) => {
  // State
  const [preTitles, setPreTitles] = useState([]);
  const [templateData, setTemplateData] = useState();
  const [eventInfo, setEventInfo] = useState({});
  const [eventConfiguration, setEventConfiguration] = useState({});

  // Effects
  useEffect(() => {
    const subscriptionEvent = appState.eventInfo.subscribe((info) => {
      setEventInfo(info);
    });
    const subscriptionEventConfiguration = appState.eventConfiguration.subscribe(
      (configuration) => {
        setEventConfiguration(configuration);
      }
    );

    return () => {
      subscriptionEvent.unsubscribe();
      subscriptionEventConfiguration.unsubscribe();
    };
  }, []);

  const fetchDefaultTemplate = async () => {
    const defaultTemplate = await getDefaultTemplate(eventId);
    return defaultTemplate ? defaultTemplate.content.string : `<div />`;
  };

  useEffect(() => {
    if (!dialog.body || !dialog.template) return;

    const fetchTemplate = async () => {
      /* if (
        !eventInfo?.mailupConfig?.listId ||
        !eventInfo?.mailupConfig?.templateId
      )
        return; */
      try {
        appState.isLoader.next(true);

        const data = dialog.template.unlayerTemplate
          ? dialog.template.unlayerTemplate.content.string
          : await fetchDefaultTemplate();

        // participations
        const emailData = [];
        if (dialog?.body?.participations?.length) {
          emailData.push(
            ...(await buildEmailForParticipations({
              // token,
              // graphqlLimitless,
              emailTemplate: dialog?.template,
              participations: [dialog?.body?.participations[0]],
              eventData: { preTitles: { items: preTitles } },
            }))
          );
        }

        //products
        if (dialog?.body?.products?.length) {
          emailData.push(
            ...(await buildEmailForProducts({
              emailTemplate: dialog?.template,
              products: dialog?.body?.products[0],
              eventData: { preTitles: { items: preTitles } },
            }))
          );
        }

        //abstracts
        if (dialog?.body?.abstracts?.length) {
          const filteredTemplate = dialog?.body?.abstracts[0]?.reviews?.items?.every(
            (review) => !review.isIncludeNoteEmail
          )
            ? {
                ...dialog?.template,
                body: dialog?.template?.body?.replace(
                  /.*(<p*>.*?<\/p>)/.exec(dialog?.template?.body)?.[1],
                  ''
                ),
              }
            : dialog?.template;
          emailData.push(
            ...(await buildEmailForAbstracts({
              // token,
              // graphqlLimitless,
              emailTemplate: filteredTemplate,
              abstracts: [dialog?.body?.abstracts[0]],
              eventData: { preTitles: { items: preTitles } },
              intl,
            }))
          );
        }

        // sponsors
        if (dialog?.body?.sponsors?.length) {
          emailData.push(
            ...(await buildEmailForSponsors({
              emailTemplate: dialog?.template,
              sponsors: [dialog?.body?.sponsors[0]],
            }))
          );
        }

        // sponsor purchases
        if (dialog?.body?.purchases?.length) {
          emailData.push(
            ...(await buildEmailForPurchases({
              emailTemplate: dialog?.template,
              purchases: [dialog?.body?.purchases[0]],
            }))
          );
        }

        const formattedTemplate = mailUpHelper.prepareEmailsHtml({
          emailData,
          template: data,
          eventName: eventInfo.name,
          eventId,
          eventConfiguration: eventConfiguration,
          link: `${eventInfo?.mailupConfig?.siteDomain}/events/${eventId}${dialog?.body?.subLink}`,
          siteDomain: eventInfo?.mailupConfig?.siteDomain,
          intl,
        });

        setTemplateData(formattedTemplate);
        appState.isLoader.next(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchTemplate();
  }, [dialog.body, dialog.template, eventInfo]);

  //load pretitles
  useEffect(() => {
    getPreTitles(eventId).then(setPreTitles);
  }, []);

  const openConfirmation = () => {
    openConfirmDialog({
      body: { ...dialog.body },
    });
  };

  return (
    <AimDialog
      disableEnforceFocus
      fullWidth
      disableOnClose
      // maxWidth="xs"
      onAgree={openConfirmation}
      title={i18n.sendMailPreviewDialog.title}
      open={dialog.active === 'preview' && dialog.isOpen}
      onClose={() => setDialog({ isOpen: false })}
    >
      <Grid container style={{ margin: 0 }}>
        <Grid item xs={12}>
          <div
            style={{
              width: '100%',
              height: 600,
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
            dangerouslySetInnerHTML={{
              __html: templateData,
            }}
          ></div>
        </Grid>
      </Grid>
    </AimDialog>
  );
};

export const ManualMailPreviewDialog = React.memo(
  ManualMailPreviewDialogComponent
);
