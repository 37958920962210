import React, { useState } from 'react';

import { BlobProvider } from '@react-pdf/renderer';

import { useElementSize } from 'usehooks-ts';
import translation from './TicketInfo/translation';

import * as reactPdf from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import PDFRenderer from './PrintPDFTicket/PDFRenderer';

import { PrintPDFTicket } from '@aim/components';

export const TicketInfo = ({
  intl,
  clientInformations,
  billingInformations,
  paymentDetails,
  includedServicesAllotment,
  includedServicesAdditionalServices,
  registrationInformations,
  additionalServicesInformations,
  allotmentInformations,
  travelInformations,
  transferInformations,
  module,
  showPrice,
}) => {
  console.log('🚀 ~ showPrice:', showPrice);
  const i18n = translation.ticketInfo(intl);
  const [squareRef, { width, height }] = useElementSize();

  const [numPages, setNumPages] = useState(null);

  // RENDER
  return (
    <div>
      <PrintPDFTicket
        {...{
          module,
          registrationInformations,
          additionalServicesInformations,
          allotmentInformations,
          billingInformations,
          clientInformations,
          paymentDetails,
          intl,
          i18n,
          showPrice,
        }}
      />
      <BlobProvider
        document={
          <PDFRenderer
            {...{
              intl,
              clientInformations,
              billingInformations,
              paymentDetails,
              includedServicesAllotment,
              includedServicesAdditionalServices,
              registrationInformations,
              additionalServicesInformations,
              allotmentInformations,
              travelInformations,
              transferInformations,
              module,
              i18n,
              showPrice,
            }}
          />
        }
      >
        {({ blob, url, loading, error }) => {
          // Do whatever you need with blob here
          return blob ? (
            <div ref={squareRef}>
              <reactPdf.Document
                // renderMode="svg"
                file={blob}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page, index) => (
                    <reactPdf.Page
                      key={index}
                      // renderTextLayer={false}
                      // renderAnnotationLayer={false}
                      pageNumber={page}
                      width={width}
                      // scale={0.7}
                    />
                  ))}
              </reactPdf.Document>
            </div>
          ) : (
            <></>
          );
        }}
      </BlobProvider>
    </div>
  );
};
