import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { debounce } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import {
  createFormFields,
  createDefaultValues,
  createFormFieldsBlocks,
} from './../AimDynamicForm';

import translation from './translation';
import { getDynamicFields } from './getDynamicFields';
import { constants, googleHelper } from '@aim/common';

import { AimTypography } from './../../atoms';
import { getEventBlocks } from './gqlHelper';
import { sortBy } from 'lodash';
import { groupBy } from 'lodash';

const { getGoogleMapsCountriesByInput } = googleHelper;

// divide standard and custom fields for the ui
// use baseFields
// make good use of profiles and typologies from event
// filter results by router state

const createBaseCheckbox = ({ name, value, label, isReadOnly, prefix }) => ({
  id: name,
  controlType: 'checkbox',
  label,
  checked: value,
  disabled: isReadOnly,
  prefix,
});

let createBaseFieldsCollection = ({
  profiles,
  typologies,
  preTitles,
  feeDateRanges,
  checkboxes,
  intl,
  i18n,
  isReadOnly,
  cognitoUserId,
  showAdminFields,
  toSkipBaseFieldsIds,
  toDisableBaseFields,
  toHideBaseFields,
  requiredBaseFields,
  cluster,
  type,
}) => {
  const fields = {
    pax: [
      {
        id: 'title',
        controlType: 'select',
        label: i18n.labels.title,
        disabled: isReadOnly,
        // options: constants.TitleOptions.map((o) => ({
        //   value: o.value,
        //   label: o.label(intl),
        // })),
        options: preTitles,
        prefix: 'baseDefaultValues',
        hasNoneValue: true,
      },
      {
        id: 'givenName',
        controlType: 'text',
        label: i18n.labels.givenName,
        disabled: isReadOnly,
        isRequired: true,
        prefix: 'baseDefaultValues',
      },
      {
        id: 'familyName',
        controlType: 'text',
        label: i18n.labels.familyName,
        disabled: isReadOnly,
        isRequired: true,
        prefix: 'baseDefaultValues',
      },
      {
        id: 'email',
        controlType: 'text',
        label: i18n.labels.email,
        disabled: isReadOnly,
        prefix: 'baseDefaultValues',
      },
      {
        id: 'typology',
        controlType: 'select',
        label: i18n.labels.typology,
        options: typologies,
        disabled: isReadOnly,
        prefix: 'baseDefaultValues',
        hasNoneValue: true,
      },
      // ...(cluster === constants.Clusters.SponsorStaff.id
      //   ? [
      //       {
      //         id: 'isSponsorAdmin',
      //         controlType: 'radio',
      //         label: i18n.labels.isSponsorAdmin.label,
      //         options: [
      //           { label: i18n.labels.isSponsorAdmin.yes, value: 'true' },
      //           { label: i18n.labels.isSponsorAdmin.no, value: 'false' },
      //         ],
      //         disabled: isReadOnly,
      //         prefix: 'baseDefaultValues',
      //       },
      //     ]
      //   : []),
      ...(!profiles?.length
        ? []
        : [
            {
              id: 'profile',
              controlType: 'select',
              label: i18n.labels.profile,
              options: profiles,
              disabled: isReadOnly,
              prefix: 'baseDefaultValues',
              hasNoneValue: true,
            },
          ]),

      {
        id: 'type',
        controlType: 'radio',
        label: i18n.labels.participationMode.label,
        options: [
          {
            label: i18n.labels.participationMode.virtual,
            value: constants.EventTypes.VIRTUAL.id,
          },
          {
            label: i18n.labels.participationMode.physical,
            value: constants.EventTypes.PHYSICAL.id,
          },
        ],
        disabled: isReadOnly,
        prefix: 'baseDefaultValues',
      },
      //to be removed commentato per sicurezza

      // {
      //   id: 'feeDateRange',
      //   controlType: 'select',
      //   label: i18n.labels.feeDateRange,
      //   options: feeDateRanges,
      //   disabled: true,
      //   prefix: 'baseDefaultValues',
      // },
      // {
      //   id: 'discountCode',
      //   controlType: 'select',
      //   label: i18n.labels.discountCode,
      //   options: feeDateRanges,
      //   disabled: isReadOnly,
      // },

      ...(cluster === constants.Clusters.Groups.id ||
      cluster === constants.Clusters.SponsorStaff.id
        ? [
            {
              id: 'feeDateRange',
              controlType: 'select',
              label: i18n.labels.feeDateRange,
              options: feeDateRanges,
              prefix: 'baseDefaultValues',
              isRequired: true,
            },
          ]
        : []),

      {
        id: 'phone',
        controlType: 'text',
        label: i18n.labels.phone,
        disabled: isReadOnly,
        prefix: 'baseDefaultValues',
      },
      {
        id: 'status',
        controlType: 'select',
        defaultValue: 'suspended',
        label: i18n.labels.status,
        options: constants.ParticipantStatus.map((status) => {
          return { label: status.label(intl), value: status.value };
        }),
        disabled: isReadOnly,
        prefix: 'baseDefaultValues',
      },
    ],
  };
  fields.pax = showAdminFields
    ? [
        ...fields.pax,
        {
          id: 'notes',
          controlType: 'text',
          label: i18n.labels.notes,
          disabled: isReadOnly,
          prefix: 'baseDefaultValues',
        },

        ...checkboxes.map((c) =>
          createBaseCheckbox({
            ...c,
            isReadOnly,
            prefix: 'baseDefaultValues',
          })
        ),
      ]
    : fields.pax;
  return {
    ...fields,
    pax: fields.pax
      .filter((f) => !toSkipBaseFieldsIds.includes(f.id))
      .map((f) =>
        toDisableBaseFields.includes(f.id) ? { ...f, disabled: true } : f
      )
      .map((f) =>
        requiredBaseFields.includes(f.id) ? { ...f, isRequired: true } : f
      )
      .map((f) =>
        toHideBaseFields.includes(f.id)
          ? { ...f, gridItemProps: { style: { display: 'none' } } }
          : f
      ),
  };
};

const sort = (fields) =>
  fields.sort((a, b) => a.contextsOfUse.position - b.contextsOfUse.position);

const createCollection = (fields, contextsOfUse, showHiddenFields) =>
  contextsOfUse
    .map((ctx) => ctx)
    .reduce((res, curr) => {
      const filtered = fields.filter(
        (f) => f.contextsOfUse.contextName === curr
      );
      const nextFiltered = showHiddenFields
        ? filtered
        : filtered.filter((f) => !f.contextsOfUse.isHidden);

      const nextFields = sort(nextFiltered);
      return { ...res, [curr]: nextFields };
    }, {});

export const ParticipationForm = ({
  intl,
  participation,
  profiles,
  typologies,
  preTitles,
  feeDateRanges,
  refButton,
  onSaveDataFn,
  showAdminFields = true,
  isReadOnly = false,
  showHiddenFields = true,
  toSkipBaseFieldsIds,
  toDisableBaseFields,
  toHideBaseFields,
  requiredBaseFields,
  excludePaxAnagraphicFields = false,
  isRequiredEnabled = true,
  variant = 'grey',
  showBlocks,
  onSetProfile = () => {},
  children,
  onLoadEnd = () => {},
  customFormControlStyle,
}) => {
  const i18n = translation.participationEdit(intl);

  // fields collections
  const [baseFieldsCollection, setBaseFieldsCollection] = useState({});
  const [baseDefaultValues, setBaseDefaultValues] = useState({});
  const [standardFieldsCollection, setStandardFieldsCollection] = useState({});
  const [customFieldsCollection, setCustomFieldsCollection] = useState({});
  const [profile, setProfile] = useState({});
  const [registrationBlocks, setRegistrationBlocks] = useState();
  const [options, setOptions] = useState([]);

  const {
    control,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
    reset,
    register,
    formState,
    clearErrors,
  } = useForm({
    defaultValues: {
      baseDefaultValues: {},
      standardDefaultValues: {},
      customDefaultValues: {},
    },
  });

  const standardFieldsWatch = watch('standardDefaultValues');
  const customFieldsWatch = watch('customDefaultValues');

  const { dirtyFields } = formState;

  const currentProfile = watch('baseDefaultValues.profile');
  // TODO discount code
  // const currentType = watch('baseDefaultValues.type');
  // const currentFeeDateRange = watch('baseDefaultValues.feeDateRange');

  const isSpeaker = watch('baseDefaultValues.isSpeaker');

  const updateStAndCstFields = async (currentProfile) => {
    const { cluster, clusterId, fieldValues } = participation;

    const {
      contextsOfUse,
      fields: [sFields, cFields],
    } = await getDynamicFields(
      participation.event.id,
      cluster,
      clusterId,
      currentProfile || '',
      excludePaxAnagraphicFields
    );

    const nextStandardFieldCollection = createCollection(
      sFields.map((f) => ({
        ...f,
        prefix: 'standardDefaultValues',
        disabled: isReadOnly,
        isRequired: isRequiredEnabled && f.contextsOfUse.isRequired,
        dirPath:
          f.controlType === 'upload'
            ? `events/${participation.event.id}/participations/${participation.id}/${f.id}`
            : null,
      })),
      contextsOfUse,
      showHiddenFields
    );
    const nextCustomFieldCollection = createCollection(
      cFields.map((f) => ({
        ...f,
        prefix: 'customDefaultValues',
        disabled: isReadOnly,
        isRequired: isRequiredEnabled && f.contextsOfUse.isRequired,
        dirPath:
          f.controlType === 'upload'
            ? `events/${participation.event.id}/participations/${participation.id}/${f.id}`
            : null,
      })),
      contextsOfUse,
      showHiddenFields
    );

    setStandardFieldsCollection(nextStandardFieldCollection);
    setCustomFieldsCollection(nextCustomFieldCollection);

    const nextStandardDefaultValues = createDefaultValues({
      fields: sFields,
      fieldValues: fieldValues ? fieldValues.items : [],
    });

    const nextCustomDefaultValues = createDefaultValues({
      fields: cFields,
      fieldValues: fieldValues ? fieldValues.items : [],
    });
    return { nextStandardDefaultValues, nextCustomDefaultValues };
  };

  const debouncedInput = useRef(
    debounce(async (value) => {
      const descriptions = await getGoogleMapsCountriesByInput(value);
      const opt = [];
      descriptions.map((el) =>
        opt.push({
          label: el.description,
          value: el.description,
          placeId: el.placeId,
        })
      );
      setOptions(opt);
    }, 300)
  ).current;

  useEffect(() => {
    if (!participation) return;

    getEventBlocks(participation.event.id).then((items) => {
      const sorted = sortBy(items, 'order');
      setRegistrationBlocks([
        ...sorted,
        //undefined is a trick to group fields with no block
        { id: undefined, name: i18n.labels.otherInformations },
      ]);
    });
  }, [participation]);

  useEffect(() => {
    return () => {
      debouncedInput.cancel();
    };
  }, [debouncedInput]);

  useEffect(() => {
    if (!participation) return;
    const { cluster } = participation;

    const checkboxes = [
      {
        name: 'isSpeaker',
        value: participation.isSpeaker,
        label: i18n.labels.isSpeaker,
      },
      {
        name: 'isReviewer',
        value: participation.isReviewer,
        label: i18n.labels.isReviewer,
      },
      {
        name: 'seg',
        value: participation.seg,
        label: i18n.labels.seg,
      },
      {
        name: 'ana',
        value: participation.ana,
        label: i18n.labels.ana,
      },
      {
        name: 'isFaculty',
        value: participation.isFaculty === 'true' ? true : false,
        label: i18n.labels.isFaculty,
      },
    ];

    const nextBaseFieldsCollection = createBaseFieldsCollection({
      profiles,
      typologies,
      preTitles,
      feeDateRanges,
      checkboxes,
      intl,
      i18n,
      isReadOnly,
      cognitoUserId: participation.cognitoUserId,
      showAdminFields,
      toSkipBaseFieldsIds: toSkipBaseFieldsIds || [],
      toDisableBaseFields: toDisableBaseFields || [],
      toHideBaseFields: toHideBaseFields || [],
      requiredBaseFields: requiredBaseFields || [],
      cluster,
      type: participation.event.type,
    });
    setBaseFieldsCollection(nextBaseFieldsCollection);

    const nextBaseDefaultValues = {
      title: participation.title,
      givenName: participation.givenName,
      familyName: participation.familyName,
      email: participation.email,
      phone: participation.phone,
      notes: participation.notes,
      seg: participation.seg,
      ana: participation.ana,
      isSponsorAdmin: (!!participation?.sponsor).toString(),
      feeDateRange: participation.feeDateRange,
      typology: participation.typology?.id || '',
      profile: participation.profile?.id || '',
      status: participation.status,
      isSpeaker: participation.isSpeaker,
      isReviewer: participation.isReviewer,
      isFaculty: participation.isFaculty === 'true' ? true : false,
      type: participation.type,
    };

    setBaseDefaultValues(nextBaseDefaultValues);
  }, [
    participation,
    profiles,
    typologies,
    feeDateRanges,
    showAdminFields,
    toSkipBaseFieldsIds,
    toDisableBaseFields,
  ]);

  useEffect(() => {
    if (!participation || !Object.keys(baseDefaultValues).length) return;

    const load = async () => {
      updateStAndCstFields(participation?.profile?.id).then(
        async ({ nextStandardDefaultValues, nextCustomDefaultValues }) => {
          reset({
            baseDefaultValues: baseDefaultValues,
            standardDefaultValues: nextStandardDefaultValues,
            customDefaultValues: nextCustomDefaultValues,
          });
          onLoadEnd();
        }
      );
    };
    load();
  }, [participation, baseDefaultValues]);

  useEffect(() => {
    if (!participation || !currentProfile) return;
    updateStAndCstFields(currentProfile).then(
      ({ nextStandardDefaultValues, nextCustomDefaultValues }) => {
        setValue('standardDefaultValues', nextStandardDefaultValues);
        setValue('customDefaultValues', nextCustomDefaultValues);
      }
    );

    if (participation && currentProfile && currentProfile !== profile.value) {
      const profileFind = profiles.find((p) => p.value === currentProfile);
      setProfile(profileFind);
      onSetProfile(profileFind);
    } else if (!currentProfile && profile.value) {
      setProfile({});
      onSetProfile({});
    }
  }, [currentProfile]);

  const renderBlocksCollection = (collection) => {
    const grouped = groupBy(
      Object.values(collection).flat(),
      'blockField.block.id'
    );
    return (
      registrationBlocks?.length &&
      registrationBlocks.map((block) => {
        const sorted = sortBy(grouped?.[block?.id], 'blockField.order');
        return sorted.length ? (
          <>
            <Grid item xs={12}>
              <AimTypography variant="h3">{block.name}</AimTypography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <AimTypography variant="text">{block.description}</AimTypography>
            </Grid>
            {createFormFieldsBlocks({
              fields: sorted,
              register,
              setValue,
              getValues,
              control,
              errors,
              variant,
              intl,
              clearErrors,
              block,
              standardFieldsWatch,
              customFieldsWatch,
              customFormControlStyle,
              // privacyUrl
            })}
          </>
        ) : (
          <></>
        );
      })
    );
  };
  const renderCollection = (collection) =>
    Object.values(collection).map((fields) =>
      fields.length ? (
        createFormFields({
          fields,
          register,
          setValue,
          getValues,
          control,
          errors,
          variant,
          intl,
          clearErrors,
          customFormControlStyle,
          // privacyUrl
        })
      ) : (
        <></>
      )
    );

  const sendData = async ({
    baseDefaultValues,
    standardDefaultValues,
    customDefaultValues,
  }) => {
    const nextStandardDefaultValues = standardDefaultValues
      ? Object.entries(standardDefaultValues).map(([key, value]) => ({
          ...Object.values(standardFieldsCollection)
            .flat()
            .find((sf) => sf.id === key),
          fieldDefinition: { id: key },

          value,
        }))
      : [];
    const nextCustomDefaultValues = customDefaultValues
      ? Object.entries(customDefaultValues).map(([key, value]) => ({
          ...Object.values(customFieldsCollection)
            .flat()
            .find((cf) => cf.id === key),
          fieldDefinition: { id: key },
          value,
        }))
      : [];
    const nextSubmittedData = {
      baseDefaultValues,
      standardDefaultValues: nextStandardDefaultValues,
      customDefaultValues: nextCustomDefaultValues,
    };
    onSaveDataFn && onSaveDataFn(nextSubmittedData, dirtyFields);
  };

  return (
    <form
      onSubmit={handleSubmit(sendData)}
      style={{ padding: '0 10px 0px 10px' }}
    >
      <Grid container>
        {renderCollection(baseFieldsCollection)}
        {showBlocks ? (
          renderBlocksCollection([
            ...Object.values(standardFieldsCollection).flat(),
            ...Object.values(customFieldsCollection).flat(),
          ])
        ) : (
          <>
            {renderCollection(
              standardFieldsCollection,
              'standardDefaultValues'
            )}
            {renderCollection(customFieldsCollection)}
          </>
        )}
        <input type={'submit'} hidden ref={refButton} />
      </Grid>
    </form>
  );
};
