import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import { appState, utilities, constants } from '@aim/common';
import { styled, AimDynamicForm, AimTypography } from '@aim/components';
import { getEventFields } from './gqlHelper';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const unionToContextOfUse = (fields, contexts) => {
  const outFields = fields
    .filter((f) => f.contextsOfUse.items.length)
    .map((field) => {
      let fieldFirstContextOfUse;
      for (let i = 0; i < contexts.length; i++) {
        fieldFirstContextOfUse = field.contextsOfUse.items.find(
          (cu) => cu.contextName === contexts[i]
        );
        if (fieldFirstContextOfUse) break;
      }
      return {
        ...field,
        contextsOfUse: fieldFirstContextOfUse,
        isReadOnly:
          fieldFirstContextOfUse.contextName !== contexts[contexts.length - 1],
      };
    });
  return outFields;
};

const getDynamicFields = async (eventId, serviceName, contextsOfUse) => {
  if (!eventId) return;

  let standardFields = [];
  let customFields = [];
  if (contextsOfUse && contextsOfUse.length > 0) {
    const {
      standardFields: sFields,
      customFields: cFields,
    } = await getEventFields({
      eventId,
      serviceName,
      contextsOfUse,
    });

    standardFields = unionToContextOfUse(sFields, contextsOfUse);
    customFields = unionToContextOfUse(cFields, contextsOfUse);
  }
  return { fields: [standardFields, customFields] };
};

export const AnagraphicForm = ({
  intl,
  eventId,
  type,
  refButton,
  baseFieldsCollection,
  baseDefaultValues,
  onSaveDataFn,
  additionalSectionTitle,
  additionalFieldsCollection,
  additionalDefaultValues,
  fieldValues = [],
}) => {
  const [standardFieldsCollection, setStandardFieldsCollection] = useState({});
  const [customFieldsCollection, setCustomFieldsCollection] = useState({});
  const { createFormFields, createDefaultValues } = AimDynamicForm;
  const {
    control,
    formState,
    errors,
    handleSubmit,
    register,
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      baseDefaultValues: {},
      standardDefaultValues: {},
      customDefaultValues: {},
      additionalDefaultValues: {},
    },
  });
  const { dirtyFields } = formState;

  const sort = (fields) =>
    fields.sort((a, b) => a.contextsOfUse.position - b.contextsOfUse.position);

  const createCollection = (fields, contextsOfUse, showHiddenFields) =>
    contextsOfUse
      .map((ctx) => ctx)
      .reduce((res, curr) => {
        const filtered = fields.filter(
          (f) => f.contextsOfUse.contextName === curr
        );
        const nextFiltered = showHiddenFields
          ? filtered
          : filtered.filter((f) => !f.contextsOfUse.isHidden);

        const nextFields = sort(nextFiltered);
        return { ...res, [curr]: nextFields };
      }, {});

  const updateStAndCstFields = async (fieldValues) => {
    let contextsOfUse;
    if (type === 'agency') contextsOfUse = [constants.Clusters.Agency.id];
    if (type === 'sponsor') contextsOfUse = [constants.Clusters.Sponsor.id];

    let serviceName;
    if (type === 'agency') serviceName = constants.Services.AGENCIES.key;
    if (type === 'sponsor') serviceName = constants.Services.SPONSOR.key;

    if (type && serviceName) {
      const {
        fields: [sFields, cFields],
      } = await getDynamicFields(eventId, serviceName, contextsOfUse);

      const nextStandardFields = createCollection(
        sFields.map((f) => ({
          ...f,
          // disabled: !!participationGrant,
          isRequired: f.contextsOfUse.isRequired,
          dirPath: null,
        })),
        contextsOfUse,
        true
      );

      const nextCustomFields = createCollection(
        cFields.map((f) => ({
          ...f,
          // disabled: !!participationGrant,
          isRequired: f.contextsOfUse.isRequired,
          dirPath: null,
        })),
        contextsOfUse,
        true
      );

      setStandardFieldsCollection(nextStandardFields);
      setCustomFieldsCollection(nextCustomFields);

      const nextStandardDefaultValues = createDefaultValues({
        fields: sFields,
        fieldValues: fieldValues || [],
      });

      const nextCustomDefaultValues = createDefaultValues({
        fields: cFields,
        fieldValues: fieldValues || [],
      });

      return { nextStandardDefaultValues, nextCustomDefaultValues };
    } else
      return {
        nextStandardDefaultValues: undefined,
        nextCustomDefaultValues: undefined,
      };
  };

  useEffect(() => {
    if (!Object.keys(baseDefaultValues).length) return;

    const load = async () => {
      const {
        nextStandardDefaultValues,
        nextCustomDefaultValues,
      } = await updateStAndCstFields(fieldValues);
      reset({
        baseDefaultValues: baseDefaultValues,
        standardDefaultValues: nextStandardDefaultValues,
        customDefaultValues: nextCustomDefaultValues,
        additionalDefaultValues: additionalDefaultValues,
      });
    };
    load();
  }, [baseDefaultValues, additionalDefaultValues]);

  const sendData = ({
    baseDefaultValues,
    standardDefaultValues,
    customDefaultValues,
    additionalDefaultValues,
  }) => {
    const nextStandardDefaultValues = standardDefaultValues
      ? Object.entries(standardDefaultValues).map(([key, value]) => ({
          ...Object.values(standardFieldsCollection)
            .flat()
            .find((sf) => sf.id === key),
          fieldDefinition: { id: key },

          value,
        }))
      : [];
    const nextCustomDefaultValues = customDefaultValues
      ? Object.entries(customDefaultValues).map(([key, value]) => ({
          ...Object.values(customFieldsCollection)
            .flat()
            .find((cf) => cf.id === key),
          fieldDefinition: { id: key },
          value,
        }))
      : [];
    const nextSubmittedData = {
      baseDefaultValues,
      standardDefaultValues: nextStandardDefaultValues,
      customDefaultValues: nextCustomDefaultValues,
      additionalDefaultValues,
    };
    onSaveDataFn && onSaveDataFn(nextSubmittedData, dirtyFields);
  };

  const renderFormFields = (collection, prefix) => {
    return Object.values(collection).map((fields) =>
      fields.length ? (
        createFormFields({
          prefix,
          fields,
          register,
          setValue,
          getValues,
          control,
          errors,
          variant: 'grey',
          intl,
          clearErrors,
          gridInputProps: { xs: 12, sm: 12, md: 12, lg: 6 },
        })
      ) : (
        <></>
      )
    );
  };

  return (
    <form
      onSubmit={handleSubmit(sendData)}
      style={{ padding: '0 10px 0px 10px' }}
    >
      <Grid container>
        {renderFormFields(baseFieldsCollection)}
        {renderFormFields(standardFieldsCollection, 'standardDefaultValues')}
        {renderFormFields(customFieldsCollection, 'customDefaultValues')}
      </Grid>
      {additionalFieldsCollection && (
        <Grid container>
          <Grid item xs={12}>
            <AimTypography
              variant="textBold"
              boxStyle={{ marginLeft: 12, marginTop: 20 }}
            >
              {additionalSectionTitle || ''}
            </AimTypography>
            <Divider />
            <Grid container style={{ marginTop: 20 }}>
              {renderFormFields(additionalFieldsCollection)}
            </Grid>
          </Grid>
        </Grid>
      )}
      <input type={'submit'} hidden ref={refButton} />
    </form>
  );
};

export default AnagraphicForm;
