import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createHotelReservationMutation = /* GraphQL */ `
  mutation CreateHotelReservation(
    $input: CreateHotelReservationInput!
    $condition: ModelHotelReservationConditionInput
  ) {
    createHotelReservation(input: $input, condition: $condition) {
      id
      isDeleted
      status
      start
      end
      hotelReservationEventId
      createdAt
      updatedAt
      event {
        id
      }
    }
  }
`;

const createHotelRoomReservationMutation = /* GraphQL */ `
  mutation CreateHotelRoomReservation(
    $input: CreateHotelRoomReservationInput!
    $condition: ModelHotelRoomReservationConditionInput
  ) {
    createHotelRoomReservation(input: $input, condition: $condition) {
      id
      isDeletable
      isDeleted
      startDate
      endDate
      depositAmount
      balanceAmount
      notes
      availability
      hotelRoomReservationHotelEventVentureId
      createdAt
      updatedAt
      hotelEventVenture {
        id
      }
    }
  }
`;

const updateHotelRoomMutation = /* GraphQL */ `
  mutation UpdateHotelRoom(
    $input: UpdateHotelRoomInput!
    $condition: ModelHotelRoomConditionInput
  ) {
    updateHotelRoom(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

const createGuestMutation = /* GraphQL */ `
  mutation CreateGuest(
    $input: CreateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    createGuest(input: $input, condition: $condition) {
      id
      participation {
        id
      }
    }
  }
`;

const hotelRoomReservationByHotelEventVentureIdByBookingCodeQuery = /* GraphQL */ `
  query HotelRoomReservationByHotelEventVentureIdByBookingCode(
    $hotelEventVentureId: ID
  ) {
    hotelRoomReservationByHotelEventVentureIdByBookingCode(
      hotelRoomReservationHotelEventVentureId: $hotelEventVentureId
      sortDirection: DESC
    ) {
      items {
        id
        bookingCode
      }
    }
  }
`;

export const createHotelReservation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createHotelReservationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createHotelReservation))
      .catch((e) => {
        console.error('create-hotel-reservation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createHotelRoomReservation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createHotelRoomReservationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createHotelRoomReservation))
      .catch((e) => {
        console.error('create-hotel-room-reservation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateHotelRoom = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateHotelRoomMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateHotelRoom))
      .catch((e) => {
        console.error('update-hotel-room', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createGuest = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createGuestMutation,
      variables: {
        input: { ...input },
      },
    })
      .then((response) => resolve(response.data.createGuest))
      .catch((e) => {
        console.error('create-guest', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getLastBookingCode = (hotelEventVentureId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: hotelRoomReservationByHotelEventVentureIdByBookingCodeQuery,
      variables: {
        hotelEventVentureId,
      },
    })
      .then((response) => {
        console.log(
          'sorted DESC ',
          response.data.hotelRoomReservationByHotelEventVentureIdByBookingCode
            .items
        );
        const lastBookingCode =
          response.data.hotelRoomReservationByHotelEventVentureIdByBookingCode
            .items[0]?.bookingCode;
        resolve(parseInt(lastBookingCode, 10));
      })
      .catch((e) => {
        console.error(
          'hotelRoomReservatibn-byHotelEventVentureId-ByBookingCode',
          e
        );
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
