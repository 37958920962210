import React, { useEffect, useState } from 'react';
import { appState } from '@aim/common';
import { isMobile } from 'react-device-detect';

export const ContentPageTemplateFlex = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [containerSize, setContainerSize] = useState(
    appState.mainContainerSize.getValue()
  );

  useEffect(() => {
    const subscription = appState.isSidebarOpen.subscribe(setIsSidebarOpen);
    const containerSizesubscription = appState.mainContainerSize.subscribe(
      setContainerSize
    );

    return () => {
      subscription.unsubscribe();
      containerSizesubscription.unsubscribe();
    };
  }, []);

  return (
    <div
      style={{
        flex: 1,
        maxWidth: isMobile
          ? `calc(${containerSize.width}px - 60px)`
          : `calc(${containerSize.width}px - 180px` +
            (isSidebarOpen ? ' - 412px)' : ')'),
        maxheight: isMobile
          ? `calc(${containerSize.width}px - 60px)`
          : `calc(${containerSize.width}px - 100px)`,
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  );
};
