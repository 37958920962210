import React from 'react';
import { useTheme } from '@mui/material';
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from 'mui-tiptap';
import MenuSelectLineSpacing from './MenuSelectLineSpacing';
import { onImageUpload } from './tipTapRichTextUtilities';
import MenuButtonUploadFromRepo from './MenuButtonUploadFromRepo';
import MenuButtonFullScreen from './MenuButtonFullScreen';

const extensionNames = {
  TABLE: 'table',
  TABLE_ROW: 'tableRow',
  TABLE_HEADER: 'tableHeader',
  TABLE_CELL: 'tableCell',
  BULLET_LIST: 'bulletList',
  CODE_BLOCK: 'codeBlock',
  DIC: 'doc',
  HARD_BREAK: 'hardBreak',
  LIST_ITEM: 'listItem',
  ORDERED_LIST: 'orderedList',
  PARAGRAPH: 'paragraph',
  SUBSCRIPT: 'subscript',
  SUPERSCRIPT: 'superscript',
  TEXT: 'text',
  BOLD: 'bold',
  BLOCKQUOTE: 'blockquote',
  CODE: 'code',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKE: 'strike',
  LINE_SPACING: 'lineSpacing',
  LINK: 'link',
  LINKBUBBLEMENUHANDLER: 'linkBubbleMenuHandler',
  UPLOAD_FROM_REPO_BUBBLE_MENU_HANDLER: 'uploadFromRepoBubbleMenuHandler',
  GAP_CURSOR: 'gapCursor',
  HEADING: 'heading',
  TEXT_ALIGN: 'textAlign',
  TEXT_STYLE: 'textStyle',
  COLOR: 'color',
  FONT_FAMILY: 'fontFamily',
  FONT_SIZE: 'fontSize',
  HIGHLIGHT: 'highlight',
  HORIZONTAL_RULE: 'horizontalRule',
  IMAGE: 'image',
  DROP_CURSOR: 'dropCursor',
  TASK_LIST: 'taskList',
  TASK_ITEM: 'taskItem',
  MENTION: 'mention',
  PLACEHOLDER: 'placeholder',
  HISTORY: 'history',
  FULLSCREEN: 'fullScreen',
};

const EditorMenuControls = ({ eventId, activeExtensions }) => {
  const theme = useTheme();
  const activeExtensionsNames = activeExtensions.map((ext) => ext.name);

  return (
    <MenuControlsContainer>
      {activeExtensionsNames.includes(extensionNames.FONT_FAMILY) ? (
        <>
          <MenuSelectFontFamily
            options={[
              { label: 'Roboto', value: 'Roboto' },
              { label: 'Comic Sans', value: 'Comic Sans MS, Comic Sans' },
              { label: 'Cursive', value: 'cursive' },
              { label: 'Monospace', value: 'monospace' },
              { label: 'Serif', value: 'serif' },
            ]}
          />
          <MenuDivider />
        </>
      ) : null}

      {activeExtensionsNames.includes(extensionNames.HEADING) ? (
        <>
          <MenuSelectHeading />
          <MenuDivider />
        </>
      ) : null}

      {activeExtensionsNames.includes(extensionNames.FONT_SIZE) ? (
        <>
          <MenuSelectFontSize />
          <MenuDivider />
        </>
      ) : null}

      {activeExtensionsNames.includes(extensionNames.BOLD) ? (
        <MenuButtonBold />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.ITALIC) ? (
        <MenuButtonItalic />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.UNDERLINE) ? (
        <MenuButtonUnderline />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.STRIKE) ? (
        <MenuButtonStrikethrough />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.LINE_SPACING) ? (
        <MenuSelectLineSpacing />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.SUBSCRIPT) ? (
        <MenuButtonSubscript />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.SUPERSCRIPT) ? (
        <MenuButtonSuperscript />
      ) : null}

      {!activeExtensionsNames.includes(extensionNames.BOLD) &&
      !activeExtensionsNames.includes(extensionNames.ITALIC) &&
      !activeExtensionsNames.includes(extensionNames.UNDERLINE) &&
      !activeExtensionsNames.includes(extensionNames.STRIKE) &&
      !activeExtensionsNames.includes(extensionNames.LINE_SPACING) &&
      !activeExtensionsNames.includes(extensionNames.SUBSCRIPT) &&
      !activeExtensionsNames.includes(extensionNames.SUPERSCRIPT) ? null : (
        <MenuDivider />
      )}

      {activeExtensionsNames.includes(extensionNames.COLOR) ? (
        <MenuButtonTextColor
          defaultTextColor={theme.palette.text.primary}
          swatchColors={[
            { value: '#000000', label: 'Black' },
            { value: '#ffffff', label: 'White' },
            { value: '#888888', label: 'Grey' },
            { value: '#ff0000', label: 'Red' },
            { value: '#ff9900', label: 'Orange' },
            { value: '#ffff00', label: 'Yellow' },
            { value: '#00d000', label: 'Green' },
            { value: '#0000ff', label: 'Blue' },
          ]}
        />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.HIGHLIGHT) ? (
        <MenuButtonHighlightColor
          swatchColors={[
            { value: '#595959', label: 'Dark grey' },
            { value: '#dddddd', label: 'Light grey' },
            { value: '#ffa6a6', label: 'Light red' },
            { value: '#ffd699', label: 'Light orange' },
            // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
            { value: '#ffff00', label: 'Yellow' },
            { value: '#99cc99', label: 'Light green' },
            { value: '#90c6ff', label: 'Light blue' },
            { value: '#8085e9', label: 'Light purple' },
          ]}
        />
      ) : null}

      {!activeExtensionsNames.includes(extensionNames.COLOR) &&
      !activeExtensionsNames.includes(extensionNames.HIGHLIGHT) ? null : (
        <MenuDivider />
      )}

      {activeExtensionsNames.includes(extensionNames.LINK) ? (
        <>
          <MenuButtonEditLink />
          <MenuDivider />
        </>
      ) : null}

      {activeExtensionsNames.includes(extensionNames.TEXT_ALIGN) ? (
        <>
          <MenuSelectTextAlign />
          <MenuDivider />
        </>
      ) : null}

      {activeExtensionsNames.includes(extensionNames.ORDERED_LIST) ? (
        <MenuButtonOrderedList />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.BULLET_LIST) ? (
        <MenuButtonBulletedList />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.TASK_LIST) ? (
        <MenuButtonTaskList />
      ) : null}

      {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
      {isTouchDevice() && (
        <>
          <MenuButtonIndent />

          <MenuButtonUnindent />
        </>
      )}

      {!activeExtensionsNames.includes(extensionNames.ORDERED_LIST) &&
      !activeExtensionsNames.includes(extensionNames.BULLET_LIST) &&
      !activeExtensionsNames.includes(extensionNames.TASK_LIST) &&
      !isTouchDevice ? null : (
        <MenuDivider />
      )}

      {activeExtensionsNames.includes(extensionNames.BLOCKQUOTE) ? (
        <>
          <MenuButtonBlockquote />
          <MenuDivider />
        </>
      ) : null}

      {activeExtensionsNames.includes(extensionNames.CODE) ? (
        <MenuButtonCode />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.CODE_BLOCK) ? (
        <MenuButtonCodeBlock />
      ) : null}

      {!activeExtensionsNames.includes(extensionNames.CODE) &&
      !activeExtensionsNames.includes(extensionNames.CODE_BLOCK) ? null : (
        <MenuDivider />
      )}

      {activeExtensionsNames.includes(extensionNames.IMAGE) ? (
        <MenuButtonImageUpload
          onUploadFiles={async (files) =>
            await Promise.all(files.map((file) => onImageUpload(file, eventId)))
          }
        />
      ) : null}

      {activeExtensionsNames.includes(
        extensionNames.UPLOAD_FROM_REPO_BUBBLE_MENU_HANDLER
      ) ? (
        <MenuButtonUploadFromRepo />
      ) : null}

      {!activeExtensionsNames.includes(extensionNames.IMAGE) &&
      !activeExtensionsNames.includes(
        extensionNames.UPLOAD_FROM_REPO_BUBBLE_MENU_HANDLER
      ) ? null : (
        <MenuDivider />
      )}

      {activeExtensionsNames.includes(extensionNames.HORIZONTAL_RULE) ? (
        <MenuButtonHorizontalRule />
      ) : null}

      {activeExtensionsNames.includes(extensionNames.TABLE) ? (
        <MenuButtonAddTable />
      ) : null}

      {!activeExtensionsNames.includes(extensionNames.HORIZONTAL_RULE) &&
      !activeExtensionsNames.includes(extensionNames.TABLE) ? null : (
        <MenuDivider />
      )}

      <MenuButtonRemoveFormatting />

      <MenuDivider />

      <MenuButtonUndo />
      <MenuButtonRedo />
      {activeExtensionsNames.includes(extensionNames.FULLSCREEN) ? (
        <MenuButtonFullScreen />
      ) : null}
    </MenuControlsContainer>
  );
};

export default EditorMenuControls;
