import React, { useState, useEffect } from 'react';

import { styled } from './../styled';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import { SketchPicker } from 'react-color';
import Grid from '@material-ui/core/Grid';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { theme } from './../../theme';
import { appState, constants } from '@aim/common';
import { AimTypography } from './AimTypography';
import { AimDialog } from './AimDialog';

const CustomFormControl = styled(FormControl)({
  padding: theme.spacing(1),
  margin: (props) => theme.spacing(props.m || 0),
  minWidth: 120,
  flex: 1,
});

const CustomTextField = styled(TextField)(
  ({ textfieldvariant, hidetypeappearance }) => ({
    minHeight: 40,
    '& .MuiInput-root': textfieldvariant,

    ...(hidetypeappearance && {
      /* Chrome, Safari, Edge, Opera */
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    }),
  })
);

const presetColors = [
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF',
];

const CustomFormLabel = styled(FormLabel)(({ variantTemplate }) => ({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
  fontFamily:
    variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
  '&.MuiFormLabel-root.Mui-focused': {
    color: 'black',
  },
}));

const formControlLabelPlacementTop = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variantsBACKEND = {
  grey: {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
    borderRadius: 4,
  },
  white: {
    backgroundColor: theme.colors.primary.white,
    borderRadius: 4,
  },
};

const variantsFRONTEND = {
  primary: {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
    borderRadius: 4,
  },
  secondary: {
    backgroundColor: theme.colors.primary.white,
    borderRadius: 4,
  },
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantTemplate = appState.template.getValue();

// TODO delete when restyle is completed
const variantMap = {
  grey: 'primary',
  white: 'secondary',
};

const getVariant = ({ variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate];
    return template[variant] || template[variantMap[variant]];
  }
};

const getDefaultVariant = (eventConfiguration) =>
  variantTemplate === constants.Templates.BACKEND
    ? 'grey'
    : eventConfiguration.background === theme.colors.primary.white
    ? 'primary'
    : 'secondary';

const openColorDialog = ({ setIsColorDialogOpen }) => {
  setIsColorDialogOpen(true);
};

export const AimTextField = ({
  label,
  type,
  displayLabel = true,
  formControlStyle,
  textfieldVariant,
  labelPlacementLeft,
  InputProps,
  inputProps,
  inputPropsStyle,
  customInputProps,
  inputRef,
  customLabel,
  fieldStyle,
  colorPickerTitle = null,
  colorPickerAgreeText = null,
  colorPickerSubtitle = null,
  hidetypeappearance = false,
  addAsterisk = false,
  disabled = false,
  m = 0,
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [inputType, setInputType] = useState(type);

  const [inputColor, setInputColor] = useState(
    rest.value && type === 'color' ? rest.value : `#000000`
  );
  const [isColorDialogOpen, setIsColorDialogOpen] = useState(false);

  const [sketchPickerInputColor, setSketchPickerInputColor] = useState(null);

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  const nextTextFieldVariant =
    textfieldVariant || getDefaultVariant(eventConfiguration);
  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };

  useEffect(() => {
    setInputColor(rest.value && type === 'color' ? rest.value : `#000000`);
  }, [rest.value, type]);

  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};

  return (
    <CustomFormControl m={m} style={controlStyle}>
      {displayLabel || customLabel ? (
        customLabel || (
          <CustomFormLabel variantTemplate={variantTemplate} style={labelStyle}>
            {label}
            {addAsterisk && '*'}
          </CustomFormLabel>
        )
      ) : (
        <></>
      )}
      <CustomTextField
        {...rest}
        {...(inputType === 'color' && {
          onClick: () => {
            openColorDialog({ setIsColorDialogOpen });
          },
          value: inputColor,
        })}
        disabled={inputType === 'color' ? true : disabled}
        textfieldvariant={{
          ...getVariant({ variant: nextTextFieldVariant }),
          ...fieldStyle,
        }}
        hidetypeappearance={hidetypeappearance ? 1 : 0}
        variant="standard"
        type={
          inputType !== 'color' && inputType
            ? inputType
            : inputType === 'color'
            ? 'text'
            : 'text'
        }
        inputRef={inputRef}
        InputProps={{
          ...InputProps,
          disableUnderline: true,
          ...(type === 'password' && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  onClick={() => {
                    setInputType(
                      inputType === 'password' ? 'text' : 'password'
                    );
                  }}
                >
                  {inputType === 'password' ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        inputProps={{
          style: {
            marginTop: 8,
            marginBottom: 8,
            paddingLeft: inputType === 'color' && inputColor ? 35 : 10,
            paddingRight: 10,
            ...inputPropsStyle,
          },
          ...inputProps,
          ...customInputProps,
        }}
      />
      {inputType === 'color' && inputColor && (
        <span
          onClick={() => {
            openColorDialog({ setIsColorDialogOpen });
          }}
          style={{
            height: 20,
            width: 20,
            backgroundColor: inputColor,
            borderRadius: 2.5,
            position: 'absolute',
            top: 40,
            left: 15,
          }}
        />
      )}
      <AimDialog
        disableEnforceFocus
        open={isColorDialogOpen}
        onClose={() => {
          setIsColorDialogOpen(false);
        }}
        onDisagree={() => {
          setIsColorDialogOpen(false);
        }}
        onAgree={() => {
          setInputColor(sketchPickerInputColor);
          rest.onChange &&
            rest.onChange({ target: { value: sketchPickerInputColor } });
        }}
        title={colorPickerTitle || 'Select color'}
        agreeText={colorPickerAgreeText || 'Conferma'}
      >
        {colorPickerSubtitle && (
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <AimTypography>
              {colorPickerSubtitle || 'use colorPickerSubtitle'}
            </AimTypography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <SketchPicker
            color={sketchPickerInputColor || '#000000'}
            onChangeComplete={(v) => {
              setSketchPickerInputColor(v.hex);
            }}
            presetColors={presetColors}
          />
        </Grid>
      </AimDialog>
    </CustomFormControl>
  );
};

export const AimTextFieldForm = ({
  control,
  name,
  defaultValue = '',
  errors,
  isRequired,
  label,
  maxLength,
  minLength,
  validate,
  onChange,
  rules,
  type,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimTextField
          label={label}
          value={props.value}
          type={type}
          onChange={(e) => {
            let value = e.target.value;
            props.onChange(value);
            onChange && onChange(e);
          }}
          addAsterisk={isRequired}
          {...rest}
        />
      )}
      rules={{
        required: isRequired,
        maxLength: maxLength,
        minLength: minLength,
        validate: validate,
        ...rules,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return (
            <div>
              <AimTypography variant="formError">
                {message ||
                  (errors?.[name]?.type === 'minLength'
                    ? `${label} is too short`
                    : errors?.[name]?.type === 'maxLength'
                    ? `${label} is too long`
                    : errors?.[name]?.type === 'validate'
                    ? `${label} is not valid`
                    : `${label} is required`)}
              </AimTypography>
            </div>
          );
        }}
      />
    )}
  </>
);

export const AimTextFieldNumberForm = ({
  control,
  name,
  defaultValue = '',
  errors,
  isRequired,
  label,
  maxLength,
  minLength,
  validate,
  onChange,
  rules,
  type,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimTextField
          label={label}
          value={props.value}
          type={type}
          onChange={(e) => {
            let value = e.target.value;
            if (type === 'number') {
              value = parseInt(value, 10);
            }
            props.onChange(value);
            onChange && onChange(e);
          }}
          addAsterisk={isRequired}
          {...rest}
        />
      )}
      rules={{
        required: isRequired,
        maxLength: maxLength,
        minLength: minLength,
        validate: validate,
        ...rules,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return (
            <div>
              <AimTypography variant="formError">
                {message ||
                  (errors?.[name]?.type === 'minLength'
                    ? `${label} is too short`
                    : errors?.[name]?.type === 'maxLength'
                    ? `${label} is too long`
                    : errors?.[name]?.type === 'validate'
                    ? `${label} is not valid`
                    : `${label} is required`)}
              </AimTypography>
            </div>
          );
        }}
      />
    )}
  </>
);
