export default {
  abstractDetail: (intl) => ({
    page: {
      review: {
        decline: {
          label: intl.formatMessage({
            description: 'decline review label',
            defaultMessage: 'Decline',
          }),
          tooltip: intl.formatMessage({
            description: 'decline review tooltip',
            defaultMessage: 'Decline review',
          }),
        },
        declineDialog: {
          title: intl.formatMessage({
            description: 'decline dialog title',
            defaultMessage: 'Decline Abstract',
          }),
          text: intl.formatMessage({
            description: 'decline dialog text',
            defaultMessage:
              'Are you sure you want to decline to review this abstract?  This action is irreversible.',
          }),
        },
        messages: {
          saveSuccess: intl.formatMessage({
            description: 'review saved success message',
            defaultMessage: 'Review saved',
          }),
          saveFail: intl.formatMessage({
            description: 'review saved error message',
            defaultMessage: 'Error saving review',
          }),
          declineSuccess: intl.formatMessage({
            description: 'review decline success message',
            defaultMessage: 'Abstract successfully declined',
          }),
          declineFail: intl.formatMessage({
            description: 'review decline error message',
            defaultMessage: 'Error declining abstract',
          }),
        },
      },
      title: intl.formatMessage({
        description: 'title text',
        defaultMessage: 'Abstract Detail',
      }),
      abstract: intl.formatMessage({
        description: 'abstract text',
        defaultMessage: 'Abstract',
      }),
      back: {
        label: intl.formatMessage({
          description: 'back to event',
          defaultMessage: 'Abstracts',
        }),
        tooltip: intl.formatMessage({
          description: 'back to event',
          defaultMessage: 'Abstracts',
        }),
      },

      abstractInfo: {
        category: intl.formatMessage({
          description: 'category title',
          defaultMessage: 'Category',
        }),
        status: intl.formatMessage({
          description: 'status title',
          defaultMessage: 'Status',
        }),
        subCategory: intl.formatMessage({
          description: 'subcategory title',
          defaultMessage: 'Subcategory',
        }),
        presentationPreferred: intl.formatMessage({
          description: 'presentation Preferred title',
          defaultMessage: 'Presentation Preferred',
        }),
        tag: intl.formatMessage({
          description: 'tag title',
          defaultMessage: 'Tag',
        }),
        keywords: intl.formatMessage({
          description: 'keywords title',
          defaultMessage: 'Keywords',
        }),
      },

      reviewable: {
        notEvaluateForConflictOfInterest: intl.formatMessage({
          description: 'not evaluate abstract for a conflict of interest',
          defaultMessage:
            'I prefer not to evaluate this abstract for a conflict of interest.',
        }),
      },

      abstractSections: {
        title: intl.formatMessage({
          description: 'abstract sections title',
          defaultMessage: 'Abstract Title',
        }),
      },
      presenter: {
        title: intl.formatMessage({
          description: 'presenting author title',
          defaultMessage: 'Presenting Author',
        }),
      },
      files: {
        title: intl.formatMessage({
          description: 'files title',
          defaultMessage: 'Files uploaded',
        }),
      },
      authors: {
        title: intl.formatMessage({
          description: 'author title',
          defaultMessage: 'Authors',
        }),
        affiliation: {
          label: intl.formatMessage({
            description: 'affilition label',
            defaultMessage: 'Affiliation',
          }),
        },
      },
      cannotParticipate: intl.formatMessage({
        description: 'Cannot participate text',
        defaultMessage: 'Are you unable to participate?',
      }),
      indicatePresenter: intl.formatMessage({
        description: 'Indicate presenter text',
        defaultMessage: 'Click here to indicate another presenter',
      }),
    },
    criteria: {
      buttons: {
        save: {
          label: intl.formatMessage({
            description: 'criteria save button',
            defaultMessage: 'Save',
          }),
        },
        cancel: {
          label: intl.formatMessage({
            description: 'criteria cancel button',
            defaultMessage: 'Cancel',
          }),
        },
      },
      form: {
        error: {
          label: intl.formatMessage({
            description: 'form criteria error',
            defaultMessage: 'Form must be fullfilled',
          }),
        },
        rejected: {
          label: intl.formatMessage({
            description:
              'I prefer to not evaluate this abstract because of interest conflict',
            defaultMessage:
              'I prefer to not evaluate this abstract because of interest conflict',
          }),
        },
        fullfilled: {
          label: intl.formatMessage({
            description: 'already reviewd message',
            defaultMessage: 'Already Reviewed',
          }),
        },
        column: {
          criteriaName: {
            label: intl.formatMessage({
              description: 'criteria name',
              defaultMessage: 'Criteria',
            }),
          },
          criteriaRating: {
            label: intl.formatMessage({
              description: 'criteria rating',
              defaultMessage: 'Rating',
            }),
          },
          criteriaNotes: {
            label: intl.formatMessage({
              description: 'criteria notes',
              defaultMessage: 'Notes',
            }),
          },
        },
      },
    },
    labels: {
      preTitle: intl.formatMessage({
        description: 'pretitle label',
        defaultMessage: 'Title',
      }),
      name: intl.formatMessage({
        description: 'name label',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'surname label',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'email label',
        defaultMessage: 'Email',
      }),
      city: intl.formatMessage({
        description: 'city',
        defaultMessage: 'City',
      }),
      country: intl.formatMessage({
        description: 'country',
        defaultMessage: 'Country',
      }),
      degree: intl.formatMessage({
        description: 'degree label',
        defaultMessage: 'Degree',
      }),
      speciality: intl.formatMessage({
        description: 'speciality label',
        defaultMessage: 'Speciality',
      }),
      jobTitle: intl.formatMessage({
        description: 'job title label',
        defaultMessage: 'Job title',
      }),
      affiliation: intl.formatMessage({
        description: 'affiliation',
        defaultMessage: 'Affiliation',
      }),
      isPresentingAuthor: intl.formatMessage({
        description: 'presenting author label',
        defaultMessage: 'Presenting author',
      }),
    },
  }),
};
